import { AbstractControl } from '@angular/forms';
import { AccountUserService } from '../../Modules/MainApp/users/users.service';
import { ProductCategoryService } from '../../Modules/MainApp/product-category/product-category.service';
import {ImageService} from '../../Modules/MainApp/images/images.service';
import { map, filter, switchMap } from 'rxjs/operators';
import {CommonService} from '../../Common/Services/common.service'; 
import {ForgotPasswordService} from '../../../app/Modules/Login/login.service';
export class ValidateUniqueName {
 
  static createUniqueValidator(commonService: CommonService,modelName:String,fieldName:String,fieldValue:String,accountID:number) {
    return (control: AbstractControl) => {
      if(control.value === fieldValue)
      {
         return commonService.checkUniqueName(modelName,fieldName,control.value,fieldValue,accountID).pipe(map(res => res ? {  } : null));
      }
      else
      {
        return commonService.checkUniqueName(modelName,fieldName,control.value,fieldValue,accountID).pipe(map(res => res ? { nameTaken: true } : null));
      }
    }
  }

  static getUserNameValidator(forgotPasswordService: ForgotPasswordService,commonservice: CommonService) {
    return (control: AbstractControl) => {
        return forgotPasswordService.getUserName(control.value, commonservice.getAccountID()).pipe(map(res => res ? null : { userNameSearch: true } ));
    }
  }

}