import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ImageModel } from './images.model';


@Injectable()
export class ImageService {
    constructor(private httpClient: HttpClient) { }

    getImagesDataByAccId(accountID): Observable<ImageModel[]> {
        return this.httpClient.get<ImageModel[]>('images/get-images-by-accountID', { params: { accountID } });
    }

    addImage(image): Observable<ImageModel> {
        return this.httpClient.post<ImageModel>(`images/add-image`, image);
    }
    
    updateImage(image): Observable<ImageModel>{
        return this.httpClient.put<ImageModel>('images/update-image', image);
    }
    
    deleteImage(image): Observable<ImageModel>{  
        return this.httpClient.put<ImageModel>('images/delete-image', image);      
      } 
      
      checkUniqueNameFromImages(modelName,fieldName,Name,fieldValue): Observable<ImageModel> {
        return this.httpClient.post<ImageModel>('common/check-unique-name',{"modelName":modelName,"fieldName":fieldName,"Name":Name,"fieldValue":fieldValue}); 
    }
}