import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-uploaded-image',
  templateUrl: './uploaded-image.component.html',
  styleUrls: ['./uploaded-image.component.css']
})
export class UploadedImageComponent implements OnInit {
  showImUpload:boolean = false;
  IsEnableQuote:boolean = false;
  IsQuantityTierEnabled = false;
  Valid:boolean = true;
  data:any;
  //Number of Sleeve
  ImagePrice:number; 

  SleeveTierData:any=[];

  // @Input('Data') data: any = [];
  @Input('Data')
  set _getData(data: any) {
     this.data = data;
     if(this.data){
      this.showImUpload = this.data.Price > 0 ? true : false;
      this.IsQuantityTierEnabled = this.data.TiersData.TierData.length > 0 ? this.data.TiersData.TierData[0].Price[0] !=0 ? true : false : false ;
     }
  }
 
  @Output('OnAdd') ChangeEvent: EventEmitter<any> = new EventEmitter<string>();

  constructor(private toastr: ToastrService) { }
   
  ngOnInit() {
    // this.changeTierStatus(0)
  }

  //Get from Child when every change
  StoreTierData($event){
    this.SleeveTierData = $event;
    this.data.TiersData = $event;

    //Validation
    this.data.TiersData.TierData.forEach(val => {
      for (let key in val.Price) {
        if((val.Price[key] == null) && this.data.Enabled){
          this.Valid = false
        }
    }
    });
    let data = {
      Valid : this.Valid,
      Data : this.data
    }
    this.ChangeEvent.emit(data);
    this.Valid = true;
  }

  SendPrice(){
    this.Valid = this.data.Price || this.data.Price > 0 ? true : false;
    let data = {
      Valid : this.Valid,
      Data : this.data
    }
    this.ChangeEvent.emit(data);
    this.Valid = true;
  }

  // changeTierStatus(Num){
  //   this.IsQuantityTierEnabled = (this.data.TiersData.TierData[0].Price[0] == 0 && Num == 0) ?  false : !this.IsQuantityTierEnabled;
  //   if(this.IsQuantityTierEnabled != true){
  //       this.data.TiersData.TierData[0].Price[0] != 0 ? '' : this.data.TiersData = {};
  //       this.ChangeEvent.emit({
  //         Valid : this.data.Price == 0 || this.data.Price == null ? false : true,
  //         Data : this.data
  //       });   
  //   }
  //   else{
  //     if(this.data.TiersData.TierData.length == 0){
  //         this.data.TiersData.TierData = [{
  //           From: 1,
  //           To:2,
  //           Price: {0:0}
  //         }];
  //         this.ChangeEvent.emit({
  //           Valid : false,
  //           Data : this.data
  //         });  
  //       }
      
  //   }
  // }

  //Check Number or not // Allow only Numbers
  isNumberkey(event){ return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57); }

}

