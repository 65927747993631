import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductCategoryModel } from '../../product-category/product-category.model';
import { printProfileModel } from '../../print-profile/print-profile.model';
import { ProductService } from '../product.service';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../../Common/Services/datashare.service';
import { productSkuModel } from '../productsku/productsku.model';
import { SafePipe } from '../../../../Common/pipes/safePipe.pipe';
import { CommonService } from '../../../../Common/Services/common.service'

@Component({
    selector: 'app-addproduct',
    templateUrl: './addproduct.component.html',
    styleUrls: ['./addproduct.component.css']
})
export class AddProductComponent implements OnInit {
    formData: FormData;
    productInEdit: FormGroup;
    fileName: String = "";
    productSkuInEdit: FormGroup;
    formArray: FormArray;
    allProductSku: productSkuModel[];
    selectedSkewImages: any = [];
    allProductCategory: ProductCategoryModel[];
    allPrintProfile: printProfileModel[];
    selectedProductCategory: any = [1];
    selectedCategoryNames: any = [];
    mapProductCategory = [];
    skuFilename: string = "";
    ProductImage: File;
    editedImage: string = "";
    ProductImageName = "";
    DataFromProductTab: any;
    public errormsg = "";

    constructor(private productService: ProductService, private spinnerService: SpinnerService, private datashare: DataService, private toastr: ToastrService, private fb: FormBuilder, private safePipe: SafePipe, public commonService: CommonService) {
        this.formArray = new FormArray([
            this.generateFormGroup()
        ]);
        this.productSkuInEdit = new FormGroup({
            productSkuDetails: this.formArray
        });
        this.productInEdit = this.generateProductFormGroup();

    }

    ngOnInit() {
        this.DataFromProductTab = this.datashare.getProductData();
        if (this.DataFromProductTab != undefined && this.DataFromProductTab._id) {
            this.productInEdit = new FormGroup({
                Status: new FormControl(this.DataFromProductTab.Status),
                Deleted: new FormControl(this.DataFromProductTab.Deleted),
                ProductID: new FormControl(this.DataFromProductTab.ProductID),
                Description: new FormControl(this.DataFromProductTab.Description),
                ProductImageUrl: new FormControl(this.DataFromProductTab.ProductImageUrl),
                AccountProductID: new FormControl(this.DataFromProductTab.AccountProductID),
                MinOrderQuantity: new FormControl(this.DataFromProductTab.MinOrderQuantity),
                MaxOrderQuantity: new FormControl(this.DataFromProductTab.MaxOrderQuantity),
                PrintProfileID: new FormControl(this.DataFromProductTab.PrintProfileID),
                AllowCustomDesign: new FormControl(this.DataFromProductTab.AllowCustomDesign),
                CreatedDate: new FormControl(this.DataFromProductTab.CreatedDate),
                ModifiedDate: new FormControl(new Date()),
                MAP: new FormControl(this.DataFromProductTab.MAP, Validators.required),
                MSRP: new FormControl(this.DataFromProductTab.MSRP, Validators.required),
                Code: new FormControl(this.DataFromProductTab.Code, Validators.required),
                AccountID: new FormControl(this.DataFromProductTab.AccountID),
                CreatedBy: new FormControl(this.DataFromProductTab.CreatedBy),
                ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
                Name: new FormControl(this.DataFromProductTab.Name, [Validators.required, this.noWhitespaceValidator]),

            });
            this.selectedProductCategory = this.DataFromProductTab.CategoryIDs;
            this.removeProductSkew(0, "");
            for (let i = 0; i < this.DataFromProductTab.ProductSkuDetails.length; i++) {
                const control = <FormArray>this.productSkuInEdit.controls['productSkuDetails'];
                control.push(this.generateSKUEditFormGroup(this.DataFromProductTab.ProductSkuDetails[i].Status,
                    this.DataFromProductTab.ProductSkuDetails[i].Deleted,
                    this.DataFromProductTab.ProductSkuDetails[i].ProductSkuID,
                    this.DataFromProductTab.ProductSkuDetails[i].Description,
                    this.DataFromProductTab.ProductSkuDetails[i].ColorImageUrl,
                    this.DataFromProductTab.ProductSkuDetails[i].AccountProductSkuID,
                    this.DataFromProductTab.ProductSkuDetails[i].CreatedDate,
                    this.DataFromProductTab.ProductSkuDetails[i].ModifiedDate,
                    this.DataFromProductTab.ProductSkuDetails[i].SkuCode,
                    this.DataFromProductTab.ProductSkuDetails[i].UpcCode,
                    this.DataFromProductTab.ProductSkuDetails[i].AccountID,
                    this.DataFromProductTab.ProductSkuDetails[i].CreatedBy,
                    this.DataFromProductTab.ProductSkuDetails[i].ModifiedBy,
                    this.DataFromProductTab.ProductSkuDetails[i].Price,
                    this.DataFromProductTab.ProductSkuDetails[i].Size,
                    this.DataFromProductTab.ProductSkuDetails[i].Color,
                    this.DataFromProductTab.ProductSkuDetails[i].Stock
                ));


            }
        }

        this.productService.getPrintProfileDataByAccountId(this.commonService.getAccountID()).subscribe(
            (data) => {
                //this.allPrintProfile = data;
                this.spinnerService.setSpinner(false);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
        this.productService.getProductCategoryDataByAccountId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allProductCategory = data;
                for (let i = 0; i < this.allProductCategory.length; i++) {
                    this.mapProductCategory.push({ label: this.allProductCategory[i].CategoryName, value: this.allProductCategory[i].CategoryID })
                }

                this.spinnerService.setSpinner(false);

            },
            (err) => {
              this.commonService.handleError(err);
            }
        )


    }

    ngAfterViewInit() {
        if (this.DataFromProductTab != undefined && this.DataFromProductTab._id) {
            for (let i = 0; i < this.DataFromProductTab.ProductSkuDetails.length; i++) {
                let selDiv = document.querySelector(".selectedFiles" + i);
                for (let j = 0; j < this.DataFromProductTab.ProductSkuDetails[i].ProductSkusImages.length; j++) {
                    let html = "<li><img src=\"" + this.DataFromProductTab.ProductSkuDetails[i].ProductSkusImages[j].ImageUrl + "\"></li>";
                    selDiv.innerHTML += html;
                }
            }
        }
    }

    isNumberkey(event) {
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }


    generateProductFormGroup() {
        return new FormGroup({
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            ProductID: new FormControl(null),
            Description: new FormControl(''),
            ProductImageUrl: new FormControl(this.fileName, [Validators.required]),
            AccountProductID: new FormControl(0),
            MinOrderQuantity: new FormControl(0),
            MaxOrderQuantity: new FormControl(0),
            PrintProfileID: new FormControl(1),
            AllowCustomDesign: new FormControl(false),
            CreatedDate: new FormControl(new Date()),
            ModifiedDate: new FormControl(new Date()),
            MAP: new FormControl(null, [Validators.required]),
            MSRP: new FormControl(null, [Validators.required]),
            Code: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            AccountID: new FormControl(this.commonService.getAccountID()),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            Name: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
        });
    }

    BackToProduct() {

    }
    submitProduct() {
        this.spinnerService.setSpinner(true);
        this.formData = new FormData();
        this.productInEdit.value.ProductImageUrl = this.ProductImageName;
        this.productInEdit.value.CategoryIDs = this.selectedProductCategory;
        this.formData.append('ProductDetails', JSON.stringify(this.productInEdit.value));
        this.formData.append('ProductSKUDetails', JSON.stringify(this.productSkuInEdit.value));
        for (let i = 0; i < this.selectedSkewImages.length; i++) {
            for (let j = 0; j < this.selectedSkewImages[i].length; j++) {
                this.formData.append('image', this.selectedSkewImages[i][j]);

            }
        }
        this.formData.append('image', this.ProductImage);
        this.productService.addProduct(this.formData).subscribe(
            (data) => {
                this.formArray = new FormArray([
                    this.generateFormGroup()
                ]);
                this.productSkuInEdit = new FormGroup({
                    productSkuDetails: this.formArray
                });
                this.productInEdit = this.generateProductFormGroup();
                this.selectedProductCategory = [];

                this.editedImage = "";
                this.toastr.success('Product Added Successfully!', 'Success');
                this.commonService.navigate('productlist');
                this.spinnerService.setSpinner(false);
            },
            err => {
              this.commonService.handleError(err);
            }
        )

    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    updateProductAndSkew() {
        this.spinnerService.setSpinner(true);
        this.formData = new FormData();
        if (this.ProductImageName && this.ProductImage) {
            this.productInEdit.value.ProductImageUrl = this.ProductImageName;
            this.formData.append('image', this.ProductImage);
        }
        for (let i = 0; i < this.selectedSkewImages.length; i++) {
            for (let j = 0; j < this.selectedSkewImages[i].length; j++) {
                this.formData.append('image', this.selectedSkewImages[i][j]);

            }
        }

        this.productInEdit.value.CategoryIDs = this.selectedProductCategory;
        this.formData.append('ProductDetails', JSON.stringify(this.productInEdit.value));
        this.formData.append('ProductSKUDetails', JSON.stringify(this.productSkuInEdit.value));
        this.productService.updateProduct(this.formData).subscribe(
            (data) => {
                this.toastr.success('Product Updated Successfully!', 'Success');
                this.commonService.navigate('productlist');
                this.spinnerService.setSpinner(false);
            }, err => {
               this.commonService.handleError(err);
            }
        )
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }




    //Product SKU controls start from here

    onFileSelected(e, index) {
        if (!e.target.files) return;
        let files = e.target.files;
        let filesArr = Array.prototype.slice.call(files);
        let name = "";
        this.selectedSkewImages.push(filesArr);
        let selDiv = document.querySelector(".selectedFiles" + index);
        let firstFile = e.target.files[0];
        filesArr.forEach(element => {
            if (!element.type.match("image.*")) {
                return;
            }

            name = name + element.name + "|";
            let reader = new FileReader();
            reader.onload = function (e: ProgressEvent) {
                var html = "<li><img src=\"" + (<FileReader>e.target).result + "\">" + '<span>' + element.name + '</span>' + "<br clear=\"left\"/></li>";
                selDiv.innerHTML += html;
            }
            reader.readAsDataURL(element);

        });
        name = name.slice(0, -1);

        this.formArray.controls[index].value.images = name;
    }

    onProductImageSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            // this.validateUpload = false;
            this.ProductImage = event.target.files[0];
            this.ProductImageName = event.target.files[0].name;
            // this.formData.append('image', this.ProductImage);
            this.productInEdit.controls['ProductImageUrl'].setValue(this.ProductImage ? this.ProductImage.name : '');
            if (!this.ProductImage.type.endsWith("image/jpeg") && !this.ProductImage.type.endsWith("image/jpg") && !this.ProductImage.type.endsWith("image/png") && !this.ProductImage.type.endsWith("image/svg+xml")) {
                this.ProductImageName = "";
                // this.validateUpload = true;
                this.productInEdit.controls['ProductImageUrl'].setValue('');
                this.editedImage = "";
                // this.formData.delete('image');
                this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;
            }
            var reader = new FileReader();

            reader.onload = (event: ProgressEvent) => {
                this.editedImage = (<FileReader>event.target).result.toString();
            }
            reader.readAsDataURL(event.target.files[0]);
        }

    }

    getSafeUrl(image) {
        return this.safePipe.transform(image, 'url');
    }


    addProductSkew() {
        const control = <FormArray>this.productSkuInEdit.controls['productSkuDetails'];
        control.push(this.generateFormGroup());
    }

    removeProductSkew(i: number, item) {
        const control = <FormArray>this.productSkuInEdit.controls['productSkuDetails'];
        if (item && item.value.ProductSkuID != null) {
            this.spinnerService.setSpinner(true);
            this.productService.deleteSKUByID(item.value).subscribe(
                (data) => {
                    control.removeAt(i);
                    this.toastr.success('SKU Removed Successfully!', 'Success');
                    this.spinnerService.setSpinner(false);
                }, err => {
                   this.commonService.handleError(err);
                }
            )
        } else {
            control.removeAt(i);
        }

    }

    generateFormGroup() {
        return new FormGroup({
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            ProductSkuID: new FormControl(null),
            Description: new FormControl(''),
            ColorImageUrl: new FormControl(),
            images: new FormControl(this.fileName, [Validators.required]),
            AccountProductSkuID: new FormControl(0),
            CreatedDate: new FormControl(new Date()),
            ModifiedDate: new FormControl(new Date()),
            SkuCode: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            UpcCode: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            AccountID: new FormControl(this.commonService.getAccountID()),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            Price: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            Size: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            Color: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            Stock: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
        });
    }


    generateSKUEditFormGroup(Status, Deleted, ProductSkuID, Description, ColorImageUrl, AccountProductSkuID, CreatedDate, ModifiedDate, SkuCode, UpcCode, AccountID,
        CreatedBy, ModifiedBy, Price, Size, Color, Stock) {
        return new FormGroup({
            Status: new FormControl(Status),
            Deleted: new FormControl(Deleted),
            ProductSkuID: new FormControl(ProductSkuID),
            Description: new FormControl(Description),
            ColorImageUrl: new FormControl(ColorImageUrl),
            images: new FormControl(),
            AccountProductSkuID: new FormControl(AccountProductSkuID),
            CreatedDate: new FormControl(CreatedDate),
            ModifiedDate: new FormControl(ModifiedDate),
            SkuCode: new FormControl(SkuCode, Validators.required),
            UpcCode: new FormControl(UpcCode, Validators.required),
            AccountID: new FormControl(AccountID),
            CreatedBy: new FormControl(CreatedBy),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            Price: new FormControl(Price, [Validators.required]),
            Size: new FormControl(Size, [Validators.required]),
            Color: new FormControl(Color, [Validators.required]),
            Stock: new FormControl(Stock, [Validators.required]),
        });
    }

}