import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PatternModel } from './patterns.model';
import { PatternService } from './patterns.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import {ValidateUniqueName} from '../../../Common/Services/asyncUniqueName.validator';
import {CommonService} from '../../../Common/Services/common.service'
import {Enums} from '../../../Common/enums';
@Component({
    selector: 'app-patterns',
    templateUrl: './patterns.component.html',
    styleUrls: ['./patterns.component.css']
})

export class PatternComponent implements OnInit {
    AllPatterns: PatternModel[];
    PatternsToShow: PatternModel[];
    modalImage: String = "https://decoratorcdn.blob.core.windows.net/images/ScarySmiley.svg";
    modalName: String = "Blind Smiley";
    modalPrice: string = "10";
    selectedPattern = null;
    public noRecords = "";
    searchText: String;
    fileName:String = "";
    public errormsg = "";
    validateUpload: boolean = false;
    patternInEdit: FormGroup;
    selectedFile: File = null;
    showImage: boolean = true;
    editedImage: String;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    selectedFileName: string = "";
    formData: FormData;
    @ViewChild('editPatternImage') myEditPattern: ElementRef;
    constructor(private patternService: PatternService, private spinnerService: SpinnerService, private toastr: ToastrService, private confirmationService: ConfirmationService, private safePipe: SafePipe,public commonService:CommonService) { }
    ngOnInit() {
        this.patternInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.editedImage = "";
        this.showAddButton = false;
        this.showImage = true;
        this.spinnerService.setSpinner(true);
        this.reloadData();
        this.formData = new FormData();
    }
    reloadData(){
        this.patternService.getPatternDataByAccountId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.AllPatterns = data;
                this.PatternsToShow = data;
                this.PatternsToShow.length == 0 ?  this.noRecords = "No Records Found" : this.noRecords = "";
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }

    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.validateUpload = false;
            this.selectedPattern = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedPattern);
            this.patternInEdit.controls['PatternUrl'].setValue(this.selectedPattern ? this.selectedPattern.name : ''); 
            if (!this.selectedPattern.type.endsWith("image/jpeg") && !this.selectedPattern.type.endsWith("image/jpg") && !this.selectedPattern.type.endsWith("image/png") && !this.selectedPattern.type.endsWith("image/svg+xml")) {
                this.selectedFileName = "";
                this.validateUpload = true;
                this.patternInEdit.controls['PatternUrl'].setValue('');
                this.editedImage = "";
                this.formData.delete('image');
                this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = (<FileReader>event.target).result.toString();
            }
            reader.readAsDataURL(event.target.files[0]);
        }

    }
    
    isNumberkey(event){
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }

    getSafeUrl(image) {
        return this.safePipe.transform(image, 'url');
    }

    clearSelectedFile() {
        this.selectedPattern = null;
        this.selectedFileName = "";
        this.formData.delete('image');
        this.myEditPattern.nativeElement.value = "";
        this.errormsg = "";
    }
    onPatternSubmit() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.patternInEdit.value));
        this.patternService.addPattern(this.formData).subscribe(
            (data) => {
                this.selectedPattern = null;
                this.selectedFileName = "";
                this.myEditPattern.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                  this.reloadData();
                this.patternInEdit = this.generateFormGroup();
                this.toastr.success('Pattern Added Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.closePopup();
    }

    deletePattern(pattern) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Pattern?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.patternService.deletePattern(pattern).subscribe(
                    (data) => {
                        this.selectedPattern = null;
                        this.selectedFileName = "";
                        this.myEditPattern.nativeElement.value = "";
                        this.formData = new FormData();
                        this.reloadData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Pattern Deleted Successfully!', 'Success');
                    },
                    (err) => {
                       this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    editPattern(Pattern) {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.showImage = false;
        this.editedImage = Pattern.PatternUrl;
        this.patternInEdit = new FormGroup({
            PatternID: new FormControl(Pattern.PatternID),
            AccountID: new FormControl(Pattern.AccountID),
            Name: new FormControl(Pattern.Name.trim(), [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.pattern,Enums.FieldNames.Name,Pattern.Name,this.commonService.getAccountID())]),
            Tags: new FormControl(Pattern.Tags),
            Price: new FormControl(Pattern.Price, [Validators.required]),
            PatternUrl: new FormControl(Pattern.PatternUrl,[Validators.required]),
            Status: new FormControl(Pattern.Status, Validators.required),
            Deleted: new FormControl(Pattern.Deleted, Validators.required),
            CreatedBy: new FormControl(Pattern.CreatedBy),
            CreatedDate: new FormControl(Pattern.CreatedDate),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            _id: new FormControl(Pattern._id)
        });
    }

    updatePattern() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.patternInEdit.value));
        this.patternService.updatePattern(this.formData).subscribe(
            (data) => {
               this.reloadData();
                this.spinnerService.setSpinner(false);
                this.formData = new FormData();
                this.myEditPattern.nativeElement.value = "";
                this.toastr.success('Pattern Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.patternInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
    }

    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = false;
        this.showAddButton = true;
        this.showImage = true;
        this.editedImage = "";
    }

    closePopup() {
        this.selectedPattern = null;
        this.selectedFileName = "";
        document.getElementById('myModal').style.display = "none";
        this.patternInEdit = this.generateFormGroup();
        this.errormsg = "";
        this.editedImage = "";
    }

    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.PatternUrl;
        this.modalName = element.Name;
        this.modalPrice = element.Price;
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    generateFormGroup() {
        return new FormGroup({
            PatternID: new FormControl(''),
            AccountID: new FormControl(this.commonService.getAccountID()),
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.pattern,Enums.FieldNames.Name,null,this.commonService.getAccountID())]),
            Tags: new FormControl(''),
            Price: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            PatternUrl: new FormControl(this.fileName,[Validators.required]),
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            CreatedDate: new FormControl(new Date()),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(new Date()),
        });
    }
}