import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { SleeveTierData } from '../../print-profile.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-printable-color',
  templateUrl: './printable-color.component.html',
  styleUrls: ['./printable-color.component.css']
})
export class PrintableColorComponent implements OnInit {
  showPritDiv:boolean = false;
  IsEnableQuote:boolean = false;
  IsQuantityTierEnabled = false;
  Valid:boolean = true;
  data: any=[];

  //Number of Sleeve
  MainPrice:number; 

  SleeveTierData:any =[];

  @Input('Data')
  set _getData(data: any) {
     this.data = data;
     if(this.data){
      this.showPritDiv = this.IsQuantityTierEnabled = this.data.TiersData.TierData.length > 0 ? this.data.TiersData.TierData[0].Price[0] !=0 ? true : false : false ;
     }
  }
 
  @Output('OnAdd') ChangeEvent: EventEmitter<any> = new EventEmitter<string>();

  constructor(private toastr: ToastrService) { }
   
  ngOnInit() {   
  }
  
  //Get from Child when every change
   StoreTierData($event){
    this.SleeveTierData = $event;
    this.data.TiersData = $event;
    
     //Validation
     this.data.TiersData.TierData.forEach(val => {
      for (let key in val.Price) {
        if((val.Price[key] == null) && this.data.Enabled){
          this.Valid = false
        }
    }
    });
    let data = {
      Valid : this.Valid,
      Data : this.data
    }
    this.ChangeEvent.emit(data);
    this.Valid = true;
  }

  //Check Number or not // Allow only Numbers
  isNumberkey(event){ return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57); }

}
