export class UserDetails {
  WebSiteUrl: string;
  ToolUrl: string;
  AdminUrl: string;
  AccountToolUrl: string;
  AccountLogo: string;
  AccountID: number;
  AccountAdminUrl: string;
  UserImageUrl: string;
  UserName: string;
  FirstName: string;
  EmailID: string;
  AccountUserID: number;
}