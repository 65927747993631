import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { productSkuModel } from '../productsku/productsku.model';
// import { ProductCategoryModel } from '../product-category/product-category.model';
// import { ProductDetailModel } from './productDetail.model';
// import { SetCanvasModel } from './setupcanvas/setcanvas.model'
// import { printProfileModel } from '../../MainApp/print-profile/print-profile.model';
@Injectable()
export class ProductSkuService {
    constructor(private httpClient: HttpClient) { }

    addProductSku(product): Observable<productSkuModel> {
        return this.httpClient.post<productSkuModel>('product-sku/add-productsku', product);
    }

    getProductSkuDataByAccId(accountID): Observable<productSkuModel[]> {
        return this.httpClient.get<productSkuModel[]>('product-sku/get-productsku-by-AccountID', { params: { accountID } })
    }

}