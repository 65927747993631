import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NgModule,
  ErrorHandler,
  Injector,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { RouterModule } from "@angular/router";
import {
  APP_BASE_HREF,
  LocationStrategy,
  HashLocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { createCustomElement } from "@angular/elements";
import { DomainValidate } from "../../src/app/Modules/Shared/domainValidation";
import { environment } from "../environments/environment";
import { Utilities } from "./Common/Utilities/utilities";
import { DomainValidationService } from "../app/Common/Services/domainValidator.service";
import { CheckChildrenDirective } from "../app/Common/directives/checkChildren.directive";
import { SelectGroupDirective } from "../app/Common/directives/selectGroup.directive";
import { SpinnerService } from "../app/Common/Services/spinner.service";
import { RequestInterceptor } from "./Common/Services/http-interceptor";
import { ErrorInterceptor } from "./Common/Services/error-handler";
import { AuthGuard } from "./Common/Services/authguard.service";
import { AuthService } from "src/app/Common/Services/auth.service";
import { CommonService } from "src/app/Common/Services/common.service";
import { AccountService } from "./Common/Services/account.service";
import { AppComponent } from "./app.component";
import { LayoutComponent } from "./Modules/MainApp/Layout/layout.component";
import { DashboardComponent } from "./Modules/MainApp/dashboard/dashboard.component";
import { ClipartComponent } from "./Modules/MainApp/clipart/clipart.component";
import { ProductCategoryComponent } from "./Modules/MainApp/product-category/product-category.component";
import { ProductComponent } from "./Modules/MainApp/product/product.component";
import { TextShapesComponent } from "./Modules/MainApp/text-shapes/text-shapes.component";
import { WordCloudComponent } from "./Modules/MainApp/word-cloud/word-cloud.component";
import { ColorComponent } from "./Modules/MainApp/colors/colors.component";
import { AccountRoleComponent } from "./Modules/MainApp/account-roles/account-roles.component";
import { DistressEffectComponent } from "./Modules/MainApp/distress-effects/distress-effects.component";
import { ImageMaskComponent } from "./Modules/MainApp/image-masks/image-masks.component";
import { OrderComponent } from "./Modules/MainApp/orders/orders.component";
import { CustomTemplateComponent } from "./Modules/MainApp/custom-templates/custom-templates.component";
import { ImageComponent } from "./Modules/MainApp/images/images.component";
import { PatternComponent } from "./Modules/MainApp/patterns/patterns.component";
import { BackgroundComponent } from "./Modules/MainApp/backgrounds/backgrounds.component";
import { FontFileComponent } from "./Modules/MainApp/font-files/font-files.component";
import { SettingsComponent } from "./Modules/MainApp/settings/settings.component";
import { PrintProfileComponent } from "./Modules/MainApp/print-profile/print-profile.component";
import { UserComponent } from "./Modules/MainApp/users/users.component";
import { SetUpCanvasComponent } from "./Modules/MainApp/product/setupcanvas/setcanvas.component";
import { AddProductComponent } from "./Modules/MainApp/product/addproduct/addproduct.component";
import { EditProductComponent } from "./Modules/MainApp/product/editproduct/editproduct.component";
import { DashboardModule } from "./Modules/MainApp/dashboard/dashboard.module";
import { ClipartModule } from "./Modules/MainApp/clipart/clipart.module";
import { ProductModule } from "./Modules/MainApp/product/product.module";
import { ProductCategoryModule } from "./Modules/MainApp/product-category/product-category.module";
import { TextShapesModule } from "./Modules/MainApp/text-shapes/text-shapes.module";
import { WordCloudModule } from "./Modules/MainApp/word-cloud/word-cloud.module";
import { ColorsModule } from "./Modules/MainApp/colors/colors.module";
import { AccountRolesModule } from "./Modules/MainApp/account-roles/account-roles.module";
import { DistressEffectsModule } from "./Modules/MainApp/distress-effects/distress-effects.module";
import { ImageMasksModule } from "./Modules/MainApp/image-masks/image-masks.module";
import { OrdersModule } from "./Modules/MainApp/orders/orders.module";
import { CustomTemplatesModule } from "./Modules/MainApp/custom-templates/custom-templates.module";
import { ImagesModule } from "./Modules/MainApp/images/images.module";
import { PatternsModule } from "./Modules/MainApp/patterns/patterns.module";
import { BackgroundsModule } from "./Modules/MainApp/backgrounds/backgrounds.module";
import { FontfilesModule } from "./Modules/MainApp/font-files/font-files.module";
import { SettingsModule } from "./Modules/MainApp/settings/settings.module";
import { PrintProfileModule } from "./Modules/MainApp/print-profile/print-profile.module";
import { UsersModule } from "./Modules/MainApp/users/users.module";
import { InputMaskModule } from "primeng/inputmask";
import { PipesModule } from "./Modules/MainApp/pipes/pipes.module";
import { SharedModule } from "./Modules/Shared/shared.module";
import { AccountUserService } from "./Common/Services/AccountUser.service";
import { StoreProfileComponent } from "./Modules/MainApp/settings/Store-Profile/Store-Profile.component";
import { ApproveRegisterComponent } from "./Modules/MainApp/settings/Approve-Register-Requests/Approve-Register.component";
import { APIConfigurationsComponent } from "./Modules/MainApp/settings/APIConfigurations/APIConfigurations.component";
import { AddPrintProfileComponent } from "./Modules/MainApp/print-profile/add-print-profile/add-print-profile.component";

const appRoutes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    loadChildren: "src/app/Modules/Login/login.module#LoginModule",
  },
  {
    path: "domain",
    loadChildren:
      "src/app/Modules/Shared/domain-validator/domain-validator.module#DomainModule",
  },
  {
    path: "admin",
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },
      { path: "clipart", component: ClipartComponent },
      { path: "product-category", component: ProductCategoryComponent },
      { path: "product/productlist", component: ProductComponent },
      { path: "product/setcanvas", component: SetUpCanvasComponent },
      { path: "product/addproduct", component: AddProductComponent },
      { path: "product/editproduct", component: EditProductComponent },
      { path: "text-shapes", component: TextShapesComponent },
      { path: "word-cloud", component: WordCloudComponent },
      { path: "colors", component: ColorComponent },
      { path: "account-roles", component: AccountRoleComponent },
      { path: "distress-effects", component: DistressEffectComponent },
      { path: "image-masks", component: ImageMaskComponent },
      { path: "orderlist", component: OrderComponent },
      { path: "custom-templates", component: CustomTemplateComponent },
      { path: "images", component: ImageComponent },
      { path: "patterns", component: PatternComponent },
      { path: "backgrounds", component: BackgroundComponent },
      { path: "font-files", component: FontFileComponent },
      { path: "settings", component: SettingsComponent },
      { path: "settings/store-Profile", component: StoreProfileComponent },
      { path: "settings/store-requests", component: ApproveRegisterComponent },
      {
        path: "settings/api-configurations",
        component: APIConfigurationsComponent,
      },
      { path: "print-profiles", component: PrintProfileComponent },
      {
        path: "print-profiles/add-print-profile",
        component: AddPrintProfileComponent,
      },
      { path: "users", component: UserComponent },
    ],
  },
];

@NgModule({
  declarations: [
    AppComponent,
    CheckChildrenDirective,
    SelectGroupDirective,
    Utilities,
    LayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    InputMaskModule,
    PipesModule,
    DashboardModule,
    ClipartModule,
    ProductCategoryModule,
    ProductModule,
    TextShapesModule,
    WordCloudModule,
    ColorsModule,
    AccountRolesModule,
    DistressEffectsModule,
    ImageMasksModule,
    OrdersModule,
    CustomTemplatesModule,
    ImagesModule,
    PatternsModule,
    BackgroundsModule,
    FontfilesModule,
    SettingsModule,
    PrintProfileModule,
    UsersModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: "ignore" }),
    // RouterModule.forChild(appChildRoutes)
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    AccountUserService,
    AuthGuard,
    SpinnerService,
    DomainValidate,
    DomainValidationService,
    CommonService,
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AccountService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [AppComponent, DashboardComponent],
})
export class AppModule {
  constructor(
    private injector: Injector,
    private authService: AuthService,
    private commonService: CommonService
  ) {}

  ngDoBootstrap() {
    if (
      window.location.href.includes(environment.Domain_URL) &&
      window.location.href.split("?").length != 1 &&
      !window.location.href.includes("forgot")
    ) {
      this.authorizeStandaloneSite();
    } else if (!window.location.href.includes(environment.Domain_URL)) {
      this.authorizeInPageSite();
    } else {
      const element = createCustomElement(AppComponent, {
        injector: this.injector,
      });
      customElements.define("decorator-admin", element);
    }
  }

  authorizeInPageSite() {
    var url = window.location.href.split("/");
    var accountURL = url[0] + "//" + url[2];
    var accountEmail = this.commonService.getAccountUserEmail();

    var isInPage = true;

    this.authService
      .isAccountAdminAuthenticated(accountURL, accountEmail, isInPage)
      .subscribe(
        (loggedIn) => {
          if (loggedIn) {
            const element = createCustomElement(AppComponent, {
              injector: this.injector,
            });
            customElements.define("decorator-admin", element);
          } else {
            //Error Page to show
          }
        },
        (err) => {
          console.error(err);
          return false;
        }
      );
  }

  authorizeStandaloneSite() {
    if (window.location.href.split("?").length != 1) {
      var params = window.location.href.split("?")[1];
      if (params != undefined) {
        var accountURL = params.split("&")[0].split("=")[1];
        var accountEmail = params.split("&")[1].split("=")[1];
      }
    }
    if (accountURL != undefined && accountURL != "") {
      var isInPage = false;
      this.authService
        .isAccountAdminAuthenticated(accountURL, accountEmail, isInPage)
        .subscribe(
          (loggedIn) => {
            if (loggedIn) {
              const element = createCustomElement(AppComponent, {
                injector: this.injector,
              });
              customElements.define("decorator-admin", element);
              if (window.location.href.split("?") != undefined) {
                window.location.href = window.location.href.split("?")[0];
              }
            } else {
            }
          },
          (err) => {
            console.error(err);
            return false;
          }
        );
    }
  }
}
