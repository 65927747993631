import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProductComponent } from './product.component';
import { ProductService } from './product.service';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { SetUpCanvasComponent } from './setupcanvas/setcanvas.component';
import { AddProductComponent } from './addproduct/addproduct.component';
import { ProductSkuComponent } from './productsku/productsku.component';
import { ProductSkuService } from './productsku/productsku.service';
import { DataService } from '../../../Common/Services/datashare.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { PipesModule } from '../../MainApp/pipes/pipes.module';
import { EditProductComponent } from './editproduct/editproduct.component';
import { PrintProfileService } from '../print-profile/print-profile.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    TableModule,
    TabViewModule,
    PipesModule,
    MultiSelectModule,
    ConfirmDialogModule,
    // RouterModule.forChild([
    //   {
    //     path: 'productlist', component: ProductComponent
    //   },
    //   {
    //     path: 'setcanvas', component: SetUpCanvasComponent
    //   },
    //   {
    //     path: 'addproduct', component: AddProductComponent
    //   },
    //   {
    //     path: 'editproduct', component: EditProductComponent
    //   },
    //   {
    //     path: 'productsku', component: ProductSkuComponent
    //   },
    //   { path: '**', redirectTo: 'ProductComponent' }
    // ])
  ],
  declarations: [ProductComponent, SetUpCanvasComponent, AddProductComponent, ProductSkuComponent, EditProductComponent],
  providers: [ProductService, DataService, ConfirmationService, ProductSkuService, PrintProfileService]
})
export class ProductModule { }
