import { Component, OnInit } from '@angular/core';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsModel } from '../settings.model';
import { SettingsService } from '../settings.service';
import {CommonService} from '../../../../Common/Services/common.service';
@Component({
    selector: 'app-file-size',
    templateUrl: './File-Size.component.html',
    styleUrls: ['./File-Size.component.css']
})

export class FontSizeComponent implements OnInit {
    allSettingsData: SettingsModel;
    fontSizeInEdit: FormGroup;
    constructor(private spinnerService: SpinnerService, private toastr: ToastrService, private settingsService: SettingsService,public commonService:CommonService) { }

    ngOnInit() {
        this.fontSizeInEdit = this.generateFormGroup();
        this.spinnerService.setSpinner(true);
        this.reloadData();
    }
    reloadData(){
        this.settingsService.getAllSettings(this.commonService.getAccountID()).subscribe(
            (data) => {
                console.log(data);
                this.allSettingsData = data;
                this.spinnerService.setSpinner(false);
                this.fontSizeInEdit = new FormGroup({
                    StartSize: new FormControl(data[0].fontSettings[0].StartSize),
                    EndSize: new FormControl(data[0].fontSettings[0].EndSize),
                    Step: new FormControl(data[0].fontSettings[0].Step)
                })

            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    onFileImageSubmit() {
        this.allSettingsData[0].fontSettings[0] = this.fontSizeInEdit.value;
        this.spinnerService.setSpinner(true);
        this.settingsService.updateStoreSettings(this.allSettingsData[0]).subscribe(
            data => {
                this.toastr.success("Data Updated Successfully!", "Success");
                this.reloadData();
            },
            err => {
              this.commonService.handleError(err);
            }
        )
        console.log(this.fontSizeInEdit.value);
    }

    generateFormGroup() {
        return new FormGroup({
            StartSize: new FormControl(0),
            EndSize: new FormControl(0),
            Step: new FormControl(0)
        });
    }




}