import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ColorModel } from './colors.model';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ColorService } from '../colors/colors.service';
import { ConfirmationService } from 'primeng/api';
import { Enums } from '../../../Common/enums';
import { ValidateUniqueName } from '../../../Common/Services/asyncUniqueName.validator';
import { CommonService } from '../../../Common/Services/common.service';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
@Component({
    selector: 'app-colors',
    templateUrl: './colors.component.html',
    styleUrls: ['./colors.component.css']
})

export class ColorComponent implements OnInit {
    hexCode: String = '#000000';
    cmykCode: String = '';
    validation: String = '';
    searchText: String;
    public noRecords = "";
    errormsg: String = '';
    selectedFileName: String;
    fileName: String = "";
    selectedImage = null;
    allColors: ColorModel[];
    colorsToShow: ColorModel[];
    colorInEdit: FormGroup;
    editedImage: String;
    showImage: boolean = false;
    radioButtons: boolean;
    showUpdateButton: boolean = true;
    prop: String = '';
    cInput: number = null;
    mInput: number = null;
    yInput: number = null;
    kInput: number = null;
    formData: FormData;
    showAddButton: boolean = true;
    @ViewChild('editPhoto') myeditImage: ElementRef;
    constructor(private colorService: ColorService, private spinnerService: SpinnerService, private safePipe: SafePipe, private confirmationService: ConfirmationService, private toastr: ToastrService, public commonService: CommonService) {

    }
    ngOnInit() {
        this.colorInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.editedImage = "";
        this.prop = 'RGB';
        this.showAddButton = false;
        this.radioButtons = true;
        this.spinnerService.setSpinner(true);
        this.reloadData();
        this.formData = new FormData();
    }

    openPopup() {
        this.colorInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = false;
        this.editedImage = "";
        this.showImage = false;
        this.showAddButton = true;
        this.radioButtons = true;
        this.prop = 'RGB';
        this.hexCode = '#000000';
        this.clearData();
    }

    cutHex(h) {
        return (h.charAt(0) == "#") ? h.substring(1, 7) : h
    }

    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.validation = "";
            this.formData.delete('image');
            this.selectedImage = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedImage);
            this.colorInEdit.controls['ColorUrl'].setValue(this.selectedImage ? this.selectedImage.name : '');
            this.colorInEdit.value.ColorType = Enums.colorTypes.pattern;
            this.colorInEdit.controls['ColorType'].setValue(Enums.colorTypes.pattern);
            this.colorInEdit.controls['HexCode'].setValue('');
            if (!this.selectedImage.type.endsWith("image/png")) {
                this.selectedFileName = "";
                this.editedImage = "";
                this.colorInEdit.controls['ColorType'].setValue(null);
                this.colorInEdit.controls['ColorUrl'].setValue('');
                this.formData.delete('image');
                this.errormsg = "Only png format is allowed"; return;
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = (<FileReader>event.target).result.toString();
            }
            reader.readAsDataURL(event.target.files[0]);
        }
        this.myeditImage.nativeElement.value = "";
    }

    preventcInput(event) {
        let value = this.cInput;
        if (value >= 101) {
            event.preventDefault();
            this.cInput = parseInt(value.toString().substring(0, 2));
        }
    }
    preventmInput(event) {
        let value = this.mInput;
        if (value >= 101) {
            event.preventDefault();
            this.mInput = parseInt(value.toString().substring(0, 2));
        }
    }
    preventyInput(event) {
        let value = this.yInput;
        if (value >= 101) {
            event.preventDefault();
            this.yInput = parseInt(value.toString().substring(0, 2));
        }
    }
    preventkInput(event) {
        let value = this.kInput;
        if (value >= 101) {
            event.preventDefault();
            this.kInput = parseInt(value.toString().substring(0, 2));
        }
    }

    hexaCodeChange(event) {
        let target = event.target;
        if (target.checked) {
            this.hexCode = "#000000";
            this.colorInEdit.controls['ColorType'].setValue(Enums.colorTypes.colorPicker);
            this.editedImage = "";
            this.formData.delete('image');
            this.colorInEdit.controls['ColorUrl'].setValue('');
            this.cmykCode = '';
            this.cInput = null;
            this.mInput = null;
            this.yInput = null;
            this.kInput = null;
        }
    }
    colorImageChange(event) {
        let target = event.target;
        if (target.checked) {
            this.colorInEdit.controls['ColorType'].setValue(Enums.colorTypes.pattern);
            this.clearData();
            this.hexCode = '';
        }
    }

    cmykChange(event) {
        let target = event.target;
        if (target.checked) {
            this.colorInEdit.controls['ColorType'].setValue(Enums.colorTypes.CMYK);
            this.editedImage = "";
            this.formData.delete('image');
            this.hexCode = '#000000';
            this.colorInEdit.controls['ColorUrl'].setValue('');
            this.colorInEdit.controls['HexCode'].setValue('');
        }
    }
    editColor(color) {
        console.log("color::", color)
        document.getElementById('myModal').style.display = "block";
        this.radioButtons = false;
        this.showUpdateButton = true;
        this.showAddButton = false;
        if (color.ColorType == Enums.colorTypes.colorPicker) {
            this.hexCode = color.HexCode;
            this.prop = 'RGB';
        }
        if (color.ColorType == Enums.colorTypes.CMYK) {
            this.prop = 'CMYK';
            this.cmykCode = color.HexCode;
            let R = parseInt((this.cutHex(this.cmykCode)).substring(0, 2), 16);
            let G = parseInt((this.cutHex(this.cmykCode)).substring(2, 4), 16);
            let B = parseInt((this.cutHex(this.cmykCode)).substring(4, 6), 16);
            console.log(R, G, B);
            var c = 1 - (R / 255);
            var m = 1 - (G / 255);
            var y = 1 - (B / 255);
            var k = Math.min(c, Math.min(m, y));
            c = (c - k) / (1 - k);
            m = (m - k) / (1 - k);
            y = (y - k) / (1 - k);

            c = Math.round(c * 10000 / 100);
            m = Math.round(m * 10000 / 100);
            y = Math.round(y * 10000 / 100);
            k = Math.round(k * 10000 / 100);

            c = isNaN(c) ? 0 : c;
            m = isNaN(m) ? 0 : m;
            y = isNaN(y) ? 0 : y;
            k = isNaN(k) ? 0 : k;

            this.cInput = c;
            this.mInput = m;
            this.yInput = y;
            this.kInput = k;
        }
        if (color.ColorType == Enums.colorTypes.pattern) {
            this.editedImage = color.ColorUrl;
            this.prop = '';
            this.showImage = true;
        }

        this.colorInEdit = new FormGroup({
            Name: new FormControl(color.Name.trim(), [Validators.required, this.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.color, Enums.FieldNames.Name, color.Name, this.commonService.getAccountID())]),
            AccountID: new FormControl(color.AccountID),
            ColorUrl: new FormControl(this.editedImage),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ColorID: new FormControl(color.ColorID),
            HexCode: new FormControl(color.HexCode),
            Tags: new FormControl(color.Tags),
            Price: new FormControl(color.Price,[Validators.required]),
        });
    }

    changeColor(event) {
        this.colorInEdit.value.HexCode = event.value;
        this.hexCode = event.value;
        this.colorInEdit.value.HexCode = this.hexCode;
        this.colorInEdit.value.ColorType = Enums.colorTypes.colorPicker;
        console.log(event.value, this.colorInEdit.value.ColorType)
    }
    componentToHex(rgb) {
        var hex = Number(rgb).toString(16);
        if (hex.length < 2) {
            hex = "0" + hex;
        }
        return hex;
    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    convertToHex(c, m, y, k) {
        // this.colorInEdit.controls['ColorType'].setValue(null);
        var r, g, b;
        r = Math.round(255 * (1 - c / 100) * (1 - k / 100));
        g = Math.round(255 * (1 - m / 100) * (1 - k / 100));
        b = Math.round(255 * (1 - y / 100) * (1 - k / 100));
        let hexaCode = "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
        this.cmykCode = hexaCode;
        // this.colorInEdit.controls['ColorType'].setValue(Enums.colorTypes.CMYK);
        this.colorInEdit.value.HexCode = this.cmykCode;
        return hexaCode;
    }


    onColorSubmit() {
        // if ((this.cmykCode == '' && this.editedImage == '')) {
        //     // this.colorInEdit.controls['HexCode'].setValue('#000000');
        //     this.colorInEdit.value.ColorType = Enums.colorTypes.colorPicker;
        // }
        if(this.colorInEdit.value.ColorType == 3){
            this.convertToHex(this.cInput, this.mInput, this.yInput,this.kInput)
        }
        this.colorInEdit.value.Price = Number(this.colorInEdit.value.Price);
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.colorInEdit.value));
        this.colorService.addColor(this.formData).subscribe(
            (data) => {
                this.reloadData();
                if (this.selectedImage != null) {
                    this.myeditImage.nativeElement.value = "";
                }
                this.selectedImage = null;
                this.selectedFileName = "";

                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                this.colorInEdit = this.generateFormGroup();
                this.hexCode = '#000000';
                this.clearData();
                this.toastr.success('Color Added Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.closePopup();
    }


    deleteColor(color) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Color?',
            header: 'Delete confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.colorService.deleteColor(color).subscribe(
                    (data) => {
                        this.reloadData();
                        this.selectedImage = null;
                        this.selectedFileName = "";
                        this.formData = new FormData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Color deleted successfully!', 'Success');
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    updateColor() {
        this.spinnerService.setSpinner(true);
        // this.formData.append('data', JSON.stringify(this.colorInEdit.value));
        if(this.prop == "CMYK"){
            this.convertToHex(this.cInput, this.mInput, this.yInput,this.kInput)
        }
        this.colorInEdit.value.Price = Number(this.colorInEdit.value.Price);
        this.colorService.updateColor(this.colorInEdit.value).subscribe(
            (data) => {
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.formData = new FormData();
                this.toastr.success('Colors updated successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        document.getElementById('myModal').style.display = "none";
    }

    closePopup() {
        document.getElementById('myModal').style.display = "none";
        this.errormsg = "";
        this.validation = "";
        this.selectedFileName = "";
        this.editedImage = "";
        this.prop = 'RGB';
        this.showImage = false;
        this.selectedImage = null;
        this.radioButtons = true;
        this.hexCode = '#000000';
        this.clearData();
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    getSafeUrl(image) {
        return this.safePipe.transform(image, 'url');
    }


    generateFormGroup() {
        return new FormGroup({
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.color, Enums.FieldNames.Name, null, this.commonService.getAccountID())]),
            ColorID: new FormControl(''),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            ColorUrl: new FormControl(this.fileName),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            HexCode: new FormControl('#000000'),
            ColorType: new FormControl(0),
            Status: new FormControl(true),
            AccountID: new FormControl(this.commonService.getAccountID()),
            Tags: new FormControl(''),
            Price: new FormControl(null,[Validators.required]),
            Deleted: new FormControl(false)
        });
    }

    reloadData() {
        this.colorService.getColorsDataByAccId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allColors = data;
                this.colorsToShow = data;
                this.colorsToShow.length == 0 ? this.noRecords = "No Records Found" : this.noRecords = "";
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }

    clearData() {
        this.cmykCode = '';
        this.colorInEdit.controls['HexCode'].setValue('');
        this.cInput = null;
        this.mInput = null;
        this.yInput = null;
        this.kInput = null;
    }

    isNumberkey(event){
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }
}