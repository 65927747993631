import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { FontFileModel } from './font-files.model';

@Injectable()
export class FontFileService {
    constructor(private httpClient: HttpClient) { }

    getFontFileDataByAccountId(accountID): Observable<FontFileModel[]> {
        return this.httpClient.get<FontFileModel[]>('font-file/get-fontfile-by-AccountID', { params: { accountID } })
    }

    addFontFile(fontFile): Observable<FontFileModel> {
        return this.httpClient.post<FontFileModel>('font-file/add-fontfile', fontFile);
    }

    deleteFontFile(fontFile): Observable<FontFileModel> {
        return this.httpClient.put<FontFileModel>('font-file/delete-fontfile', fontFile);
    }

    updateFontFile(fontFile): Observable<FontFileModel> {
        return this.httpClient.put<FontFileModel>('font-file/update-fontfile', fontFile);
    }

}