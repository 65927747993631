import { Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService,) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf("inlogger") === -1) {
            req = req.clone({ url: config.BASE_URL + req.url });
        }
        if (req.url.indexOf("login") === -1) {
            // apply token here
            let token = this.authService.token || '';
            if (token) {
                req = req.clone({
                    headers: req.headers.set('authtoken', token)
                });
            }
            else {
                this.router.navigateByUrl('login', { skipLocationChange: true });
            }
        }
        return next.handle(req).pipe(map(res => {
            if (res instanceof HttpResponse) {
                if (res.ok) {
                    res = res.clone<any>({
                        body: res.body['response']
                    })
                }
            }
            return res;
        }));
    }
}
