import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { TextShapeModel } from './text-shapes.model';

@Injectable()
export class TextShapeService {
    constructor(private httpClient: HttpClient) { }

    getTextShapeDataByAccId(accountID): Observable<TextShapeModel[]> {
        return this.httpClient.get<TextShapeModel[]>('text-shape/get-text-shapes-by-AccountID', { params: { accountID } })
    }

    addTextShape(textShape): Observable<TextShapeModel> {
        return this.httpClient.post<TextShapeModel>('text-shape/add-text-shape', textShape);
    }

    deleteTextShape(textShape): Observable<TextShapeModel> {
        return this.httpClient.put<TextShapeModel>('text-shape/delete-text-shape', textShape);
    }

    updateTextShape(textShape): Observable<TextShapeModel> {
        return this.httpClient.put<TextShapeModel>('text-shape/update-text-shape', textShape);
    }
}