import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "./Common/Services/account.service";
import { environment } from "../environments/environment";
import { isNullOrUndefined } from "util";

@Component({
  selector: "decorator-admin",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "app";
  inPage = false;
  domainURL = false;
  accountId = 0;
  constructor(private router: Router, private accountService: AccountService) {}
  ngOnInit() {
    if (window.location.href.includes(environment.Domain_URL)) {
      this.inPage = false;
    } else {
      this.inPage = true;
    }

    if (!this.inPage) {
      this.getAccountID();
    } else {
      this.router.navigate(["admin/dashboard"], { skipLocationChange: true });
    }
  }
  getAccountID() {
    // this.accountId = Number(localStorage.getItem('accountId'));
    this.getAccountCSS();
    if (this.accountId == 0) {
      this.accountService
        .getAccountByAccountAdminUrl(window.location.origin)
        .subscribe(
          (data) => {
            if (!isNullOrUndefined(data)) {
              this.accountId = Number(data.AccountID);
              localStorage.setItem("accountId", this.accountId.toString());
              this.loadPage();
            }
          },
          () => {
            this.router.navigate(["login/unauthorized-access"], {
              skipLocationChange: true,
            });
          }
        );
    } else {
      this.loadPage();
    }
  }
  getAccountCSS() {
    if (this.accountId != 0) {
      document
        .getElementById("accountCSS")
        .setAttribute(
          "href",
          environment.Main_Admin_Url +
            "assets/css/custom/" +
            this.accountId +
            ".css"
        );
    } else {
      //   this.accountService.getAccountById(this.accountId).subscribe(
      //     (data) => {
      //         console.log(data);
      //         if(data != undefined && data[0] != undefined && document.getElementById("accountCSS") != undefined)
      //           document.getElementById("accountCSS").setAttribute("href",environment.Main_Admin_Url+"assets/css/custom/"+data[0].AccountID+".css")
      // })
    }
  }
  loadPage() {
    if (this.accountId == 0) {
      this.router.navigate(["login/unauthorized-access"], {
        skipLocationChange: true,
      });
    } else if (window.location.href.includes("forgot")) {
      let token: string = "";
      let urlParams = new URLSearchParams(window.location.search);
      token = urlParams.get("token");
      this.router.navigate(["login/forgot"], {
        skipLocationChange: true,
        queryParams: { token: token },
      });
    } else {
      //console.log(window.location.href.);
      this.router.navigate([], { skipLocationChange: true });
    }
  }
}
