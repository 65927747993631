import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { SettingsModel } from './settings.model';
import { StoreRequestsModel } from './Approve-Register-Requests/Approve-Register.model';
import { APIConfigurationsModel } from './APIConfigurations/APIConfigurations.model';

@Injectable()
export class SettingsService {
    constructor(private httpClient: HttpClient) { }

    getAllSettings(accountID): Observable<SettingsModel> {
        return this.httpClient.get<SettingsModel[]>('account-setting/get-all-account-settings-by-accountID', { params: { accountID } }).pipe(
            map(response => {
                return response[0];
            })
        )
    }

    updateStoreSettings(SettingsModel): Observable<SettingsModel> {
        console.log(SettingsModel);
        return this.httpClient.put<SettingsModel>('account-setting/update-account-setting', SettingsModel);
    }
    getAllStoreRequests(): Observable<StoreRequestsModel> {
        return this.httpClient.get<StoreRequestsModel>('store-requests/get-all-store-requests');
    }

    getAllStoreRequestsCount(): Observable<any> {
        return this.httpClient.get<any>('store-requests/get-stores-count');
    }

    getStoresLazyLoading(start, rows, sortField, sortOrder): Observable<any> {
        return this.httpClient.get<any>('store-requests/get-Stores-Pages', { params: {start, rows , sortField, sortOrder} })
    }

    getStoreDetails(accountID): Observable<StoreRequestsModel[]> {
        return this.httpClient.get<StoreRequestsModel[]>('store-requests/get-store-details', { params: { accountID } });
    }

    updateStoreRequestData(StoreData): Observable<StoreRequestsModel> {
        return this.httpClient.put<StoreRequestsModel>('store-requests/update-store-requests', StoreData);
    }
    AddAPIConfigurations(APIConfigurations): Observable<APIConfigurationsModel> {
        return this.httpClient.post<APIConfigurationsModel>('apiConfig/add-apiConfigurations', APIConfigurations);
    }
    getAPIConfigurations(accountID): Observable<APIConfigurationsModel> {
        return this.httpClient.get<APIConfigurationsModel>('apiConfig/get-apiConfigurations', { params: { accountID } });
    }


}