import { Component, OnInit } from '@angular/core';
import { TextShapeModel } from './text-shapes.model';
import { TextShapeService } from './text-shapes.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-text-shapes',
    templateUrl: './text-shapes.component.html',
    styleUrls: ['./text-shapes.component.css']
})

export class TextShapesComponent implements OnInit {
    accountId: number;
    showDataTable: boolean = false;
    allTextShapes : TextShapeModel[];
    modalImage: String = "https://www.swagshirts99.com/wp-content/uploads/2014/08/plain-raglan-tshirt.jpg";
    modalName: String = "T-shirt";
    textShapeInEdit: FormGroup;
    showUpdateButton:boolean = true;
    showAddButton:boolean = true;
    constructor(private textShapeService: TextShapeService, private spinnerService: SpinnerService, private toastr: ToastrService) { }

    ngOnInit() {
        this.accountId = Number(localStorage.getItem('accountId'));
        this.textShapeInEdit = this.generateFormGroup();
        this.spinnerService.setSpinner(true);
        this.showUpdateButton = false;
        this.showAddButton = false;
        this.textShapeService.getTextShapeDataByAccId(this.accountId).subscribe(
            (data)=>{
                this.allTextShapes = data;
                this.spinnerService.setSpinner(false);
            },
            (err)=>{
                     console.log(err);
                this.spinnerService.setSpinner(false);
                throw err;
           }
        )
    }

    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.textShapeInEdit = this.generateFormGroup();
        this.showDataTable = true;
         this.showUpdateButton = false;
         this.showAddButton = true;
    }

    closePopup() {
        document.getElementById('myModal').style.display = "none";
        this.showDataTable = false;
    }
    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.imageURL;
        this.modalName = element.name;
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }

    addTextShape() {
        this.spinnerService.setSpinner(true);
        this.textShapeService.addTextShape(this.textShapeInEdit.value).subscribe(
            (data) => {
                this.textShapeService.getTextShapeDataByAccId(this.accountId).subscribe(
                    (data) => {
                        this.allTextShapes = data;
                        this.textShapeInEdit = this.generateFormGroup();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Text Shapes Added Successfully!', 'Success');
                    },
                    (err) => {
                        console.log(err);
                        this.spinnerService.setSpinner(false);
                        throw err;
                    }
                )
            },
            (err) => {
                this.spinnerService.setSpinner(false);
                console.log(err);
            }
        )
        this.closePopup();
        this.showDataTable = false;
    }

    deleteTextShape(textShape){
        this.spinnerService.setSpinner(true);
        this.textShapeService.deleteTextShape(textShape).subscribe(
            (data)=>{
                this.ngOnInit();
                this.spinnerService.setSpinner(false);
                this.toastr.success('Text Shapes Deleted Successfully!', 'Success');
            },
            (err)=>{
                console.log(err);
                this.spinnerService.setSpinner(false);
                throw err;
           }
        )
    }

    editTextShape(textShape)
    {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.textShapeInEdit = new FormGroup({
            name: new FormControl(textShape.name, Validators.required),
            imageURL: new FormControl(textShape.imageURL,Validators.required),
            accountID: new FormControl(Number(localStorage.getItem('accountId'))),
            accountUserID: new FormControl(Number(localStorage.getItem('accountUserId'))),
            textShapeID:new FormControl(textShape.textShapeID),
            _id:new FormControl(textShape._id)
        });
    }

    updateTextShape(){
        this.spinnerService.setSpinner(true);
        var textShape  = this.textShapeInEdit.value;
        this.textShapeService.updateTextShape(textShape).subscribe(
            (data)=>{
                this.ngOnInit();
                this.spinnerService.setSpinner(false);
                this.toastr.success('Text Shapes Updated Successfully!', 'Success');
            },
            (err)=>{
                     console.log(err);
                this.spinnerService.setSpinner(false);
               throw err;
           }
        )
        document.getElementById('myModal').style.display = "none";
    }

    
    generateFormGroup() {
        return new FormGroup({
            name: new FormControl('', Validators.required),
            accountID: new FormControl(Number(localStorage.getItem('accountId'))),
            accountUserID: new FormControl(Number(localStorage.getItem('accountUserId'))),
            imageURL: new FormControl('',Validators.required),
            isAdmin: new FormControl(1)
        });
    }
}