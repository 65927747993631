import { Directive, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { map } from 'rxjs/internal/operators/map';

@Directive({ selector: '[selectGroup]' })
export class SelectGroupDirective {
  checkChanges$ = fromEvent(this.host.nativeElement, 'change').pipe(
    map((e) => (<HTMLInputElement>e.target).checked)
  )

  constructor(private host: ElementRef<HTMLInputElement>) { }

  set checked(checked: boolean) {
    this.host.nativeElement.checked = checked;
  }
}