import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { WordCloudModel } from './word-cloud.model';


@Injectable()
export class WordCloudService {
    constructor(private httpClient: HttpClient) { }

    getAllWordClouds(): Observable<WordCloudModel[]> {
        return this.httpClient.get<WordCloudModel[]>('word-cloud/get-all-word-clouds');
    }

}