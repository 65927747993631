import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { UserComponent } from '../users/users.component';
import { AccountUserService } from '../users/users.service';
import {AccountRoleService} from '../account-roles/account-roles.service';
import { PipesModule } from '../../MainApp/pipes/pipes.module';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
// import { TextMaskModule } from 'angular2-text-mask';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    // TextMaskModule,
    InputMaskModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: UserComponent
    //   },
    //   { path: '**', redirectTo: 'properties' }
    // ])
  ],
  declarations: [UserComponent],
  providers: [AccountUserService,ConfirmationService,AccountRoleService]
})
export class UsersModule { }
