
export namespace Enums {
    export enum FileNames {
        accountUser = "accountuser",
        background = "background",
        image = "image",
        productCategory = "productcategory",
        imageMask = "imagemask",
        pattern = "pattern",
        clipart = "clipart",
        distressEffect = "distresseffect",
        fontFile = "fontfile",
        accountRole = "accountrole",
        printProfile = "printprofile",
        color = "color",
        productservice = "productservice",
        productsku = "productsku"
    }

    export enum FieldNames {
        UserName = "UserName",
        Name = "Name",
        CategoryCode = "CategoryCode",
        RoleName = "RoleName",
        Code = "Code",
        SkuCode = "SkuCode",
        UpcCode = "UpcCode"
    }

    export enum colorTypes{
        colorPicker = 1,
        pattern = 2,
        CMYK = 3
    }

    export enum roleId
    {
        role = 2
    }
   
    export enum accountId
    {
        One = 1
    }

}
