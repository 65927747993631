import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ClipartModel } from './clipart.model';

@Injectable()
export class ClipartService {
    constructor(private httpClient: HttpClient) { }

    getClipArtDataByIds(accountID, accountUserID): Observable<ClipartModel[]> {
        return this.httpClient.get<ClipartModel[]>('clipart/get-clipart-by-ID/', { params: { accountID, accountUserID } })
    }
    getAllCliparts(): Observable<ClipartModel[]> {
        return this.httpClient.get<ClipartModel[]>(`clipart/get-all-clipart`);
    }

    getClipArtDataByAccountID(accountID): Observable<ClipartModel[]> {
        return this.httpClient.get<ClipartModel[]>('clipart/get-clipart-by-AccountID/', { params: { accountID } })
    }
    bulkAddCliparts(bulkCliparts): Observable<ClipartModel[]> {
        return this.httpClient.post<ClipartModel[]>(`clipart/bulk-add-clipart`, bulkCliparts);
    }

    addClipart(clipart): Observable<ClipartModel> {
        return this.httpClient.post<ClipartModel>(`clipart/add-clipart`, clipart);
    }

    deleteClipart(clipart): Observable<ClipartModel> {
        return this.httpClient.put<ClipartModel>('clipart/delete-clipart', clipart);
    }

    updateClipart(clipart): Observable<ClipartModel> {
        return this.httpClient.put<ClipartModel>('clipart/update-clipart', clipart);
    }

}