import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from '../../../../app/Common/pipes/commonfilter.pipe';
import { CommonFilterPipe } from '../../../../app/Common/pipes/commonfilter.pipe';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import { TreePipe } from '../../../Common/pipes/tree.pipe';
import { UnescapePipe } from '../../../Common/pipes/unescape.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FilterPipe,CommonFilterPipe,SafePipe,TreePipe,UnescapePipe],
  exports: [
    FilterPipe,CommonFilterPipe,SafePipe,TreePipe, UnescapePipe
  ],
  providers:[SafePipe, UnescapePipe]
})
export class PipesModule { }
