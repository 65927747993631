import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AccountRolesModel } from './account-roles.model';

@Injectable()
export class AccountRoleService {
    constructor(private httpClient: HttpClient) { }

    getRolesDataByAccId(accountID): Observable<AccountRolesModel[]> {
        return this.httpClient.get<AccountRolesModel[]>('roles/get-roles-by-accountID', { params: { accountID } });
    }
    
    getAccountRoleCountByAccountId(accountID): Observable<any> {
        return this.httpClient.get<any>('roles/get-roles-count-by-accountID', { params: { accountID } })
    }
    
    getAccountRolesByRoleId(roleID): Observable<any> {
        return this.httpClient.get<any>('roles/get-role-by-roleID', { params: { roleID } })
    }
    
    getRoleDetailByAccIdandLazyLoading(accountID, start, rows, sortField, sortOrder): Observable<any> {
        return this.httpClient.get<any>('roles/get-roles-by-accountID-pages', { params: { accountID, start, rows,  sortField, sortOrder} })
    }

    addRole(role): Observable<AccountRolesModel> {
        return this.httpClient.post<AccountRolesModel>(`roles/add-role`, role);
    }

    getUserCountByRoleID(roleID){
        return this.httpClient.get<any>('roles/get-user-count-by-roleID', { params: { roleID } } )
    }
    
    deleteRole(role): Observable<AccountRolesModel> {
        return this.httpClient.put<AccountRolesModel>('roles/delete-role', role);
    }

    updateRole(role): Observable<AccountRolesModel> {
        return this.httpClient.put<AccountRolesModel>('roles/update-role', role);
    }

}