import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { PatternModel } from './patterns.model';

@Injectable()
export class PatternService {
    constructor(private httpClient: HttpClient) { }

    getPatternDataByAccountId(accountID): Observable<PatternModel[]> {
        return this.httpClient.get<PatternModel[]>('pattern/get-patterns-by-accountID', { params: { accountID } })
    }

    addPattern(pattern): Observable<PatternModel> {
        return this.httpClient.post<PatternModel>('pattern/add-pattern', pattern);
    }

    deletePattern(pattern): Observable<PatternModel> {
        return this.httpClient.put<PatternModel>('pattern/delete-pattern', pattern);
    }

    updatePattern(Pattern): Observable<PatternModel> {
        return this.httpClient.put<PatternModel>('pattern/update-pattern', Pattern);
    }

}