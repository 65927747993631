import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SafePipe } from '../../../../Common/pipes/safePipe.pipe';
import { UnescapePipe } from '../../../../Common/pipes/unescape.pipe'; 
import { DataService } from '../../../../Common/Services/datashare.service';
import { CommonService } from '../../../../Common/Services/common.service';
import { SpinnerService } from '../../../../Common/Services/spinner.service';
import { PrintProfileModel } from '../../print-profile/print-profile.model';
import { ProductService } from '../product.service';
import { ToastrService } from 'ngx-toastr';
import { ProductModel } from '../product.model';
import { SelectItem } from 'primeng/api';
import { SleeveComponent } from '../../print-profile/add-print-profile/sleeve/sleeve.component';

@Component({
  selector: 'app-editproduct',
  templateUrl: './editproduct.component.html',
  styleUrls: ['./editproduct.component.css']
})
export class EditProductComponent implements OnInit {
  product: ProductModel;
  formData: FormData;
  DataFromProductTab: ProductModel;
  productInEdit: FormGroup;
  allPrintProfiles: PrintProfileModel[];
  multiselectProfiles: SelectItem[] = [];
  selectedProfileIDs = [];
  public noRecords = "";
  
  constructor(
    private datashare: DataService,
    private safePipe: SafePipe,
    private unescape: UnescapePipe,
    private productService: ProductService,
    private spinnerService: SpinnerService,
    private toastr: ToastrService,
    private _fb: FormBuilder,
    public commonService: CommonService,
  ) { }

  ngOnInit() {
    this.spinnerService.setSpinner(true);
    this.product = this.DataFromProductTab = this.datashare.getProductData();
    
    this.productService.getPrintProfileDataByAccountId(this.commonService.getAccountID()).subscribe(
      (data) => {
        this.allPrintProfiles = data;
        this.allPrintProfiles.forEach(x => {
          this.multiselectProfiles.push({label: x.GeneralSettings.ProfileName.toString(), value:  x.PrintProfileID});
        });
        if(this.product.PrintProfileIDs.length > 0) {
          this.multiselectProfiles.filter(x => this.product.PrintProfileIDs.includes(x.value)).forEach(x=> this.selectedProfileIDs.push(x.value));
        }
        this.allPrintProfiles.length == 0 ?  this.noRecords = "No Records Found" : this.noRecords = "";       
        this.spinnerService.setSpinner(false);
      },
      (err) => {
         this.commonService.handleError(err);
      }
    )
    
    if (this.DataFromProductTab != undefined && this.DataFromProductTab.ProductID) {
      this.productInEdit = new FormGroup({
        ProductID: new FormControl(this.DataFromProductTab.ProductID),
        Name: new FormControl(this.DataFromProductTab.Name),
        AccountID: new FormControl(this.DataFromProductTab.AccountID),
        Description: new FormControl(this.DataFromProductTab.Description),
        ProductImageUrl: new FormControl(this.DataFromProductTab.ProductImageUrl),
        PrintProfileIDs: new FormControl(this.DataFromProductTab.PrintProfileIDs),
        MAP: new FormControl(this.DataFromProductTab.MAP),
        MSRP: new FormControl(this.DataFromProductTab.MSRP),
        Code: new FormControl(this.DataFromProductTab.Code),
        Status: new FormControl(this.DataFromProductTab.Status),
        Deleted: new FormControl(this.DataFromProductTab.Deleted),
        CreatedBy: new FormControl(this.DataFromProductTab.CreatedBy),
        CreatedDate: new FormControl(this.DataFromProductTab.CreatedDate),
        ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
        ModifiedDate: new FormControl(new Date()),
      });
    }
  }
  
  getSafeUrl(image) { return this.safePipe.transform(image, 'url'); }
  // getSafeHtml(text) { return this.safePipe.transform(text, 'html'); }  
  getSafeHtml(text) { return this.safePipe.transform(text.trim().substring(1, text.length-1), 'html'); }

  get _description() {
    let desc = this.productInEdit.get('Description').value;
    let formattedDesc = this.unescape.transform(desc.trim().substring(1, desc.length-1));
    // formattedDesc = formattedDesc.replaceAll("\\u003cstrong\\u003e", '<strong>');
    // formattedDesc = formattedDesc.replaceAll("\\u003c/strong\\u003e", '</strong>');
    // formattedDesc = formattedDesc.replaceAll("\\u003cul\\u003e", '<ul>');
    // formattedDesc = formattedDesc.replaceAll("\\u003cli\\u003e", '<li>');
    // formattedDesc = formattedDesc.replaceAll("\\u003c/li\\u003e", '</li>');
    // formattedDesc = formattedDesc.replaceAll("\\u003c/ul\\u003e", '</ul>');
    // formattedDesc = formattedDesc.replaceAll("\\u003cbr\\u003e", '</br>');
    // formattedDesc = formattedDesc.replaceAll("\\n", '');
    //var decoded = document.createElement('div').innerHTML = formattedDesc.html;
    formattedDesc = JSON.parse('"' + formattedDesc + '"')
    
    return formattedDesc;
  }

  onProfileChange(event?){
    if(event != null){
      
      // if(!this.selectedProfileIDs.includes(event.itemValue))
        this.selectedProfileIDs.push(event.value);
    }
  }

  updateProduct() {
    this.spinnerService.setSpinner(true);
    this.product.PrintProfileIDs = this.selectedProfileIDs;
    if(this.product.PrintProfileIDs.length > 0){
      this.productService.updateProduct(this.product).subscribe(
        (data) => {
            this.toastr.success('Saved Successfully!', 'Success');
            this.commonService.navigate('productlist');
            this.spinnerService.setSpinner(false);
        }, err => {
            this.commonService.handleError(err);
        }
      )
    }
    else{
      this.toastr.warning('Please choose atleast one Profile.', 'Warning');
      this.spinnerService.setSpinner(false);
    }
  }
}
