import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ColorComponent } from './colors.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorService } from '../colors/colors.service';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ColorPickerModule } from 'primeng/colorpicker';
import { PipesModule } from '../../MainApp/pipes/pipes.module';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    ColorPickerModule,
    ConfirmDialogModule,
    FormsModule,
    PipesModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: ColorComponent
    //   },
    //   { path: '**', redirectTo: 'ColorComponent' }
    // ])
  ],
  declarations: [
    ColorComponent
  ],
  providers: [ColorService, ConfirmationService]
})
export class ColorsModule { }
