import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AccountUserModel } from '../Models/AccountUser.Model';

@Injectable()
export class AccountUserService {
    constructor(private httpClient: HttpClient) { }

    getAccountUserDataById(accountUserID): Observable<AccountUserModel> {
        return this.httpClient.get<AccountUserModel>('account-user/get-account-user-by-ID', { params: { accountUserID } })
    }

    getAccountUserByUserName(userName):Observable<AccountUserModel>{
        return this.httpClient.get<AccountUserModel>('account-user/get-account-user-by-user-name', { params: { userName } })
    }

    updateAccountUser(user): Observable<AccountUserModel> {
        return this.httpClient.put<AccountUserModel>('account-user/update-accountUser', user);
    }

    decryptPassword(password): Observable<String> {
        return this.httpClient.get<String>('account-user/decrypt-password', { params: { password } });
    }

    encryptPassword(password): Observable<String> {
        return this.httpClient.get<String>('account-user/encrypt-password', { params: { password } });
    }
}