import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { SleeveTierData, SleeveLessTierData } from '../../print-profile.model';


@Component({
  selector: 'app-quantity-tier',
  templateUrl: './quantity-tier.component.html',
  styleUrls: ['./quantity-tier.component.css']
})
export class QuantityTierComponent implements OnInit {
  SleeveTiersData:SleeveTierData;
  SleeveLessTierData:SleeveLessTierData;
  SleeveTierData:any=[];
  NumberofSleev:number=1;
  TierData: any=[];
  IsQuantityTierEnabled:boolean=false;countObservable;
  
  //Sleev
  @Input('IsSleevPriceSetup') IsSleevPriceSetup: boolean = false;
  @Input('NumSleev') numberOfSleev: boolean = false;

  // @Input('TierData') TierData: any = [];
  @Input('TierData')
  set _getData(TierData: any) {
     this.TierData = TierData;
     if(this.TierData && this.IsSleevPriceSetup){
      this.IsQuantityTierEnabled = this.TierData.TierData.length > 0 ? this.TierData.TierData[0].Price[0] !=0 ? true : false : false ;
     }
  }

  @Output('OnAdd') ChangeEvent: EventEmitter<any> = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {   
    this.IsSleevPriceSetup ? '' : this.IsQuantityTierEnabled=true;
    this.initiateSleevArray();
    this.NumberofSleev = Object.keys(this.TierData.TierData[0].Price).length;
    this.countObs();
  }

    countObs(){
      this.countObservable = Array(this.NumberofSleev).fill(0).map((x,i)=>i);
    }

    //Creating the array based on the number => For Sleev
    changeSleeveNum(){
      let length = this.TierData.SleeveData.length;
       if(this.NumberofSleev > length){
       for(let i = 1; i <= this.NumberofSleev-length; i++){
         this.TierData.SleeveData.push(
           {
             Number: i+1, Price: 0 
           }
         )     
       }
      }
      else{
        for(let i = this.NumberofSleev; i < this.NumberofSleev+length; i++){
         this.TierData.SleeveData.pop();
          
        }
      }
       for(let k=0; k < this.TierData.TierData.length; k++)
       {
        for(let j = 0; j < this.TierData.TierData[k].Price.length; j++){
          for(let m = 0; m < this.NumberofSleev; m++){
            this.TierData.TierData[k].Price[j][m] = 0;
          }
         }  
       }
       this.countObs()
    }

    //Input Validation
  AdjustNearNum(index,data,val){
      if(val == 'From' && index != 0){          
        this.TierData.TierData[index].From =  data < this.TierData.TierData[index].To &&
        this.TierData.TierData[index-1].To < data ? this.TierData.TierData[index].From : this.TierData.TierData[index].To -1 ;
      }
      else if(val == 'To'){
        this.TierData.TierData[index].To =  data > this.TierData.TierData[index].From
        ? this.TierData.TierData[index].To : this.TierData.TierData[index].From +1 ;
        if(this.TierData.TierData.length > index)
        for(let i=index+1; i < this.TierData.TierData.length; i++)
        {
           this.TierData.TierData[i].From = this.TierData.TierData[i-1].To + 1;
           this.TierData.TierData[i].To = this.TierData.TierData[i].From + 1;
        }
      }
      this.ChangeEvent.emit(this.TierData)
}

  //Initializing Sleeve array
  initiateSleevArray(){
    if(this.IsSleevPriceSetup){
    this.SleeveTierData = this.SleeveTiersData =  {
      TierData: [{
        From: 1,
        To:2,
        Price: {}
      }],
      SleeveData:[{
        Number: 1,
        Price: 0
      }],
      IsQuoteEnable: false
    };}
    else {
      this.SleeveTierData = this.SleeveLessTierData = {
        TierData: [{
          From: 1,
          To:2,
          Price: {}
        }],
        IsQuoteEnable: false
      };
    }
  }

  //Creating the array based on the number

  //Add New Row
  AddTier(){
    let lastRow = this.TierData.TierData.length -1;
    let newRow = {
      From: this.TierData.TierData[lastRow].To + 1,
      To:this.TierData.TierData[lastRow].To + 2,
      Price: {0:1}
    }
    if(this.IsSleevPriceSetup)
      for(let i=0;i<this.NumberofSleev;i++){
        newRow.Price[i] = 0 ;
      }
    this.TierData.TierData.push(newRow);
  }
  
  //Remove Last Row
  RemoveTier(){
    this.TierData.TierData.pop();
  }

  //Check Number or not // Allow only Numbers
  isNumberkey(event){ return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57); }

}
