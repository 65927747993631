import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings.service';
import {CommonService} from '../../../../Common/Services/common.service';
@Component({
    selector: 'app-apiconfiguration',
    templateUrl: './APIConfigurations.component.html',
    styleUrls: ['./APIConfigurations.component.css']
})

export class APIConfigurationsComponent implements OnInit {
    APIConfigurationsForm: FormGroup;
    SelectedAuthenticationMechanism: Number = 2;
    public AuthenticationType: Array<{ text: string, value: number }> = [
        { text: "OAuth2", value: 1 },
        { text: "Basic", value: 2 },
    ];
    constructor(private spinnerService: SpinnerService, private settingsService: SettingsService,
        private toastr: ToastrService,
        public commonService:CommonService) { }

    ngOnInit() {
        this.spinnerService.setSpinner(true);
        this.APIConfigurationsForm = this.generateFormGroup();
        this.settingsService.getAPIConfigurations(this.commonService.getAccountID()).subscribe(
            (data) => {
                if (data && data.APIConfigurationID) {
                    this.SelectedAuthenticationMechanism = data.AuthenticationType;
                    this.APIConfigurationsForm = this.generateEditFormGroup(
                        data.APIConfigurationID,
                        data.AccountID,
                        data.AuthenticationType,
                        data.UserName,
                        data.Password,
                        data.HeaderKey,
                        data.HeaderKeyValue,
                        data.ProductSyncAPIURL,
                        data.ProductSKUSyncAPIURL,
                        data.CategorySyncAPIURL,
                        data.TokenURL,
                        data.GrantType,
                        data.CreatedBy,
                        data.CreatedDate,
                        data.ModifiedBy,
                        data.ModifiedDate,
                        data.Status,
                        data.Deleted,
                        data.CartURL,
                        data['_id']



                    );
                }
                this.spinnerService.setSpinner(false);
            }, err => {
                this.commonService.handleError(err);
            }
        )
    }
    generateFormGroup() {
        return new FormGroup({
            APIConfigurationID: new FormControl(),
            AccountID: new FormControl(this.commonService.getAccountID()),
            AuthenticationType: new FormControl(),
            UserName: new FormControl(null, [this.noWhitespaceValidator]),
            Password: new FormControl(null, this.noWhitespaceValidator),
            HeaderKey: new FormControl(null, this.noWhitespaceValidator),
            HeaderKeyValue: new FormControl(null, this.noWhitespaceValidator),
            ProductSyncAPIURL: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            ProductSKUSyncAPIURL: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            CategorySyncAPIURL: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            TokenURL: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            GrantType: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            CreatedDate: new FormControl(new Date()),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(new Date()),
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            CartURL:new FormControl("", [Validators.required, this.noWhitespaceValidator])
        })
    }

    generateEditFormGroup(APIConfigurationID, AccountID, AuthenticationType, UserName, Password
        , HeaderKey, HeaderKeyValue, ProductSyncAPIURL, ProductSKUSyncAPIURL, CategorySyncAPIURL,
        TokenURL, GrantType, CreatedBy, CreatedDate, ModifiedBy, ModifiedDate, Status, Deleted, CartURL,id) {
        return new FormGroup({
            APIConfigurationID: new FormControl(APIConfigurationID),
            AccountID: new FormControl(AccountID),
            AuthenticationType: new FormControl(AuthenticationType),
            UserName: new FormControl(UserName, [this.noWhitespaceValidator]),
            Password: new FormControl(Password, this.noWhitespaceValidator),
            HeaderKey: new FormControl(HeaderKey, this.noWhitespaceValidator),
            HeaderKeyValue: new FormControl(HeaderKeyValue, this.noWhitespaceValidator),
            ProductSyncAPIURL: new FormControl(ProductSyncAPIURL, [Validators.required, this.noWhitespaceValidator]),
            ProductSKUSyncAPIURL: new FormControl(ProductSKUSyncAPIURL, [Validators.required, this.noWhitespaceValidator]),
            CategorySyncAPIURL: new FormControl(CategorySyncAPIURL, [Validators.required, this.noWhitespaceValidator]),
            TokenURL: new FormControl(TokenURL, [Validators.required, this.noWhitespaceValidator]),
            GrantType: new FormControl(GrantType, [Validators.required, this.noWhitespaceValidator]),
            CreatedBy: new FormControl(CreatedBy),
            CreatedDate: new FormControl(CreatedDate),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(new Date()),
            Status: new FormControl(Status),
            Deleted: new FormControl(Deleted),
            CartURL:new FormControl(CartURL,[Validators.required, this.noWhitespaceValidator]),
            _id: new FormControl(id)

        })
    }
    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    submitAPIConfigurations() {
        this.spinnerService.setSpinner(true);
        this.APIConfigurationsForm.value.AuthenticationType = this.SelectedAuthenticationMechanism;
        if (this.SelectedAuthenticationMechanism == 1) {
            this.APIConfigurationsForm.value.HeaderKey = null;
            this.APIConfigurationsForm.value.HeaderKeyValue = null;
        } else {
            this.APIConfigurationsForm.value.UserName = null;
            this.APIConfigurationsForm.value.Password = null;
            this.APIConfigurationsForm.value.TokenURL = null;
            this.APIConfigurationsForm.value.GrantType = null;
        }
        this.settingsService.AddAPIConfigurations(this.APIConfigurationsForm.value).subscribe(
            (data) => {
                this.toastr.success('API Configurations Added Successfully!', 'Success');
                this.spinnerService.setSpinner(false);
            }, err => {
                this.commonService.handleError(err);
            }
        )
    }


}