import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextShapesComponent } from './text-shapes.component'
import { TextShapeService } from './text-shapes.service';
import { ReactiveFormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: TextShapesComponent
    //   },

    //   {path: '**', redirectTo: 'properties'}
    // ])
  ],
  declarations: [
    TextShapesComponent
  ],
  providers: [TextShapeService]
})

export class TextShapesModule { }
