import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from "./Spinner/spinner.component";
import { TabComponent } from "./tabs/tab.component";
import { TabsComponent } from "./tabs/tabs.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [SpinnerComponent, TabComponent, TabsComponent],
    providers: [],
    exports: [CommonModule, SpinnerComponent, TabComponent, TabsComponent]
})
export class SharedModule { }