import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { DashboardModel } from './dashboard.model';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { CommonService } from 'src/app/Common/Services/common.service';
import { OrderService } from '../orders/orders.service';
import { OrderInfoModel } from '../orders/orderinfo.model';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
    , providers: [DashboardService, OrderService]
})

export class DashboardComponent implements OnInit {
    data: any;
    countsToShow: DashboardModel;
    orderDetailToShow: OrderInfoModel[];
    public noRecords = "";
    public email: string = '';
    @ViewChild(RouterOutlet) outlet: RouterOutlet;

    constructor(
        private spinnerService: SpinnerService,
        private orderService: OrderService,
        public commonService: CommonService,
        private dashboardService: DashboardService,
        private router: Router
        ) {
    }
    ngOnInit() {
        this.router.events.subscribe(e => {
            if (e instanceof ActivationStart && e.snapshot.outlet === "dashboard")
                this.outlet.deactivate();
        });
        this.spinnerService.setSpinner(true);
        this.dashboardService.getAllCardCountsByAccountID(this.commonService.getAccountID()).subscribe(
            (result) => {
                this.countsToShow = result;
                this.data = {
                    labels: ['Cliparts', 'Images', 'Image Masks', 'Patterns', 'Backgrounds', 'Textures'],
                    datasets: [
                        {
                            data: [result.allClipartCount, result.allImagesCount, result.allImageMaskCount, result.allPatternsCount, result.allBackgroundsCount, result.allTexturesCount],
                            backgroundColor: [
                                "#de3950",
                                "#1ba878",
                                "#e1a116",
                                "#7171b4",
                                "#4aacda",
                                "#ff743f",
                            ],
                            hoverBackgroundColor: [
                                "#de3950",
                                "#1ba878",
                                "#e1a116",
                                "#7171b4",
                                "#4aacda",
                                "#ff743f",
                            ]
                        }
                    ]
                }
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.spinnerService.setSpinner(false);
                this.commonService.handleError(err);
            }
        )
        //ToDo: need to Replace with the Custom Range Bar Inputs
        var fromDate = new Date('2020-02-12').toISOString();
        var toDate = new Date().toISOString();

        this.orderService.getOrderInfoDataByDateRange(this.commonService.getAccountID(), fromDate, toDate).subscribe(
            (data) => {
                this.orderDetailToShow = data;
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.spinnerService.setSpinner(false);
                this.commonService.handleError(err);
            }
        )
    }


    //Open Popup
    openPopUp() {
        document.getElementById('myModal').style.display = "block";
    }

    //Close Popup
    closePopup() {
        document.getElementById('myModal').style.display = "none";
    }

    //StoreMail
    StoreMail() {
        if (this.email) {
            this.dashboardService.sendContactMail(this.commonService.getAccountID(), this.email).subscribe(
                (result) => {
                },
                (err) => {
                    this.commonService.handleError(err);
                }
            )
        }
    }

}