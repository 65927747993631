import { Component, OnInit } from '@angular/core';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { StoreRequestsModel } from "../Approve-Register-Requests/Approve-Register.model";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { DataService } from '../../../../Common/Services/datashare.service';
import {CommonService} from '../../../../Common/Services/common.service';
@Component({
    selector: 'view-details',
    templateUrl: './ViewDetails.component.html',
    styleUrls: ['./ViewDetails.component.css']
})

export class ViewDetailsComponent implements OnInit {
    statusDisabled: boolean;
    storeRequestInEdit: FormGroup;
    storeRequestData: any;
    urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    public mobileNumberMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    constructor(private spinnerService: SpinnerService, private toastr: ToastrService, private settingService: SettingsService, private datashare: DataService, public commonService:CommonService) { }

    ngOnInit() {
        this.storeRequestInEdit = this.generateFormGroup();
        this.spinnerService.setSpinner(true);
        this.storeRequestData = this.datashare.getStoreRequestData();
        if (!this.storeRequestData) {
            this.commonService.navigate('store-requests');
        }
        else {
            if (this.storeRequestData.Status == 1 || this.storeRequestData.Status == 2) {
                this.statusDisabled = true;
            }
            this.storeRequestInEdit = new FormGroup({
                Name: new FormControl(this.storeRequestData.Name, [Validators.required, this.noWhitespaceValidator]),
                WebSiteUrl: new FormControl(this.storeRequestData.WebSiteUrl, [Validators.required, Validators.pattern(this.urlPattern)]),
                AccountID: new FormControl(this.storeRequestData.AccountID),
                AccountLogo: new FormControl(this.storeRequestData.AccountLogo),
                ToolUrl: new FormControl(this.storeRequestData.ToolUrl, [Validators.required, Validators.pattern(this.urlPattern)]),
                AdminUrl: new FormControl(this.storeRequestData.AdminUrl, [Validators.required, Validators.pattern(this.urlPattern)]),
                AccountToolUrl:new FormControl(this.storeRequestData.AccountToolUrl),
                AccountAdminUrl:new FormControl(this.storeRequestData.AccountAdminUrl),
                ContactPersonFirstName: new FormControl(this.storeRequestData.ContactPersonFirstName, [Validators.required, this.noWhitespaceValidator]),
                ContactPersonLastName: new FormControl(this.storeRequestData.ContactPersonLastName, [Validators.required, this.noWhitespaceValidator]),
                ContactPersonEmail: new FormControl(this.storeRequestData.ContactPersonEmail, Validators.compose([
                    Validators.required,
                    Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
                ])),
                ContactPersonPhone: new FormControl(this.storeRequestData.ContactPersonPhone, [Validators.required]),
                FromEmailID: new FormControl(this.storeRequestData.FromEmailID),
                AddressLine1: new FormControl(this.storeRequestData.AddressLine1, [Validators.required, this.noWhitespaceValidator]),
                AddressLine2: new FormControl(this.storeRequestData.AddressLine2),
                City: new FormControl(this.storeRequestData.City, [Validators.required, this.noWhitespaceValidator]),
                ZipCode: new FormControl(this.storeRequestData.ZipCode, [Validators.required, this.noWhitespaceValidator]),
                State: new FormControl(this.storeRequestData.State, [Validators.required, this.noWhitespaceValidator]),
                Country: new FormControl(this.storeRequestData.Country, [Validators.required, this.noWhitespaceValidator]),
                _id: new FormControl(this.storeRequestData._id),
                Status: new FormControl(this.storeRequestData.Status),
                Deleted: new FormControl(this.storeRequestData.Deleted),
                Key:new FormControl(this.storeRequestData.Key),
                SharedSecret:new FormControl(this.storeRequestData.SharedSecret),

            });
            this.spinnerService.setSpinner(false);
        }
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }

    updateStoreRequest() {
        this.spinnerService.setSpinner(true);
        this.settingService.updateStoreRequestData(this.storeRequestInEdit.value).subscribe(
            (data) => {
                this.spinnerService.setSpinner(false);
                if (data.Status == 1) {
                    this.toastr.success('StoreRequest Approved Successfully!', 'Success');
                }
                else if (data.Status == 0) {
                    this.toastr.success('StoreRequest Pending Successfully!', 'Success');
                }
                else if (data.Status == 2) {
                    this.toastr.success('StoreRequest Declined Successfully!', 'Success');
                }
                this.commonService.navigate('store-requests');
                
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }
    
    generateFormGroup() {
        return new FormGroup({
            Name: new FormControl(""),
            WebSiteUrl: new FormControl(""),
            AccountID: new FormControl(this.commonService.getAccountID()),
            AccountLogo: new FormControl(""),
            ToolUrl: new FormControl("", [Validators.required, Validators.pattern(this.urlPattern)]),
            AdminUrl: new FormControl("", [Validators.required, Validators.pattern(this.urlPattern)]),
            AccountToolUrl:new FormControl(""),
            AccountAdminUrl:new FormControl(""),
            ContactPersonFirstName: new FormControl(""),
            ContactPersonLastName: new FormControl(""),
            ContactPersonEmail: new FormControl(""),
            ContactPersonPhone: new FormControl(""),
            FromEmailID: new FormControl(""),
            AddressLine1: new FormControl(""),
            AddressLine2: new FormControl(""),
            City: new FormControl(""),
            ZipCode: new FormControl(""),
            State: new FormControl(""),
            Country: new FormControl(""),
            Status: new FormControl(0),
            Deleted: new FormControl(false),
            Key:new FormControl(""),
            SharedSecret:new FormControl("")
        });
    }







}