import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClipartComponent } from './clipart.component'
import { ClipartService } from './clipart.service';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { PipesModule } from '../../MainApp/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    FormsModule,
    ConfirmDialogModule,
    PipesModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: ClipartComponent
    //   },
    //   { path: '**', redirectTo: 'ClipartComponent' }
    // ])
  ],
  declarations: [ClipartComponent],
  providers: [ClipartService, ConfirmationService]
})
export class ClipartModule { }
