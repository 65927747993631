import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { FileImageComponent } from './File-Image/File-Image.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FontSizeComponent } from './Font-Size/File-Size.component';
import { PreloadedItemsComponent } from './PreloadedItems/preloadedItems.component';
import { NumberOfElementsComponent } from './NumberOfElements/numberOfElements.component';
import { SettingsService } from './settings.service';
import { ApproveRegisterComponent } from "./Approve-Register-Requests/Approve-Register.component";
import { TableModule } from 'primeng/table';
import { DataService } from '../../../Common/Services/datashare.service';
import { ViewDetailsComponent } from './ViewDetailsOfRequest/ViewDetails.component';
import { StoreProfileComponent } from './Store-Profile/Store-Profile.component'
import { TabViewModule } from 'primeng/tabview';
import { PipesModule } from '../../MainApp/pipes/pipes.module';
import { AuthGuard } from '../../../Common/Services/authguard.service';
import { APIConfigurationsComponent } from './APIConfigurations/APIConfigurations.component';
// import { TextMaskModule } from 'angular2-text-mask';
import { InputMaskModule } from 'primeng/inputmask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    // TextMaskModule,
    InputMaskModule,
    TableModule,
    TabViewModule,
    ReactiveFormsModule,
    // RouterModule.forChild([
    //   {
    //     path: 'file-image', component: FileImageComponent
    //   },
    //   {
    //     path: 'font-size', component: FontSizeComponent
    //   },
    //   {
    //     path: 'preloaded-item', component: PreloadedItemsComponent
    //   },
    //   {
    //     path: 'number-elements', component: NumberOfElementsComponent
    //   },
    //   {
    //     path: 'store-requests', component: ApproveRegisterComponent, canActivateChild: [AuthGuard]
    //   },
    //   {
    //     path: 'view-Details', component: ViewDetailsComponent
    //   },
    //   {
    //     path: 'store-Profile', component: StoreProfileComponent, canActivateChild: [AuthGuard]
    //   },
    //   {
    //     path: 'api-configurations', component: APIConfigurationsComponent, canActivateChild: [AuthGuard]
    //   },

    //   { path: '**', redirectTo: 'FileImageComponent' }
    // ])
  ],
  declarations: [SettingsComponent, FileImageComponent, StoreProfileComponent, FontSizeComponent, PreloadedItemsComponent, NumberOfElementsComponent, ApproveRegisterComponent, ViewDetailsComponent,
    APIConfigurationsComponent],
  providers: [SettingsService, DataService]
})
export class SettingsModule { }
