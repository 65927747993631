import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FontFileModel } from './font-files.model';
import { FontFileService } from './font-files.service'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import {ValidateUniqueName} from '../../../Common/Services/asyncUniqueName.validator';
import {CommonService} from '../../../Common/Services/common.service';
import {Enums} from '../../../Common/enums';
@Component({
    selector: 'app-font-files',
    templateUrl: './font-files.component.html',
    styleUrls: ['./font-files.component.css']
})

export class FontFileComponent implements OnInit {
      fontFilesToShow: FontFileModel[];
      searchText:String;
      selectedFontFile = null;
      fontFileInEdit: FormGroup;
      errormsg = "";
      public noRecords = "";
      showUpdateButton: boolean = true;
      showAddButton: boolean = true;
      selectedFileName: string = "";
      formData: FormData;
       @ViewChild('editFont') myeditFontFile: ElementRef;
    constructor(private fontFileService: FontFileService, private spinnerService: SpinnerService, private toastr: ToastrService,private confirmationService: ConfirmationService,public commonService:CommonService) { }

    ngOnInit() {
        this.fontFileInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.showAddButton = false;
        this.spinnerService.setSpinner(true);
        this.reloadData();
        this.spinnerService.setSpinner(true);
        this.formData = new FormData();
    }

    reloadData(){
        this.fontFileService.getFontFileDataByAccountId(this.commonService.getAccountID()).subscribe(
            (data) => {
                console.log(data);
                this.fontFilesToShow = data;
                this.fontFilesToShow.length == 0 ?  this.noRecords = "No Records Found" : this.noRecords = "";
                this.fontFilesToShow.forEach(element => {
                var fontFilesFamilyDiv = document.getElementById("fontFileFamily");
                var fontface = document.createElement("style");
                 fontface.innerHTML = "@font-face{font-family:" + element.FontFamily + "; src:url('" + element.FontUrl + "') format('truetype');}";
                 fontFilesFamilyDiv.appendChild(fontface);
                });
  
                this.spinnerService.setSpinner(false);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

        onFileSelected(event) {
            this.fontFileInEdit.controls['FontUrl'].setValue(''); 
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.selectedFontFile = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedFontFile);
            this.fontFileInEdit.controls['FontUrl'].setValue(this.selectedFontFile ? this.selectedFontFile.name : ''); 
            if(!this.selectedFontFile.name.endsWith("TTF") && !this.selectedFontFile.name.endsWith("ttf"))
            {
                this.selectedFileName = "";
                this.formData.delete('image');
                this.fontFileInEdit.controls['FontUrl'].setValue('');
                this.errormsg = "Only ttf formats are allowed"; return;
            }
        }

    }
    
    clearSelectedFile() {
        this.selectedFontFile = null;
        this.selectedFileName = "";
        this.myeditFontFile.nativeElement.value = "";
        this.fontFileInEdit.controls['FontUrl'].setValue(''); 
    }
   
    isNumberkey(event){
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }
     
    onFontFileSubmit() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.fontFileInEdit.value));
        this.fontFileService.addFontFile( this.formData).subscribe(
            (data) => {
                this.selectedFontFile = null;
                this.selectedFileName = "";
                this.myeditFontFile.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                this.reloadData();
                this.toastr.success('Font File Added Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.closePopup();
    }

    deleteFontFile(fontFile) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Font File?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.selectedFontFile = null;
                this.selectedFileName = "";
                this.myeditFontFile.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(true);
                this.fontFileService.deleteFontFile(fontFile).subscribe(
                    (data) => {
                        this.reloadData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Font File Deleted Successfully!', 'Success');
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                ) 
            },
            reject: () => {

            }
        });
    }

    editFontFile(fontFile) {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.selectedFileName = fontFile.FontFamily + ".ttf"
        this.fontFileInEdit = new FormGroup({
            Name: new FormControl(fontFile.Name,[Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.fontFile,Enums.FieldNames.Name,fontFile.Name,this.commonService.getAccountID())]),
            AccountID: new FormControl(fontFile.AccountID),
            Tags:new FormControl(fontFile.Tags),
            ModifiedBy:new FormControl(this.commonService.getAccountUserID()),
            FontFamily: new FormControl(fontFile.FontFamily),
            FontUrl: new FormControl(this.selectedFileName,[Validators.required]),
            Price: new FormControl(fontFile.Price.toFixed(2),[Validators.required]),
            Status:new FormControl(fontFile.Status),
            FontID: new FormControl(fontFile.FontID)
        });
    }

    updateFontFile() {
        this.spinnerService.setSpinner(true);
        this.formData.set('data', JSON.stringify(this.fontFileInEdit.value));
        this.fontFileService.updateFontFile(this.formData).subscribe(
            (data) => {
                this.reloadData();
                this.fontFileInEdit = this.generateFormGroup();
                this.spinnerService.setSpinner(false);
                this.formData = new FormData();
                this.myeditFontFile.nativeElement.value = "";
                this.toastr.success('Font File Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.fontFileInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
    }

    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }   
    
    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = false;
        this.showAddButton = true;
        this.selectedFileName = "";
    }

    closePopup() {
        this.selectedFontFile = null;
        this.selectedFileName = "";
        this.errormsg = "";
        document.getElementById('myModal').style.display = "none";
        this.fontFileInEdit = this.generateFormGroup();
    }
    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }


     generateFormGroup() {
        return new FormGroup({
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.fontFile,Enums.FieldNames.Name,null,this.commonService.getAccountID())]),
            Tags:new FormControl(''),
            FontFamily: new FormControl(''),
            FontID: new FormControl(''),
            Price: new FormControl('',[Validators.required]),
            CreatedBy:new FormControl(this.commonService.getAccountID()),
            ModifiedBy:new FormControl(this.commonService.getAccountUserID()),
            FontUrl:new FormControl('',[Validators.required]),
            Status:new FormControl(true),
            AccountID: new FormControl(this.commonService.getAccountID())
        });
    }
}
