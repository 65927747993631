import { Component, OnInit } from '@angular/core';
import { WordCloudModel } from './word-cloud.model';
import { WordCloudService } from './word-cloud.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import {CommonService} from '../../../Common/Services/common.service';
@Component({
    selector: 'app-word-cloud',
    templateUrl: './word-cloud.component.html',
    styleUrls: ['./word-cloud.component.css']
})

export class WordCloudComponent implements OnInit {
    allWordClouds: WordCloudModel[];
    wordCloudsToShow: WordCloudModel[];
    modalImage: String = "";
    modalName: String = "";
    modalPrice: string = "";
    selectedWordCloud = null;
    wordCloudInEdit: FormGroup;
    selectedFile: File = null;
    createdDateValue: Date;
    modifiedDateValue: Date;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    selectedFileName: string = "";
    formData: FormData;
    constructor(private wordCloudService: WordCloudService, private spinnerService: SpinnerService, private toastr: ToastrService,public commonService:CommonService) { }

    ngOnInit() {
        this.wordCloudInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.showAddButton = false;
        this.spinnerService.setSpinner(true);
        this.reloadData();
        this.formData = new FormData();
    }

    reloadData(){
        this.wordCloudService.getAllWordClouds().subscribe(
            (data) => {
                this.allWordClouds = data;
                this.wordCloudsToShow = data;
                this.spinnerService.setSpinner(false);
                console.log(data);
            },
            (err) => {
             this.commonService.handleError(err);
            }
        )
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }

    generateFormGroup() {
        return new FormGroup({
            WordCloudID: new FormControl(0),
            AccountID: new FormControl(this.commonService.getAccountID()),
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            Tags: new FormControl(''),
            Price: new FormControl(0),
            WordCloudUrl: new FormControl('', [Validators.pattern(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/)]),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            CreatedDate: new FormControl(Date),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(Date),
            Status: new FormControl(true),
            Deleted: new FormControl(false),
        });
    }
}