import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AccountModel } from '../Models/account.model'

@Injectable()
export class AccountService {
constructor(private httpClient: HttpClient) { }


    getAccountById(accountID): Observable<AccountModel> {
        return this.httpClient.get<AccountModel>('store-requests/get-store-details',{ params: { accountID } });
    }
    getAccountByAccountAdminUrl(accounturl:string) :Observable<AccountModel> {
        return this.httpClient.post<AccountModel>('login/getAccountByAccountAdminUrl',{accounturl});
    }
}