import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-artwork',
  templateUrl: './artwork.component.html',
  styleUrls: ['./artwork.component.css']
})
export class ArtworkComponent implements OnInit {

  
  showArtwork:boolean = false;
  ArtworkPrice;
  data:any;
  @Input('IsArtwork') IsArtWork: boolean = false;
  // @Input('Data') data: any = [];
  @Input('Data')
  set _getData(data: any) {
     this.data = data;
     if(this.data){
      this.showArtwork = this.data.Price > 0 ? true : false;
     }
  }

  @Output('OnAdd') ChangeEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(private toastr: ToastrService) { }

  ngOnInit() {}

  //Passing the value to Parent 
  // StoreArtPrice(){
  //   this.ArtworkPrice ? this.ChangeEvent.emit(this.data) : this.toastr.warning('Enter the Price!', 'Warning');
  // }

  //Check Number or not // Allow only Numbers
  isNumberkey(event){ return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57); }
 
}
