import { Injectable } from '@angular/core';
import { ProductDetailModel } from '../../Modules/MainApp/product/productDetail.model';
import { ProductModel } from '../../Modules/MainApp/product/product.model';
import { StoreRequestsModel } from '../../Modules/MainApp/settings/Approve-Register-Requests/Approve-Register.model'
import { AccountRolesModel } from '../../Modules/MainApp/account-roles/account-roles.model';
import { PrintProfileModel } from 'src/app/Modules/MainApp/print-profile/print-profile.model';

@Injectable()
export class DataService {
    serviceData: ProductDetailModel;
    productServiceDate: ProductModel;
    storeRequestData: StoreRequestsModel;
    roleData: AccountRolesModel;
    printProfileData: PrintProfileModel;
    treeData:any;

    
    getdata() {
        return this.serviceData;
    }
    setdata(value: ProductDetailModel) {
        this.serviceData = value;
    }

    getProductData() {
        return this.productServiceDate;
    }

    setProductData(value: any) {
        return this.productServiceDate = value;
    }
    getStoreRequestData() {
        return this.storeRequestData;
    }

    setStoreRequestData(value: StoreRequestsModel) {
        return this.storeRequestData = value;
    }

    getRoleData() {
        return this.roleData;
    }

    setRoleData(value: AccountRolesModel) {
        return this.roleData = value;
    }
    
    setPrintProfileData(value: any){
        return this.printProfileData = value;
    }

    getPrintProfileData() {
        return this.printProfileData;
    }

    setTree(value: any){
        const arr = [];
        const groups = {};
        let groupIndex = 0;let groupKey = 'Tags'
        value.forEach((item, i) => {
            const withIndex = {
              ...item,
              index: i
            };
            const groupName = item[groupKey];
            
            if (!groups.hasOwnProperty(groupName)) {
              groups[groupName] = groupIndex++;
        
              arr.push({
                name: groupName,
                children: [withIndex]
              });
            } else {
              arr[groups[groupName]].children.push(withIndex);
            }
          });
        
          return arr;
        }
    

}