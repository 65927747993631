import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clipart',
  templateUrl: './clipart.component.html',
  styleUrls: ['./clipart.component.css']
})
export class ClipartComponent implements OnInit {
  showClipArt:boolean = false;
  IsEnableQuote:boolean = false;
  IsQuantityTierEnabled = false;
  Valid:boolean = true;
  data: any =[];

  SleeveTierData:any=[];

  // @Input('Data') data: any = [];
  @Input('Data')
  set _getData(data: any) {
     this.data = data;
     if(this.data.TiersData && this.data.TiersData.TierData[0].Price){
      this.showClipArt = this.IsQuantityTierEnabled = this.data.TiersData.TierData.length > 0 ? this.data.TiersData.TierData[0].Price[0] !=0 ? true : false : false ;
     }
  }

  @Output('OnAdd') ChangeEvent: EventEmitter<any> = new EventEmitter<string>();

  constructor(private toastr: ToastrService) { }
   
  ngOnInit() {}
  
  //Get from Child when every change
  StoreTierData($event){
    this.SleeveTierData = $event;
    this.data.TiersData = $event;

     //Validation
     this.data.TiersData.TierData.forEach(val => {
      for (let key in val.Price) {
        if((val.Price[key] == null) && this.data.Enabled){
          this.Valid = false
        }
    }
    });
    let data = {
      Valid : this.Valid,
      Data : this.data
    }
    this.ChangeEvent.emit(data);
    this.Valid = true;
  }
  
  //Store Data
  // StoreSleeve(){
  //     this.IsQuantityTierEnabled ? this.ChangeEvent.emit(this.SleeveTierData) : this.toastr.warning('Enter the Price!', 'Warning');
  // }

  //Check Number or not // Allow only Numbers
  isNumberkey(event){ return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57); }

}

