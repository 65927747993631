import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { DashboardModel } from './dashboard.model';

@Injectable()
export class DashboardService {
    constructor(private httpClient: HttpClient) { }
 
    getAllCardCountsByAccountID(accountID): Observable<DashboardModel> {
        return this.httpClient.get<DashboardModel>('dashboard/getAllCardCountsByAccountID', { params: { accountID } });
    }

    getOrderInfoDataByDateRange(AccountID, fromDate, toDate): Observable<any> {
        return this.httpClient.get<any>('order-info/get-order-info-data-by-date-range', { params: { AccountID, fromDate, toDate } });
    }

    sendContactMail(AccountID, Email): Observable<any> {
        return this.httpClient.get<any>('dashboard/send-contact-mail', { params: { AccountID, Email } });
    }
}