import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import { ProductCategoryModel } from './product-category.model';
import { ProductCategoryService } from './product-category.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ValidateUniqueName } from '../../../Common/Services/asyncUniqueName.validator';
import { CommonService } from '../../../Common/Services/common.service';
import { Enums } from '../../../Common/enums';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-product-category',
    templateUrl: './product-category.component.html',
    styleUrls: ['./product-category.component.css']
})

export class ProductCategoryComponent implements OnInit {
    public errormsg;
    modalName: String;
    fileName: String = "";
    commonSearchText: String;
    modalImage: String;
    public noRecords: String;
    formData: FormData;
    editedImage: String;
    totalRecords: number;
    createdDateValue: Date;
    modifiedDateValue: Date;
    selectedCategory = null;
    selectedFileName: String;
    productCategoryID: number;
    showImage: boolean = true;
    showAddButton: boolean = true;
    validateUpload: boolean = false;
    showUpdateButton: boolean = true;
    productCategoryInEdit: FormGroup;
    allProductCategories: ProductCategoryModel[];
    productCategoriesToShow: any;
    searchText: string = '';
    tableHeaders: any;
    @ViewChild('dt') table: Table;
    @ViewChild('editCategory') myEditCategory: ElementRef;
    constructor(private productCategoryService: ProductCategoryService, private spinnerService: SpinnerService, private toastr: ToastrService, private confirmationService: ConfirmationService, private safePipe: SafePipe, public commonService: CommonService) { }

    ngOnInit() {
        this.spinnerService.setSpinner(true);
        this.productCategoryInEdit = this.generateFormGroup();
        this.editedImage = "";
        this.showUpdateButton = false;
        this.showImage = true;
        this.showAddButton = false;
        this.tableHeaders = [
            { field: 'CategoryName', header: 'Name' },
            { field: 'CategoryCode', header: 'Code' },
            { field: 'Status', header: 'Status' },
        ];
        // this.reloadData();
        this.formData = new FormData();
    }

    reloadData() {
        this.productCategoryService.getProductCategoryDataByAccountId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allProductCategories = data;
                this.productCategoriesToShow = data;
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }

    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }

    searchCategory() {
        this.table.reset();
        let event = {
            "first": 0,
            "rows": this.table.rows
        }
        this.loadCategoriesLazy(event);
    }


    loadCategoriesLazy(event) {
        let first = event.first;
        let rows = event.rows;
        let sortObject = {
            "sortField": "_id",
            "sortOrder": 1
        }
        if (event.sortField != undefined) {
            sortObject["sortField"] = event.sortField;
            sortObject["sortOrder"] = event.sortOrder;
        }
        if (first == 0) {
            this.spinnerService.setSpinner(true);
            this.productCategoryService.getProductCategoryCountByAccountId(this.commonService.getAccountID(), this.searchText).subscribe(data => {
                if (data && data.count) {
                    this.totalRecords = data.count;
                    data.count == "0" ? this.noRecords = "No Records Found" : this.noRecords = "";
                }
            }, err => {
                this.commonService.handleError(err);
            })
        }
        this.spinnerService.setSpinner(true);
        this.getCategories(first, rows, sortObject);
        // this.sort(event.sortField,event.sortOrder)
    }


    getCategories(first, rows, sortObject) {
        this.productCategoryService.getProductCategoryDataByAccountIdAndLazyloading(this.commonService.getAccountID(), this.searchText, first, rows, sortObject.sortField, sortObject.sortOrder).subscribe(
            (data) => {
                this.productCategoriesToShow = data;
                this.spinnerService.setSpinner(false);
            }, err => {
                this.commonService.handleError(err);
            }
        )
    }

    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.validateUpload = false;
            this.selectedCategory = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedCategory);
            this.productCategoryInEdit.controls['ImageUrl'].setValue(this.selectedCategory ? this.selectedCategory.name : '');
            if (!this.selectedCategory.type.endsWith("image/jpeg") && !this.selectedCategory.type.endsWith("image/jpg") && !this.selectedCategory.type.endsWith("image/png") && !this.selectedCategory.type.endsWith("image/svg+xml")) {
                this.selectedFileName = "";
                this.validateUpload = true;
                this.editedImage = "";
                this.productCategoryInEdit.controls['ImageUrl'].setValue('');
                this.formData.delete('image');
                this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = String((<FileReader>event.target).result);
            }
            reader.readAsDataURL(event.target.files[0]);

        }

    }

    getSafeUrl(image) {
        return this.safePipe.transform(image, 'url');
    }

    clearSelectedFile() {
        this.selectedCategory = null;
        this.selectedFileName = "";
        this.formData.delete('image');
        this.myEditCategory.nativeElement.value = "";
        this.errormsg = "";
        this.editedImage = "";
    }

    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.productCategoryInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.showAddButton = true;
        this.editedImage = "";
        this.showImage = true;
    }

    closePopup() {
        this.selectedCategory = null;
        this.selectedFileName = "";
        document.getElementById('myModal').style.display = "none";
        this.productCategoryInEdit = this.generateFormGroup();
        this.errormsg = "";
        this.editedImage = "";
    }
    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.productCategoryImage;
        this.modalName = element.productCategoryName;
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }

    addProductCategory() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.productCategoryInEdit.value));
        this.productCategoryService.addProductCategory(this.formData).subscribe(
            (data) => {
                this.selectedCategory = null;
                this.selectedFileName = "";
                this.myEditCategory.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                this.reloadData();
                this.toastr.success('Category Added Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.closePopup();
    }

    filterByProductCategoryCreatedDate() {
        this.productCategoriesToShow = this.allProductCategories.filter(element => {
            let dbDate = moment(element.CreatedDate);
            let selectedDate = moment(this.createdDateValue);
            return moment(dbDate).isSame(selectedDate, 'day')
        });
    }

    filterByProductCategoryModifiedDate() {
        this.productCategoriesToShow = this.allProductCategories.filter(element => {
            let dbDate = moment(element.ModifiedDate);
            let selectedDate = moment(this.modifiedDateValue);
            return moment(dbDate).isSame(selectedDate, 'day')
        });
    }
    clearFilter() {
        this.productCategoriesToShow = this.allProductCategories;
    }

    clearInputFilter() {
        this.productCategoriesToShow = this.productCategoriesToShow;
    }

    deleteProductCategory(productCategory) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Category?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.productCategoryService.deleteProductCategory(productCategory).subscribe(
                    (data) => {
                        this.selectedCategory = null;
                        this.selectedFileName = "";
                        this.myEditCategory.nativeElement.value = "";
                        this.formData = new FormData();
                        this.reloadData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Category Deleted Successfully!', 'Success');
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    editProductCategory(productCategory) {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.showImage = false;
        this.editedImage = productCategory.ImageUrl;
        this.productCategoryInEdit = new FormGroup({
            Status: new FormControl(productCategory.Status),
            ImageUrl: new FormControl(productCategory.ImageUrl, [Validators.required]),
            CategoryID: new FormControl(productCategory.CategoryID),
            Deleted: new FormControl(productCategory.Deleted),
            CreatedBy: new FormControl(productCategory.CreatedBy),
            CreatedDate: new FormControl(productCategory.CreatedDate),
            AccountID: new FormControl(productCategory.AccountID),
            CategoryCode: new FormControl(productCategory.CategoryCode, [Validators.required, this.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.productCategory, Enums.FieldNames.CategoryCode, productCategory.CategoryCode, this.commonService.getAccountID())]),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(new Date()),
            CategoryName: new FormControl(productCategory.CategoryName, [Validators.required, this.noWhitespaceValidator]),
            AccountCategoryID: new FormControl(productCategory.AccountCategoryID),
            _id: new FormControl(productCategory._id)

        });
    }

    updateProductCategory() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.productCategoryInEdit.value));
        this.productCategoryService.updateProductCategory(this.formData).subscribe(
            (data) => {
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.formData = new FormData();
                this.myEditCategory.nativeElement.value = "";
                this.toastr.success('Category Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.productCategoryInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }

    generateFormGroup() {
        return new FormGroup({
            Status: new FormControl(true),
            ImageUrl: new FormControl(this.fileName, [Validators.required]),
            CategoryID: new FormControl(''),
            Deleted: new FormControl(false),
            CategoryCode: new FormControl('', [Validators.required, this.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.productCategory, Enums.FieldNames.CategoryCode, null, this.commonService.getAccountID())]),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            CreatedDate: new FormControl(new Date()),
            AccountID: new FormControl(this.commonService.getAccountID()),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(new Date()),
            CategoryName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            AccountCategoryID: new FormControl(0)

        });
    }
}