import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';
import { OrderComponent } from './orders.component';
import { OrderService } from './orders.service';
import { FormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    TableModule,
    //  RouterModule.forChild([
    //   {
    //     path: '', component: OrderComponent
    //   },
    //   {path: '**', redirectTo: 'properties'}
    // ]),
    FormsModule
  ],
  declarations: [ OrderComponent],
  providers: [OrderService]
})
export class OrdersModule { }
