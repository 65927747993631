import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { UserModel } from './users.model';
import {AccountRolesModel} from '../account-roles/account-roles.model';

@Injectable()
export class AccountUserService {
    constructor(private httpClient: HttpClient) { }

    getAccountUserDataByAccountId(accountID): Observable<any> {
        return this.httpClient.get<any>('account-user/get-account-user-by-AccountID', { params: { accountID } })
    }
    getAccountUsersDataByAccountId(accountID): Observable<UserModel[]> {
        return this.httpClient.get<UserModel[]>('account-user/get-account-user-data-by-AccountID', { params: { accountID } })
    }

    getAccountUserDataById(accountUserID): Observable<UserModel> {
        return this.httpClient.get<UserModel>('account-user/get-account-user-by-ID', { params: { accountUserID } })
    }
     
    getAccountRolesByAccountIdAndActive(accountID): Observable<any> {
        return this.httpClient.get<any>('roles/get-roles-by-activeActiveAccountID', { params: { accountID } })
    }

    addAccountUser(user): Observable<UserModel> {
        return this.httpClient.post<UserModel>('account-user/add-accountUser', user);
    }

    getuserDetailByAccIdandLazyLoading(accountID, start, rows, sortField, sortOrder): Observable<any> {
        return this.httpClient.get<any>('account-user/get-user-details-by-accountID-pages', { params: { accountID, start, rows, sortField, sortOrder } })
    }

    updateAccountUser(user): Observable<UserModel> {
        return this.httpClient.put<UserModel>('account-user/update-accountUser', user);
    }

    updateAccountUserPassword(user): Observable<UserModel> {
        return this.httpClient.put<UserModel>('account-user/update-accountUser-Password', user);
    }


    deleteAccountUser(user): Observable<UserModel> {
        return this.httpClient.put<UserModel>('account-user/delete-accountUser', user);
    }

    decryptPassword(password): Observable<String> {
        console.log(password);
        return this.httpClient.get<String>('account-user/decrypt-password', { params: { password } });
    }

    encryptPassword(password):Observable<String> {
        console.log(password);
        return this.httpClient.get<String>('account-user/encrypt-password', { params: { password } });
    }

}