import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AccountRoleComponent } from './account-roles.component';
import { AddRoleComponent } from '../account-roles/addrole/addrole.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import {AccountRoleService} from '../account-roles/account-roles.service'
import { DataService } from '../../../Common/Services/datashare.service';
import {AccordionModule} from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { PipesModule } from '../../MainApp/pipes/pipes.module';
@NgModule({
  imports: [
    CommonModule,
    AccordionModule,
    PipesModule,
    ReactiveFormsModule,
    TableModule,
    ConfirmDialogModule,
    FormsModule,
    CheckboxModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: AccountRoleComponent
    //   },
    //   {
    //     path: 'addrole', component: AddRoleComponent
    //   },
    //   { path: '**', redirectTo: 'AccountRoleComponent' }
    // ])
  ],
  declarations: [
    AccountRoleComponent,AddRoleComponent
  ],
  providers: [AccountRoleService,ConfirmationService,DataService]
})
export class AccountRolesModule { }
