import { Component, OnInit } from '@angular/core';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { StoreRequestsModel } from "./Approve-Register.model";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../../../Common/Services/datashare.service'
import { SettingsService } from '../settings.service';
import {CommonService} from '../../../../Common/Services/common.service';
@Component({
    selector: 'approve-register',
    templateUrl: './Approve-Register.component.html',
    styleUrls: ['./Approve-Register.component.css']
})

export class ApproveRegisterComponent implements OnInit {
    storeRequestInEdit: FormGroup;
    commonSearchText: String;
    public noRecords:String;
    totalRecords: number;
    allStoreRequests: any;
    constructor(private spinnerService: SpinnerService, private toastr: ToastrService, private settingService: SettingsService, private dataShareService: DataService, public commonService:CommonService) { }

    ngOnInit() {
        this.spinnerService.setSpinner(true);
        this.storeRequestInEdit = this.generateFormGroup();
        this.settingService.getAllStoreRequests().subscribe(
            (data) => {
                this.allStoreRequests = data;
                console.log(data);
                this.spinnerService.setSpinner(false);
            },
            err => {
               this.commonService.handleError(err);
            }
        )
    }
    
    loadStoresLazy(event) {
        this.spinnerService.setSpinner(true);
        this.settingService.getAllStoreRequestsCount().subscribe(data => {
            if (data && data.count) {
                this.totalRecords = data.count;
                if(data.count == "0")
                {
                    this.noRecords = "No Records Found";
                }
                }
        }, err => {
            this.commonService.handleError(err);
        })
        this.spinnerService.setSpinner(true);
        let sortObject = {
            "sortField": "_id",
            "sortOrder": -1
        }
        if (event.sortField != undefined) {
            sortObject["sortField"] = event.sortField;
            sortObject["sortOrder"] = event.sortOrder;
        }
        this.settingService.getStoresLazyLoading(event.first, event.rows, sortObject.sortField, sortObject.sortOrder).subscribe(
            (data) => {
                this.allStoreRequests = data;
                console.log(this.allStoreRequests);
                this.spinnerService.setSpinner(false);
            }, err => {
                this.commonService.handleError(err);
            }
        )
    }

    editStoreRequest(storeData) {
        this.dataShareService.setStoreRequestData(storeData);
        this.commonService.navigate('view-Details');
    }
    
    generateFormGroup() {
        return new FormGroup({
            AccountID: new FormControl(this.commonService.getAccountID()),
            Name: new FormControl(''),
            WebSiteUrl: new FormControl(''),
            AccountLogo: new FormControl(''),
            ToolUrl: new FormControl(''),
            AdminURL: new FormControl(''),
            AccountToolUrl:new FormControl(''),
            AccountAdminUrl:new FormControl(''),
            ContactPersonFirstName: new FormControl(''),
            ContactPersonLastName: new FormControl(''),
            ContactPersonEmail: new FormControl(''),
            ContactPersonPhone: new FormControl(''),
            FromEmailID: new FormControl(''),
            AddressLine1: new FormControl(''),
            AddressLine2: new FormControl(''),
            City: new FormControl(''),
            ZipCode: new FormControl(''),
            State: new FormControl(''),
            Country: new FormControl(''),
            CreatedBy: new FormControl(this.commonService.getAccountID()),
            CreatedDate: new FormControl(Date),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(Date),
            Status: new FormControl(0),
            Deleted: new FormControl(false),
            Key:new FormControl(""),
            SharedSecret:new FormControl(""),
            _id: new FormControl('')
        });
    }






}