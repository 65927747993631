import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { BackgroundModel } from './backgrounds.model';

@Injectable()
export class BackgroundService {
    constructor(private httpClient: HttpClient) { }

    getBackgroundDataByAccountId(accountID): Observable<BackgroundModel[]> {
        return this.httpClient.get<BackgroundModel[]>('background/get-backgrounds-by-accountID', { params: { accountID } })
    }

    addBackground(background): Observable<BackgroundModel> {
        return this.httpClient.post<BackgroundModel>('background/add-background', background);
    }

    deleteBackground(background): Observable<BackgroundModel> {
        return this.httpClient.put<BackgroundModel>('background/delete-background', background);
    }

    updateBackground(background): Observable<BackgroundModel> {
        return this.httpClient.put<BackgroundModel>('background/update-background', background);
    }

}