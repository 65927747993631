import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { printProfileModel } from './print-profile.model';
import { PrintProfileModel } from './print-profile.model';
import { PriceSettings } from './print-profile.model';

@Injectable()
export class PrintProfileService {
    constructor(private httpClient: HttpClient) { }

    getPrintProfileDataByAccountId(accountID): Observable<PrintProfileModel[]> {
        return this.httpClient.get<PrintProfileModel[]>('print-profile/get-print-profiles-by-AccountID', { params: { accountID } })
    }

    addPrintProfile(printProfile): Observable<PrintProfileModel> {
        return this.httpClient.post<PrintProfileModel>('print-profile/add-print-profile', printProfile);
    }

    deletePrintProfile(printProfile): Observable<PrintProfileModel> {
        return this.httpClient.put<PrintProfileModel>('print-profile/delete-print-profile', printProfile);
    }

    updatePrintProfile(printProfile): Observable<PrintProfileModel> {
        return this.httpClient.put<PrintProfileModel>('print-profile/update-print-profile', printProfile);
    }

    StorePriceSetup(PriceSettings): Observable<PrintProfileModel> {
        return this.httpClient.post<PrintProfileModel>('print-profile/update-price', PriceSettings);
    }
}