import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { productSkuModel } from '../productsku/productsku.model';
import { ProductSkuService } from '../productsku/productsku.service';
import { ProductService } from '../product.service';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ProductModel } from '../product.model';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DataService } from '../../../../Common/Services/datashare.service';
import {CommonService} from '../../../../Common/Services/common.service';

@Component({
    selector: 'app-productsku',
    templateUrl: './productsku.component.html',
    styleUrls: ['./productsku.component.css']
})

export class ProductSkuComponent implements OnInit {
    dataFormProductTab: ProductModel;
    productSkuInEdit: FormGroup;
    formArray: FormArray;
    allProductSku: productSkuModel[];
    allproducts: any;
    allProductDetails: ProductModel[];
    validateUpload: boolean = false;
    selectedProduct = null;
    // selectedFileName = [{ "name": [] }];
    selectedSkewImages: any = [];
    items = [];
    formData: FormData;
    public errormsg = "";
    @ViewChild('myInput')
    myInputVariable: ElementRef;
    constructor(private productService: ProductService, private productSkuService: ProductSkuService, private spinnerService: SpinnerService, private toastr: ToastrService, private router: Router, private confirmationService: ConfirmationService, private dataService: DataService,private commonService:CommonService) {
        this.formArray = new FormArray([
            this.generateFormGroup()
        ]);
        this.productSkuInEdit = new FormGroup({
            productSkuDetails: this.formArray
        });
    }

    ngOnInit() {
        this.dataFormProductTab = this.dataService.getProductData();
        console.log(this.dataFormProductTab);
        if (!this.dataFormProductTab) {
            this.router.navigateByUrl('app/product/addproduct');
        }

        this.formData = new FormData();
        // this.productSkuInEdit = this.generateFormGroup();

    }
    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }

    isNumberkey(event){
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }

    onProductSkuSubmit() {
        // for (let i = 0; i < this.selectedSkewImages.length; i++) {
        //     this.formData.append('image', this.selectedSkewImages[i]);

        // }
        // for (let i = 0; i < this.selectedFileName.length; i++) {
        //     let str = "";
        //     for (let k = 0; k < this.selectedFileName[i]['name'].length; k++) {
        //         str = str + " |" + this.selectedFileName[i].name[k];
        //     }
        //     this.productSkuInEdit.value.productSkuDetails[i].images = str;
        //     str = "";
        // }


        // this.productSkuInEdit.value["ProductDetails"] = this.dataFormProductTab;
        // console.log(this.productSkuInEdit.value);
        // this.formData.append('data', JSON.stringify(this.productSkuInEdit.value));
        // this.productService.addProductDetail(this.formData).subscribe(
        //     (data) => {
        //         console.log(data);
        //     }, err => {
        //         console.log(err);
        //     }
        // )


    }


    onFileSelected(e, index) {

        if (!e.target.files) return;
        let files = e.target.files;
        let filesArr = Array.prototype.slice.call(files);
        let name = "";
        this.selectedSkewImages.push(filesArr);
        let selDiv = document.querySelector(".selectedFiles" + index);
        filesArr.forEach(element => {
            if (!element.type.match("image.*")) {
                return;
            }
            name = name + element.name + "|";
            let reader = new FileReader();
            reader.onload = function (e: ProgressEvent) {
                var html = "<li><img src=\"" + (<FileReader>e.target).result + "\">" + '<span>' + element.name + '</span>' + "<br clear=\"left\"/></li>";
                selDiv.innerHTML += html;
            }
            reader.readAsDataURL(element);

        });
        name = name.slice(0, -1);

        this.formArray.controls[index].value.images = name;
        console.log(this.selectedSkewImages);
        console.log(this.formArray);
    }
    addProductSkew() {
        const control = <FormArray>this.productSkuInEdit.controls['productSkuDetails'];
        control.push(this.generateFormGroup());
    }

    removeProductSkew(i: number) {
        const control = <FormArray>this.productSkuInEdit.controls['productSkuDetails'];
        control.removeAt(i);
    }


    generateFormGroup() {
        return new FormGroup({
            name: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            description: new FormControl(''),
            color: new FormControl(''),
            price: new FormControl(),
            images: new FormControl(),
            accountUserID: new FormControl(this.commonService.getAccountUserID()),
            accountID: new FormControl(this.commonService.getAccountID()),
            productSkuCode: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            stock: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            size: new FormControl('', [Validators.required, this.noWhitespaceValidator])
        });
    }
}