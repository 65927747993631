import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WordCloudComponent } from './word-cloud.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { WordCloudService } from './word-cloud.service';


@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,

    // RouterModule.forChild([
    //   {
    //     path: '**', component: WordCloudComponent
    //   }
    // ])
  ],
  declarations: [WordCloudComponent],
  providers: [WordCloudService]
})
export class WordCloudModule { }
