import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ImageMaskModel } from './image-masks.model';

@Injectable()
export class ImageMaskService {
    constructor(private httpClient: HttpClient) { }

    getImageMaskDataByAccId(accountID): Observable<ImageMaskModel[]> {
        return this.httpClient.get<ImageMaskModel[]>('image-mask/get-image-masks-by-accountID', { params: { accountID } });
    }
  
    addImageMask(ImageMask): Observable<ImageMaskModel> {
        return this.httpClient.post<ImageMaskModel>(`image-mask/add-image-mask`, ImageMask);
    }
    
    deleteImageMask(ImageMask): Observable<ImageMaskModel>{  
        return this.httpClient.put<ImageMaskModel>('image-mask/delete-image-mask', ImageMask);      
      }  

    updateImageMask(ImageMask): Observable<ImageMaskModel>{
        return this.httpClient.put<ImageMaskModel>('image-mask/update-image-mask', ImageMask);
    }
}