import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { AccountRolesModel } from './account-roles.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import {AccountRoleService} from '../account-roles/account-roles.service';
import { DataService } from '../../../Common/Services/datashare.service';
import {CommonService} from '../../../Common/Services/common.service';
import { Enums } from '../../../Common/enums';
@Component({
    selector: 'app-account-roles',
    templateUrl: './account-roles.component.html',
    styleUrls: ['./account-roles.component.css']
})

export class AccountRoleComponent implements OnInit {
    searchText: String;
    allRoles:any;
    showDelete:Boolean = true;
    public noRecords:String;
    totalRecords: number;
    roleData:AccountRolesModel;
    showClose:Boolean = false;
    showSearch:Boolean = true;
    constructor(private accountRoleService : AccountRoleService, private spinnerService: SpinnerService, private toastr: ToastrService, private datashare: DataService, private confirmationService: ConfirmationService,public commonService:CommonService){

    }
    ngOnInit() {
        this.spinnerService.setSpinner(true);
        this.reloadData();   
    }

    addRole(){
        this.datashare.setRoleData(this.roleData);
        this.commonService.navigate('addrole');
    }
    
    closeSearch()
    {
        this.searchText = "";
        this.showSearch = true;
        this.showClose = false;
    }
  
    
    SearchRole(event){
        if(event.target.value != null && event.target.value != "")
        {
             this.showSearch = false;
             this.showClose = true;
        }
        else
        {
            this.showSearch = true;
            this.showClose = false;
        }
    }

    reloadData(){
        this.accountRoleService.getRolesDataByAccId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.accountRoleService.getAccountRolesByRoleId(Enums.roleId.role).subscribe(
                    (roleData) => {
                        this.allRoles = data.concat(roleData);
                        this.allRoles.forEach(element => {
                            if(element.RoleID == Enums.roleId.role && this.commonService.getAccountID() !== Enums.accountId.One)
                            {
                                this.showDelete = false;
                            }
                            else
                            {
                                this.showDelete = true;
                            }
                        });
                        this.spinnerService.setSpinner(false);
                    },
                    (err) => {
                       this.commonService.handleError(err);
                    }
                )
                this.spinnerService.setSpinner(false);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    
    loadRolesLazy(event) {
        this.spinnerService.setSpinner(true);
        this.accountRoleService.getAccountRoleCountByAccountId(this.commonService.getAccountID()).subscribe(data => {
            console.log(data);
            if (data && data.count) {
                this.totalRecords = data.count;
                }
        }, err => {
            this.commonService.handleError(err);
        })
        this.spinnerService.setSpinner(true);
        let sortObject = {
            "sortField": "_id",
            "sortOrder": -1
        }
        if (event.sortField != undefined) {
            sortObject["sortField"] = event.sortField;
            sortObject["sortOrder"] = event.sortOrder;
        }
        this.accountRoleService.getRoleDetailByAccIdandLazyLoading(this.commonService.getAccountID(), event.first, event.rows, sortObject.sortField, sortObject.sortOrder).subscribe(
            (data) => {
                console.log(event.first);
                console.log(event.rows);
                console.log("here no.of rows");
                this.allRoles = data;
                console.log(this.allRoles);
                this.spinnerService.setSpinner(false);
            }, err => {
                this.commonService.handleError(err);
            }
        )
    }
    editRole(role){
        this.datashare.setRoleData(role);
        this.commonService.navigate('addrole');
    }

    getUserCount(role){
        this.accountRoleService.getUserCountByRoleID(role.RoleID).subscribe(
            (data) =>{
                if(data > 0){
                    this.toastr.info("Please untag the users before deleting the role", "Alert");
                }else{
                    this.deleteRole(role);
                }
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.spinnerService.setSpinner(false);
                this.commonService.handleError(err);
            }
        )
    }

    deleteRole(role) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Role?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.accountRoleService.deleteRole(role).subscribe(
                    (data) => {
                        this.reloadData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Role Deleted Successfully!', 'Success');
                    },
                    (err) => {
                       this.commonService.handleError(err);
                    }
                ) 
            },
            reject: () => {

            }
        });
    }
}