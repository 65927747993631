import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import {StoreRequestsModel} from '../../Modules/MainApp/settings/Approve-Register-Requests/Approve-Register.model';
@Injectable()
export class DomainValidationService {
    constructor(private httpClient: HttpClient) { }

    checkUniqueDomainURL(domainURL): Observable<boolean> {
        return this.httpClient.post<boolean>('login/check_unique_admin_domainurl', { domainURL })
    }
}