import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ColorModel } from './colors.model';

@Injectable()
export class ColorService {
    constructor(private httpClient: HttpClient) { }

    getColorsDataByAccId(accountID): Observable<ColorModel[]> {
        return this.httpClient.get<ColorModel[]>('colors/get-colors-by-accountID', { params: { accountID } });
    }

    addColor(color): Observable<ColorModel> {
        return this.httpClient.post<ColorModel>(`colors/add-color`, color);
    }
    
    deleteColor(color): Observable<ColorModel> {
        return this.httpClient.put<ColorModel>('colors/delete-color', color);
    }

    updateColor(color): Observable<ColorModel> {
        return this.httpClient.put<ColorModel>('colors/update-color', color);
    }

}