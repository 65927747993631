import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserModel } from './users.model';
import { AccountRolesModel } from '../account-roles/account-roles.model';
import { AccountUserService } from './users.service';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AccountRoleService } from '../account-roles/account-roles.service';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import { ValidateUniqueName } from '../../../Common/Services/asyncUniqueName.validator';
import { Enums } from '../../../Common/enums';
import { CommonService } from '../../../Common/Services/common.service'
@Component({
    selector: 'app-account-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})

export class UserComponent implements OnInit {
    allusers: UserModel[];
    allAccountRoles: AccountRolesModel[];
    usersToShow: any = [];
    modalImage: String = "https://decoratorcdn.blob.core.windows.net/images/ScarySmiley.svg";
    modalName: String = "Blind Smiley";
    modalPrice: string = "10";
    selectedUser = null;
    editedImage: String;
    public noRecords: String;
    totalRecords: number;
    passwordButton: boolean = false;
    commonSearchText: String;
    public errormsg = "";
    validateUpload: boolean = false;
    userInEdit: FormGroup;
    mapAccountRoles = [];
    selectedFile: File = null;
    showImage: boolean = true;
    checkname: String;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    userViewOnlyDetails: any[];
    selectedFileName: string = "";
    url: String;
    formData: FormData;
    public mobileNumberMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    @ViewChild('editAccountUser') myEditUser: ElementRef;
    constructor(private accountUserService: AccountUserService, private accountRolesService: AccountRoleService, private spinnerService: SpinnerService, private toastr: ToastrService, private confirmationService: ConfirmationService, private safePipe: SafePipe, public commonService: CommonService) { }
    ngOnInit() {
        this.userInEdit = this.generateFormGroup();
        this.editedImage = "";
        this.showUpdateButton = false;
        this.showAddButton = false;
        this.spinnerService.setSpinner(true);
        this.accountUserService.getAccountRolesByAccountIdAndActive(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.accountRolesService.getAccountRolesByRoleId(Enums.roleId.role).subscribe(
                    (roleData) => {
                        if (this.commonService.getAccountID() !== Enums.accountId.One) {
                            this.allAccountRoles = data.concat(roleData);
                        }
                        else {
                            this.allAccountRoles = data;
                        }
                        this.spinnerService.setSpinner(false);
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                )
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
        this.reloadData();
        this.formData = new FormData();
    }

    reloadData() {
        this.accountUserService.getAccountUsersDataByAccountId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allusers = data;
                this.usersToShow = data;
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }

    loadUsersLazy(event) {
        this.spinnerService.setSpinner(true);
        this.accountUserService.getAccountUserDataByAccountId(this.commonService.getAccountID()).subscribe(data => {
            console.log(data);
            if (data && data.count) {
                this.totalRecords = data.count;
                data.count == "0" ? this.noRecords = "No Records Found" : this.noRecords = "";
            }
        }, err => {
            this.commonService.handleError(err);
        })
        this.spinnerService.setSpinner(true);
        let sortObject = {
            "sortField": "_id",
            "sortOrder": -1
        }
        if (event.sortField != undefined) {
            sortObject["sortField"] = event.sortField;
            sortObject["sortOrder"] = event.sortOrder;
        }
        this.accountUserService.getuserDetailByAccIdandLazyLoading(this.commonService.getAccountID(), event.first, event.rows, sortObject.sortField, sortObject.sortOrder).subscribe(
            (data) => {
                this.usersToShow = data;
                console.log(this.usersToShow);
                this.spinnerService.setSpinner(false);
            }, err => {
                this.commonService.handleError(err);
            }
        )
    }
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.validateUpload = false;
            this.selectedUser = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedUser);
            if (!this.selectedUser.type.endsWith("image/jpeg") && !this.selectedUser.type.endsWith("image/jpg") && !this.selectedUser.type.endsWith("image/png") && !this.selectedUser.type.endsWith("image/svg+xml")) {
                this.selectedFileName = "";
                this.editedImage = "";
                this.validateUpload = true;
                this.formData.delete('image');
                this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = (<FileReader>event.target).result.toString();
            }
            reader.readAsDataURL(event.target.files[0]);
        }
        this.myEditUser.nativeElement.value = "";

    }

    getSafeUrl(image) {
        return this.safePipe.transform(image, 'url');
    }

    addUser() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.userInEdit.value));
        this.accountUserService.addAccountUser(this.formData).subscribe(
            (data) => {
                this.selectedUser = null;
                this.selectedFileName = "";
                this.myEditUser.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                this.reloadData();
                this.spinnerService.setSpinner(true);
                this.toastr.success('User  Added Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.closePopup();
    }



    editUser(user) {
        this.passwordButton = true;
        this.myEditUser.nativeElement.value = "";
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.editedImage = user.UserImageUrl;
        this.userInEdit = new FormGroup({
            FirstName: new FormControl(user.FirstName, [Validators.required, this.noWhitespaceValidator]),
            LastName: new FormControl(user.LastName, [Validators.required, this.noWhitespaceValidator]),
            UserName: new FormControl(user.UserName.trim(), [Validators.required, this.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.accountUser, Enums.FieldNames.UserName, user.UserName, this.commonService.getAccountID())]),
            EmailID: new FormControl(user.EmailID, Validators.compose([
                Validators.required, this.noWhitespaceValidator,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])),
            RoleID: new FormControl(user.RoleID, [Validators.required]),
            AccountID: new FormControl(user.AccountID),
            AccountUserID: new FormControl(user.AccountUserID),
            Phone: new FormControl(user.Phone, [Validators.required]),
            UserImageUrl: new FormControl(this.editedImage),
            CreatedBy: new FormControl(user.CreatedBy),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            Status: new FormControl(user.Status),
            Deleted: new FormControl(false),
            reset_password_token: new FormControl(''),
            reset_password_expires: new FormControl('')
        });

    }

    updateUser() {
        this.spinnerService.setSpinner(true);
        this.formData.set('data', JSON.stringify(this.userInEdit.value));
        this.accountUserService.updateAccountUser(this.formData).subscribe(
            (data) => {
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.myEditUser.nativeElement.value = "";
                this.formData = new FormData();
                this.toastr.success('User Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.userInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
        this.errormsg = "";
    }
    generateUserPassword() {
        this.accountUserService.updateAccountUserPassword(this.userInEdit.value).subscribe(
            (data) => {
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.toastr.success('Generated Password Sent To Your Email Successfully!', 'Success');
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )

    }
    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.userInEdit = this.generateFormGroup();
        this.editedImage = "https://decoratorimages.blob.core.windows.net/clipartimage/image-1536995630152";
        this.showUpdateButton = false;
        this.showAddButton = true;
        this.passwordButton = false;
    }

    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.accountImage;
        this.modalName = element.name;
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }
    closePopup() {
        this.selectedUser = null;
        this.selectedFileName = "";
        this.editedImage = "";
        document.getElementById('myModal').style.display = "none";
        this.userInEdit = this.generateFormGroup();
        this.errormsg = "";
        this.passwordButton = false;
    }

    deleteUser(user) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this user?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.accountUserService.deleteAccountUser(user).subscribe(
                    (data) => {
                        this.selectedUser = null;
                        this.selectedFileName = "";
                        this.myEditUser.nativeElement.value = "";
                        this.formData = new FormData();
                        this.reloadData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('User Deleted Successfully!', 'Success');
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    clearSelectedFile() {
        this.selectedUser = null;
        this.selectedFileName = "";
        this.formData.delete('image');
        this.editedImage = "";
        this.errormsg = "";
    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    fileName = '';
    generateFormGroup() {
        return new FormGroup({
            FirstName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            LastName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            UserName: new FormControl('', [Validators.required, this.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.accountUser, Enums.FieldNames.UserName, null, this.commonService.getAccountID())]),
            Password: new FormControl(''),
            EmailID: new FormControl('', Validators.compose([
                Validators.required, this.noWhitespaceValidator,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])),

            RoleID: new FormControl('', [Validators.required]),
            Phone: new FormControl(null, [Validators.required]),
            UserImageUrl: new FormControl(),
            AccountID: new FormControl(this.commonService.getAccountID()),
            AccountUserID: new FormControl(0),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            reset_password_token: new FormControl(''),
            reset_password_expires: new FormControl('')
        });
    }
}