import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { CustomTemplateModel } from './custom-templates.model';

@Injectable()
export class CustomTemplateService {
    constructor(private httpClient: HttpClient) { }
  
    getCustomTemplateDataByAccountId(accountID): Observable<CustomTemplateModel[]> {
        return this.httpClient.get<CustomTemplateModel[]>('custom-template/get-custom-templates-by-accountID', { params: { accountID } })
    }

    addCustomTemplate(customTemplate): Observable<CustomTemplateModel> {
        return this.httpClient.post<CustomTemplateModel>('custom-template/add-custom-template', customTemplate);
    }

    deleteCustomTemplate(customTemplate): Observable<CustomTemplateModel>{  
        return this.httpClient.put<CustomTemplateModel>('custom-template/delete-custom-template', customTemplate);      
      }  

      updateCustomTemplate(customTemplate): Observable<CustomTemplateModel>{
        return this.httpClient.put<CustomTemplateModel>('custom-template/update-custom-template', customTemplate);
      }

}