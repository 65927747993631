import { Component, ChangeDetectorRef, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener } from "@angular/core";
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../../Common/Services/auth.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AccountUserService } from "../../../Common/Services/AccountUser.service";
import { UserModel } from "../users/users.model";
import { AccountUserModel } from "../../../Common/Models/AccountUser.Model";
import { ToastrService } from "ngx-toastr";
import { AuthGuard } from "../../../Common/Services/authguard.service";
import { CommonService } from '../../../Common/Services/common.service';
import { SafePipe } from "../../../Common/pipes/safePipe.pipe";
@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],
    providers: []
})
export class LayoutComponent implements OnInit, AfterViewInit {
    showSelectors: boolean;
    useMultiDatePicker: boolean;
    changePassword: FormGroup
    user: AccountUserModel;
    dateObject: NgbDateStruct;
    showSubmitButton: boolean = true;
    validateUpload: boolean = false;
    public isSuperAdmin: boolean = false;
    public isvalidPwd: boolean = true;
    selectedUser = null;
    selectedFileName: string = "";
    editedImage: String;
    public errormsg = "";
    showUpdateButton: boolean = true;
    showSpinner: boolean;
    isMinified: boolean;
    userInEdit: FormGroup;
    formData: FormData;
    dropDownObject = {
        'showProfileOptions': false,
        'showDashboardOptions': false,
        'showRankAnalysisOptions': false,
        'showOrderOptions': false,
        'showProductOptions': false,
        'showImageOptions': false,
        'showClipartOptions': false,
        'showExtraOptions': false
    }
    public mobileNumberMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    @ViewChild('profileOptions') profileOptions: ElementRef;
    @ViewChild('Products') Products: ElementRef;
    @ViewChild('editAccountUser') myEditUser: ElementRef;
    constructor(
        private spinnerService: SpinnerService,
        private cdr: ChangeDetectorRef,
        private authService: AuthService,
        private accountUserService: AccountUserService,
        private toastr: ToastrService,
        private authGaurd: AuthGuard,
        router: Router,
        private commonSerivce: CommonService,
        private safePipe: SafePipe
    ) {


        router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                let url = event.urlAfterRedirects;
            });
    }
    public isViewLoaded: boolean = false;
    public screenIds = this.authGaurd.getScreens();
    ngOnInit() {
        this.userInEdit = this.generateProfileFormGroup();
        this.showUpdateButton = false;
        this.editedImage = "";
        this.accountUserService.getAccountUserDataById(this.commonSerivce.getAccountUserID()).subscribe(
            (data) => {
                this.user = data;
                this.isViewLoaded = true;
                if (this.user.RoleID == 1) {
                    this.isSuperAdmin = true;
                }
                else {
                    this.isSuperAdmin = false;
                }
            },
        ),
            (err) => {
                console.log(err);
                //this.spinnerService.setSpinner(false);
                throw err;
            };

        this.changePassword = this.generateFormGroup();
        this.showSpinner = false;
        this.isMinified = false;
        this.spinnerService.getSpinner().subscribe((val: boolean) => {
            setTimeout(() => {
                this.showSpinner = val;
            });
        }, (err) => {

        })
        this.formData = new FormData();
    }
    ngAfterViewInit() {
        this.cdr.detectChanges();
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event) {

        if (this.profileOptions
            && !(this.profileOptions.nativeElement).contains(event.target)) {
            this.dropDownObject['showProfileOptions'] = false;
        }
    }

    toggleDropDown(propertyName: string) {
        var els = document.querySelectorAll('.settings-dropdown.collapse.in');
        if (els.length)
            els[0].classList.remove('in');
        if (propertyName != "nochild") {
            if ((els.length && els[0].id != propertyName) || els.length == 0) {
                var d = document.getElementById(propertyName);
                d.className += " in";
            }
        }
    }

    changeMinimized() {
        this.isMinified = !this.isMinified;
    }

    logout() {
        this.authService.logout();
    }

    openPopup(id) {
        document.getElementById(id).style.display = "block";
        switch (id) {
            case "userProfileModal": {
                this.getUser();
                break;
            }
            case "changePassword": {
                this.loadChangePassword()
                break;
            }
        }
    }

    loadChangePassword() {
        this.changePassword.setValue({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            accountID: this.commonSerivce.getAccountID(),
            accountUserID: this.commonSerivce.getAccountUserID()
        });

    }


    getUser() {
        this.showUpdateButton = true;
        this.myEditUser.nativeElement.value = "";
        this.editedImage = this.user.UserImageUrl;
        if (this.user != undefined) {
            this.userInEdit = new FormGroup({
                FirstName: new FormControl(this.user.FirstName.trim(), [Validators.required, this.noWhitespaceValidator]),
                LastName: new FormControl(this.user.LastName.trim(), [Validators.required, this.noWhitespaceValidator]),
                UserName: new FormControl(this.user.UserName.trim(), [Validators.required, this.noWhitespaceValidator]),
                EmailID: new FormControl(this.user.EmailID, Validators.compose([
                    Validators.required, this.noWhitespaceValidator,
                    Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
                ])),
                Password: new FormControl(this.user.Password),
                RoleID: new FormControl(this.user.RoleID, [Validators.required]),
                AccountID: new FormControl(this.user.AccountID),
                AccountUserID: new FormControl(this.user.AccountUserID),
                Phone: new FormControl(this.user.Phone, [Validators.required]),
                UserImageUrl: new FormControl(this.editedImage),
                CreatedBy: new FormControl(this.user.CreatedBy),
                ModifiedBy: new FormControl(this.commonSerivce.getAccountUserID()),
                Status: new FormControl(this.user.Status),
                Deleted: new FormControl(false),
                reset_password_token: new FormControl(''),
                reset_password_expires: new FormControl('')
            });
        }
    }

    closePopup(id) {
        document.getElementById(id).style.display = "none";
        this.changePassword = this.generateFormGroup();
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }

    checkOldPassword() {
        var oldPassword = this.changePassword.value.oldPassword;
        this.accountUserService.encryptPassword(oldPassword).subscribe(
            data => {
                var newOldPassword = data;
                if (newOldPassword != this.user.Password) {
                    this.toastr.error("Please provide your exact old password", "Alert");
                    this.isvalidPwd = false;
                }
                else {
                    this.isvalidPwd = true;
                }
            }
        );
    }

    confirmNewPassword(frm: FormGroup) {
        return frm.controls['newPassword'].value != frm.controls['oldPassword'].value ? null : { 'match': true };
    }

    generateFormGroup() {
        return new FormGroup({
            oldPassword: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            newPassword: new FormControl('', [Validators.required,
            this.noWhitespaceValidator,
            Validators.minLength(6),
            Validators.pattern(/^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,30}$/)
            ]),
            confirmPassword: new FormControl('', [
                Validators.required
            ]),
            accountID: new FormControl(this.commonSerivce.getAccountID()),
            accountUserID: new FormControl(this.commonSerivce.getAccountUserID())
        }, [this.passwordMatchValidator, this.confirmNewPassword]);
    }

    canLoadScreen(id) {
        if (this.screenIds.length > 0) {
            return this.screenIds.includes(id);
        }
        else {
            return false;
        }
    }


    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.validateUpload = false;
            this.selectedUser = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedUser);
            if (!this.selectedUser.type.endsWith("image/jpeg") && !this.selectedUser.type.endsWith("image/jpg") && !this.selectedUser.type.endsWith("image/png") && !this.selectedUser.type.endsWith("image/svg+xml")) {
                this.selectedFileName = "";
                this.editedImage = "";
                this.validateUpload = true;
                this.formData.delete('image');
                this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = (<FileReader>event.target).result.toString();
            }
            reader.readAsDataURL(event.target.files[0]);
        }
        this.myEditUser.nativeElement.value = "";

    }


    updateUser() {
        this.spinnerService.setSpinner(true);
        this.formData.set('data', JSON.stringify(this.userInEdit.value));
        this.accountUserService.updateAccountUser(this.formData).subscribe(
            (data) => {
                this.spinnerService.setSpinner(false);
                this.myEditUser.nativeElement.value = "";
                this.formData = new FormData();
                this.toastr.success('User Updated Successfully!', 'Success');
                this.accountUserService.getAccountUserDataById(this.commonSerivce.getAccountUserID()).subscribe(
                    (data) => {
                        this.user = data;
                        this.isViewLoaded = true;
                    });
                this.cdr.detectChanges();
            },
            (err) => {
                this.formData = new FormData();
                this.commonSerivce.handleError(err);
            }
        )
        this.userInEdit = this.generateProfileFormGroup();
        document.getElementById('userProfileModal').style.display = "none";
        this.errormsg = "";
    }

    getSafeUrl(image) {
        return this.safePipe.transform(image, 'url');
    }


    submitchangePassword() {
        this.formData = new FormData();
        this.spinnerService.setSpinner(true);
        var newPassword = this.changePassword.value.newPassword;
        this.accountUserService.encryptPassword(newPassword).subscribe(
            (data) => {
                console.log(data);
                this.getUser();
                this.userInEdit.value.Password = data;
                this.formData.set('data', JSON.stringify(this.userInEdit.value));
                this.accountUserService.updateAccountUser(this.formData).subscribe(
                    (data) => {
                        this.spinnerService.setSpinner(false);
                        this.formData = new FormData();
                        this.toastr.success('Changed Password Successfully!', 'Success');
                    },
                    (err) => {
                        this.formData = new FormData();
                        this.commonSerivce.handleError(err);
                    }
                )
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.formData = new FormData();
                this.commonSerivce.handleError(err);
            }
        )
        document.getElementById('changePassword').style.display = "none";
        this.changePassword = this.generateFormGroup();
    }

    passwordMatchValidator(frm: FormGroup) {
        return frm.controls['newPassword'].value === frm.controls['confirmPassword'].value ? null : { 'mismatch': true };
    }

    fileName = '';
    generateProfileFormGroup() {
        return new FormGroup({
            FirstName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            LastName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            UserName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
            Password: new FormControl(''),
            EmailID: new FormControl('', Validators.compose([
                Validators.required, this.noWhitespaceValidator,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
            ])),

            RoleID: new FormControl('', [Validators.required]),
            Phone: new FormControl(null, [Validators.required]),
            UserImageUrl: new FormControl(),
            AccountID: new FormControl(this.commonSerivce.getAccountID()),
            AccountUserID: new FormControl(0),
            CreatedBy: new FormControl(this.commonSerivce.getAccountUserID()),
            ModifiedBy: new FormControl(this.commonSerivce.getAccountUserID()),
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            reset_password_token: new FormControl(''),
            reset_password_expires: new FormControl('')
        });
    }

    get password() { return this.changePassword.get('newPassword'); }
    get confirm_password() { return this.changePassword.get('confirmPassword'); }
}