import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { TabComponent } from './tab.component';
  
  
  @Component({
    selector: 'dc-tabs',
    template: `
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs" (click)="selectTab(tab)"  [class.active]="tab.active">
          <a href="#"><strong>{{tab.title}}</strong></a>
        </li>
      </ul>
      <ng-content></ng-content>
    `,
    styles: [
      `
      .nav {
        padding-left: 0px;
        margin-bottom: 0px;
        list-style: none;
      }      
      .nav > li {
        position: relative;
        display: inline-flex;
        margin: 15px 25px;
      }
      .nav > li > a:after {
        content: '',
        position: absolute;
        bottom: -1px;
        left: 0px;
        height: 3px;
        width: 100%;
        background: #DE3950;
      }
      .nav > li > a:hover {
        color: #DE3950;
      }
      .nav> li > a:focus {
        color: red;
      }
      .nav:before {
        box-sizing: border-box;
      }
      .nav-tabs {
        border-bottom: 1px solid #ddd;
      }
      .tab-close {
        color: gray;
        text-align: right;
        cursor: pointer;
      }
      `
    ]
  })
  export class TabsComponent implements AfterContentInit {
    
    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
    
    // contentChildren are set
    ngAfterContentInit() {
      // get all active tabs
      let activeTabs = this.tabs.filter((tab)=>tab.active);
      
      // if there is no active tab set, activate the first
      if(activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      }
    }
    
    selectTab(tab: any){
      // deactivate all tabs
      this.tabs.toArray().forEach(tab => tab.active = false);
      
      // activate the tab the user has clicked on.
      tab.active = true;
    }
  }
  