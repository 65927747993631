import * as XLSX from 'ts-xlsx';
import { ToastrService } from 'ngx-toastr';
import { ProductModel } from './product.model';
import { ConfirmationService } from 'primeng/api';
import { ProductService } from './product.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { printProfileModel } from '../print-profile/print-profile.model'
import { DataService } from '../../../Common/Services/datashare.service';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductCategoryModel } from '../product-category/product-category.model';
import { CommonService } from '../../../Common/Services/common.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/Common/Services/auth.service';
import { UserDetails } from 'src/app/Common/Models/user-details.model';
import { Table } from 'primeng/table';
import { env } from 'process';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css']
})

export class ProductComponent implements OnInit {
    btn: any;
    span: any;
    modal: any;
    file: File;
    top: String;
    left: String;
    width: String;
    height: String;
    modalName: String;
    fileType = 'Xlsx';
    arrayBuffer: any;
    fileUploaded: any;
    modalImage: String;
    modalPrice: String;
    commonSearchText: String;
    public noRecords: String;
    skumainImage: String;
    totalRecords: number;
    dataToShowOnView: any;
    createdDateValue: Date;
    public errormsg: String;
    modifiedDateValue: Date;
    modalDescription: String;
    productInEdit: FormGroup;
    selectedFile: File = null;
    modalPrintMethodName: String;
    BulkSelectedFileName: String;
    showAddButton: boolean = true;
    showDataTable: boolean = false;
    allProductDetail: ProductModel[];
    showUpdateButton: boolean = true;
    allPrintProfile: printProfileModel[];
    allProductCategory: ProductCategoryModel[];
    ProductViewOnlyDetails: any = [];
    userDetails: UserDetails;
    searchProduct: string = '';
    loading: boolean;
    rowsPerPage = [5,10,15,20,50,100,200];
    @ViewChild('dt') table: Table;
    @ViewChild('myFileInput') myInputVariable: ElementRef;
    constructor(
        private productService: ProductService,
        private spinnerService: SpinnerService,
        private toastr: ToastrService,
        private datashare: DataService,
        private confirmationService: ConfirmationService,
        public commonService: CommonService,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.datashare.setProductData("");
        this.authService.userDetails.subscribe(data => {
            console.log(data);
            this.userDetails = data;
        })
        this.showAddButton = false;
        this.showUpdateButton = false;
        this.spinnerService.setSpinner(true);
        this.productInEdit = this.generateFormGroup();
        this.spinnerService.setSpinner(true);
        this.loading = true;
    }

    reloadData() {
        this.productService.getProductDetailsByAccountID(this.commonService.getAccountID(), this.searchProduct).subscribe(
            (data) => {
                this.ProductViewOnlyDetails = data;
                console.log(data);
                this.spinnerService.setSpinner(false);
            }, err => {
                this.commonService.handleError(err);
            }
        )
    }
    public onEventLog(event: string, data: any): void {
        console.log(event, data);
    }

    searchProducts() {
        this.table.reset();
        let event = {
            "first": 0,
            "rows": this.table.rows
        }
        this.loadProductsLazy(event);
    }

    NoDesignNotify(){
        document.getElementById('EmptyDesignNorification').style.display = "block";
    }
    closeNotifyPopup() {
        document.getElementById('EmptyDesignNorification').style.display = "none";
      }

    loadProductsLazy(event) {
        this.spinnerService.setSpinner(true);
        this.productService.getProductDetailsByAccountID(this.commonService.getAccountID(), this.searchProduct).subscribe(data => {
            if (data && data.count) {
                this.totalRecords = data.count;
                this.noRecords = data.count == 0 ?  "No Records Found" : "";
            }
        }, err => {
            this.commonService.handleError(err);
        })
        this.spinnerService.setSpinner(true);
        
        let sortObject = { "sortField": "_id", "sortOrder": -1 };
        if (event.sortField != undefined) {
            sortObject = { ...event.sortField, ...event.sortOrder};
            // sortObject["sortField"] = event.sortField;
            // sortObject["sortOrder"] = event.sortOrder;
        }
        this.getProductsLazy(event.first, event.rows, sortObject);
        // this.productService.getProductDetailByAccIdandLazyLoading(this.commonService.getAccountID(), this.searchProduct, event.first, event.rows, sortObject.sortField, sortObject.sortOrder).subscribe(
        //     (data) => {
        //         this.ProductViewOnlyDetails = data;
        //         this.totalRecords = data.count;
        //         this.noRecords = data.count == 0 ?  "No Records Found" : "";
        //         this.spinnerService.setSpinner(false);
        //     }, err => {
        //         this.commonService.handleError(err);
        //     }
        // )
    }

    getProductsLazy(first, rows, sortObject) {
        console.log(first);
        this.productService.getProductDetailByAccIdandLazyLoading(this.commonService.getAccountID(), this.searchProduct, first, rows, sortObject.sortField, sortObject.sortOrder).subscribe(
            (data) => {
                this.ProductViewOnlyDetails = data;
                this.loading = false;
                this.spinnerService.setSpinner(false);
            }, err => {
                this.commonService.handleError(err);
            }
        )
    }

    changeMainImage(data) {
        this.skumainImage = data.skuDefaultImage;
        this.modalName = data.name;
        this.modalPrice = data.price;
        this.modalDescription = data.description;
        // this.ImageId = productimageID;
    }

    setCanvas(product) {
        this.datashare.setProductData(product);
        this.commonService.navigate('setcanvas');
    }
    
    onProductSubmit() {
        this.spinnerService.setSpinner(true);
        let p = Object.assign({}, this.productInEdit.value);
        // p.swatchcolor = this.productColors;
        this.productService.addProduct(p).subscribe(
            (data) => {
                this.productService.getProductsByAccountID(this.commonService.getAccountID()).subscribe(
                    (data) => {
                        this.allProductDetail = data;
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Product Added Successfully!', 'Success');
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                )
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
        this.closePopup();
        this.showDataTable = false;
    }

    deleteProduct(product) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Product?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.productService.deleteProduct(product).subscribe(
                    (data) => {
                        // this.ngOnInit(); //ToDo_Ayush remove this ngOnInit
                        this.loadProductsLazy(event);
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Product Deleted Successfully!', 'Success');
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    editProduct(product) {
        // console.log("Product Details in Edit Are :", product)
        this.datashare.setProductData(product);
        // this.commonService.navigate('addproduct');
        this.commonService.navigate('editproduct');
    }

    designTemplate(product) {
        // [TODO]: Refactor for Implementing Better Tool URL Redirection

        console.log(this.userDetails);
        let __url = (environment.production ? this.userDetails.AccountToolUrl : environment.Tool_URL) + "?accountUrl=" + this.userDetails.ToolUrl + "&productId=" + product.AccountProductID + "&ssotype=" + 1 + "&SKUID=" + product.Variants[0].AccountProductSkuID + "&QTY=1&customer=0&cartId=0" ;
        window.open(__url);
    }

    updateProduct() {
        this.spinnerService.setSpinner(true);
        this.productService.updateProduct(this.productInEdit.value).subscribe(
            (data) => {
                this.ngOnInit();
                this.spinnerService.setSpinner(false);
                this.toastr.success('Product Updated Successfully!', 'Success');
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
        document.getElementById('myModal').style.display = "none";
    }

    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.showDataTable = true;
        this.showUpdateButton = false;
        this.showAddButton = true;
    }

    closePopup() {
        document.getElementById('myModal').style.display = "none";
        this.showDataTable = false;
        this.productInEdit = this.generateFormGroup();
    }

    openImagePopup(element) {
        this.modalImage = element.productImage;
        this.modalName = element.productName;
        this.myInputVariable.nativeElement.value = "";
    }

    closeImagePopup() {
        document.getElementById('ProductDetailViewingModal').style.display = "none";
        document.getElementById('bulkUploadModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
        this.errormsg = "";
        this.BulkSelectedFileName = "";
        this.myInputVariable.nativeElement.value = "";
    }

    openBulkUploadPopup() {
        this.file = undefined;
        this.myInputVariable.nativeElement.value = "";
        this.BulkSelectedFileName = "";
        document.getElementById('bulkUploadModal').style.display = "block";

    }

    incomingfile(event) {
        this.file = event.target.files[0];
        this.BulkSelectedFileName = event.target.files[0].name;
    }
    UploadExcel = function (e) {
        var filetype = this.file;
        if (filetype == undefined) {
            this.errormsg = "Please select any one file"; return;
        }
        if (this.fileType == 'Csv' && this.file.name.endsWith("xlsx")) {
            this.errormsg = "Only csv files should be uploaded"; return;

        }
        if (this.fileType == 'Xlsx' && this.file.name.endsWith("csv")) {
            this.errormsg = "Only xlsx files should be uploaded"; return;
        }
        if ((filetype != 'Xlsx' && !this.file.name.endsWith("xlsx")) && (filetype != 'Csv' && !this.file.name.endsWith("csv"))) {
            this.errormsg = "Only xlsx and csv files should be uploaded"; return;
        }
        this.errormsg = "";
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary", cellDates: true, cellFormula: true });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            var sh = workbook.Sheets.Sheet1;
            this.logs = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            var headers = [];
            var range = XLSX.utils.decode_range(worksheet["!ref"]);
            var C, R = range.s.r;
            for (C = range.s.c; C <= range.e.c; ++C) {
                var cell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
                if (cell == "" || cell == undefined) {
                    this.errormsg = "Please check the Given Sheet"; return;
                }
            }
            this.spinnerService.setSpinner(true);
            this.productService.bulkAddProducts(this.logs).subscribe(
                (data) => {
                    // this.reloadData();
                    this.loadProductsLazy(event);
                    document.getElementById('bulkUploadModal').style.display = "none";
                },
                (err) => {
                    this.commonService.handleError(err);
                }
            )
        }
        fileReader.readAsArrayBuffer(this.file);
        this.file = undefined;
    }

    onFileChanged(event) {
        if (event.target.files[0]) {
            this.selectedFile = event.target.files[0];
        }
        else {
            this.selectedFile = null;
        }
    }

    isCanvasSetup(product) {
        if (product.Variants == undefined || product.Variants[0] == undefined)
            return false;
        if (product.Variants[0].ImageUrls == undefined || product.Variants[0].ImageUrls[0] == undefined)
            return false;
        return product.Variants[0].CanvasWidth > 0 || product.Variants[0].CanvasHeight > 0
    }


    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    SyncProduct() {
        this.spinnerService.setSpinner(true);
        let SyncData = {
            "AccountID": this.commonService.getAccountID(),
            "CreatedBy": this.commonService.getAccountUserID(),
            "CreatedDate": new Date()
        }
        this.productService.syncProduct(SyncData).subscribe(
            (data) => {
                this.spinnerService.setSpinner(false);
                this.toastr.success('Sync Request Received Successfully!', 'Success');
            }, (err) => {
                this.commonService.handleError(err);
            }
        )
    }

    generateFormGroup() {
        //ToDo:Ayush Add Product category hile submitting the form
        return new FormGroup({
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            ProductID: new FormControl(null),
            Description: new FormControl(''),
            ProductImageUrl: new FormControl(),
            AccountProductID: new FormControl(0),
            MinOrderQuantity: new FormControl(0),
            MaxOrderQuantity: new FormControl(0),
            PrintProfileIDs: new FormControl(null),
            AllowCustomDesign: new FormControl(false),
            CreatedDate: new FormControl(new Date()),
            ModifiedDate: new FormControl(new Date()),
            MAP: new FormControl(null, Validators.required),
            MSRP: new FormControl(null, Validators.required),
            Code: new FormControl(null, Validators.required),
            AccountID: new FormControl(this.commonService.getAccountID()),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            Name: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
            // ProductImageUrl: new FormControl('', [Validators.required, Validators.pattern(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/)]),
        });
    }


}