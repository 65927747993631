import { Component, OnInit } from '@angular/core';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { StoreRequestsModel } from "../Approve-Register-Requests/Approve-Register.model";
import {CommonService} from '../../../../Common/Services/common.service';
@Component({
    selector: 'view-details',
    templateUrl: './Store-Profile.component.html',
    styleUrls: ['./Store-Profile.component.css']
})

export class StoreProfileComponent implements OnInit {
    storeRequestInEdit: FormGroup;
    storeSettingsInEdit: FormGroup;
    generateTool:String;
    generateAdmin:String;
    storeRequestData: any;
    storeSettingData: any;
    public mobileNumberMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public currencyMask = [/[a-zA-Z]/,/[a-zA-Z]/,/[a-zA-Z]/];
    constructor(private spinnerService: SpinnerService, private toastr: ToastrService, private settingService: SettingsService,public commonService:CommonService) { }

    ngOnInit() {
        this.storeRequestInEdit = this.generateFormGroup();
        this.storeSettingsInEdit = this.generateSettingsFormGroup();
        this.spinnerService.setSpinner(true);
        this.settingService.getStoreDetails(this.commonService.getAccountID()).subscribe(
            (data) => {
                console.log(data);
                if (data) {
                    this.storeRequestData = data[0];
                    if (this.storeRequestData) {
                        const urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
                        this.storeRequestInEdit = new FormGroup({
                            ContactPersonPhone: new FormControl(this.storeRequestData.ContactPersonPhone,[Validators.required]),
                            WebSiteUrl: new FormControl(this.storeRequestData.WebSiteUrl,[Validators.required,Validators.pattern(urlPattern)]),
                            AccountID: new FormControl(this.commonService.getAccountID()),
                            AdminUrl: new FormControl(this.storeRequestData.AdminUrl),
                            AccountLogo: new FormControl(this.storeRequestData.AccountLogo),
                            Name: new FormControl(this.storeRequestData.Name,[Validators.required, this.noWhitespaceValidator]),
                            ToolUrl: new FormControl(this.storeRequestData.ToolUrl),
                            AddressLine1: new FormControl(this.storeRequestData.AddressLine1,[Validators.required, this.noWhitespaceValidator]),
                            AddressLine2: new FormControl(this.storeRequestData.AddressLine2),
                            City: new FormControl(this.storeRequestData.City,[Validators.required, this.noWhitespaceValidator]),
                            ContactPersonFirstName: new FormControl(this.storeRequestData.ContactPersonFirstName,[Validators.required, this.noWhitespaceValidator]),
                            ContactPersonLastName: new FormControl(this.storeRequestData.ContactPersonLastName),
                            Country: new FormControl(this.storeRequestData.Country,[Validators.required, this.noWhitespaceValidator]),
                            ContactPersonEmail: new FormControl(this.storeRequestData.ContactPersonEmail,Validators.compose([
                                Validators.required,
                                Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                            ])),
                            State: new FormControl(this.storeRequestData.State,[Validators.required, this.noWhitespaceValidator]),
                            Status: new FormControl(this.storeRequestData.Status),
                            ZipCode: new FormControl(this.storeRequestData.ZipCode,[Validators.required, this.noWhitespaceValidator]),
                            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
                            CreatedBy: new FormControl(this.storeRequestData.CreatedBy),
                            FromEmailID: new FormControl(""),
                            Deleted: new FormControl(false),
                            _id:new FormControl(this.storeRequestData._id),
                            Key:new FormControl(this.storeRequestData.Key),
                            SharedSecret:new FormControl(this.storeRequestData.SharedSecret)
                        });
                    }

                }
                else {
                    this.storeRequestInEdit = this.generateFormGroup();
                }
                this.spinnerService.setSpinner(false);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )

        this.settingService.getAllSettings(this.commonService.getAccountID()).subscribe(
            (data) => {
                if (data) {
                    this.storeSettingData = data;
                    console.log(this.storeSettingData);
                    if (this.storeSettingData) {
                        this.storeSettingsInEdit = new FormGroup({
                            AccountSettingID: new FormControl(this.storeSettingData.AccountSettingID, Validators.required),
                            AccountID: new FormControl(this.storeSettingData.AccountID),
                            AccountUserID: new FormControl(this.storeSettingData.AccountUserID),
                            CreatedDate: new FormControl(this.storeSettingData.CreatedDate),
                            ModifiedDate: new FormControl(this.storeSettingData.ModifiedDate),
                            Language: new FormControl(this.storeSettingData.Language,[Validators.required, this.noWhitespaceValidator]),
                            Umo: new FormControl(this.storeSettingData.Umo,[Validators.required, this.noWhitespaceValidator]),
                            Currency: new FormControl(this.storeSettingData.Currency,[Validators.required, this.noWhitespaceValidator]),
                            CustomCSSForTool: new FormControl(this.storeSettingData.CustomCSSForTool),
                            CustomCSSForAdmin: new FormControl(this.storeSettingData.CustomCSSForAdmin),
                            AssetSettings: new FormControl(this.storeSettingData.AssetSettings),
                            FontSettings: new FormControl(this.storeSettingData.FontSettings),
                            PreLoadSettings: new FormControl(this.storeSettingData.PreLoadSettings),
                            CanvasSettings: new FormControl(this.storeSettingData.CanvasSettings),
                            PriceSettings: new FormControl(this.storeSettingData.PriceSettings),
                            Deleted: new FormControl(this.storeSettingData.Deleted),
                            _id: new FormControl(this.storeSettingData._id)
                        });
                    }

                }
                else {
                    this.storeSettingsInEdit = this.generateSettingsFormGroup();
                }
                this.spinnerService.setSpinner(false);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    
      }
      public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }

    generateToolScript(){
        this.generateTool = '<script type="text/javascript" src="https://decoratorimages.blob.core.windows.net/scripts/tool-launcher.js"></script><div id="ToolCustomization"></div>';
    }
    generateAdminScript(){
      this.generateAdmin ='<script type="text/javascript" src="https://decoratorimages.blob.core.windows.net/scripts/admin-launcher.js"></script><div id="AdminCustomization"></div>';
    }

    changeCurrency(event){
        if( event.target.value != undefined)
        {
            event.target.value =event.target.value.toUpperCase();
        }
      
    }
    updateStoreRequest() {
        console.log(this.storeRequestInEdit.value.ZipCode);
        this.spinnerService.setSpinner(true);
        this.settingService.updateStoreRequestData(this.storeRequestInEdit.value).subscribe(
            (data) => {
                this.spinnerService.setSpinner(false);
                this.toastr.success('Store Profile Updated Successfully!', 'Success');
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    updateStoreSettings(){
        this.settingService.updateStoreSettings(this.storeSettingsInEdit.value).subscribe(
            (data) => {
                this.spinnerService.setSpinner(false);
                this.toastr.success('Store Profile Updated Successfully!', 'Success');
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }


    generateFormGroup() {
        return new FormGroup({
            ContactPersonPhone: new FormControl("", Validators.required),
            WebSiteUrl: new FormControl(""),
            AccountID: new FormControl(this.commonService.getAccountID()),
            AdminUrl: new FormControl(""),
            AccountLogo: new FormControl(""),
            Name: new FormControl(""),
            ToolUrl: new FormControl(""),
            AddressLine1: new FormControl(""),
            AddressLine2: new FormControl(""),
            City: new FormControl(""),
            ContactPersonFirstName: new FormControl(""),
            ContactPersonLastName: new FormControl(""),
            Country: new FormControl(""),
            ContactPersonEmail: new FormControl(""),
            State: new FormControl(""),
            Status: new FormControl(true),
            ZipCode: new FormControl(""),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            FromEmailID: new FormControl(""),
            Deleted: new FormControl(false),
            _id: new FormControl(''),
            Key:new FormControl(""),
            SharedSecret:new FormControl("")
        });
    }


    generateSettingsFormGroup() {
        return new FormGroup({
            AccountSettingID: new FormControl(0, Validators.required),
            AccountID: new FormControl(this.commonService.getAccountID()),
            AccountUserID: new FormControl(this.commonService.getAccountUserID()),
            CreatedDate: new FormControl(Date),
            ModifiedDate: new FormControl(Date),
            Language: new FormControl(""),
            Umo: new FormControl(""),
            Currency: new FormControl(""),
            CustomCSSForTool: new FormControl(""),
            CustomCSSForAdmin: new FormControl(""),
            AssetSettings: new FormControl(Array),
            FontSettings: new FormControl(Array),
            PreLoadSettings: new FormControl(Array),
            CanvasSettings: new FormControl(Array),
            PriceSettings: new FormControl(Array),
            Deleted: new FormControl(false),
            _id: new FormControl('')
        });
    }

}

