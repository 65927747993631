import {DomainValidationService} from '../../Common/Services/domainValidator.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import {CommonService} from '../../../Common/Services/common.service';
@Injectable()

export class DomainValidate {
    constructor(private domainValidationService:DomainValidationService,private router:Router) { }
    domainValidation()
    {
        console.log(window.location.href);
        // var url = 
    this.domainValidationService.checkUniqueDomainURL(window.location.origin).subscribe(
        (data) => {
            console.log(data);
             if(data == false)
             {
                 console.log("Please Enter Valid Url");
                 this.router.navigate(["domain"],{skipLocationChange:true});
                 
             }
            // this.spinnerService.setSpinner(false);
        },
        (err) => {
        console.log(err);
        }
  
    )
 }
}