import { Component, OnInit,ViewChild, ElementRef, HostListener  } from '@angular/core';
import { DistressEffectModel } from './distress-effects.model';
import { DistressEffectService } from './distress-effects.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import {Enums} from '../../../Common/enums';
import {CommonService} from '../../../Common/Services/common.service';
import {ValidateUniqueName} from '../../../Common/Services/asyncUniqueName.validator';
@Component({
    selector: 'app-distress-effects',
    templateUrl: './distress-effects.component.html',
    styleUrls: ['./distress-effects.component.css']
})

export class DistressEffectComponent implements OnInit {
    fileName:String='';
    allDistressEffects: DistressEffectModel[];
    distressEffectsToShow: DistressEffectModel[];
    searchText:String;
    modalImage: String = "https://decoratorcdn.blob.core.windows.net/images/ScarySmiley.svg";
    modalName: String = "Blind Smiley";
    modalCode: string = "abcd";
    modalPrice: string = "10";
    public noRecords = "";
    selectedDistressEffect = null;
    distressEffectInEdit: FormGroup;
    selectedFile: File = null;
    editedImage: String;
    createdDateValue: Date;
    modifiedDateValue: Date;
    showImage: boolean = true;
    validateUpload:boolean = false;
    errormsg = "";
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    selectedFileName: string = "";
    formData: FormData;
    public counter:number = 0;
    public prevscrollY:number=0;
    public canScroll : boolean = true;
    @ViewChild('editEffectImage') myeditDistressEffect: ElementRef;
    constructor(private distressEffectService: DistressEffectService, private spinnerService: SpinnerService, private toastr: ToastrService, private confirmationService: ConfirmationService,private safePipe :SafePipe,public commonService:CommonService) { }
    ngOnInit() {
        //document.querySelector(".productfeaturescroll").removeEventListener("scroll", this.divScroll, true)
        //this.scrollY = window.scrollY;
        this.counter=0;
        this.distressEffectInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.editedImage = "";
        this.showAddButton = false;
        this.validateUpload = false;
        this.showImage = true;
        this.spinnerService.setSpinner(true);
        this.initialize();
        this.formData = new FormData();
    }

    initialize(){
        this.distressEffectService.getDistressEffectDataByAccId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allDistressEffects = data;
                this.distressEffectsToShow = data;
                this.distressEffectsToShow.length == 0 ?  this.noRecords = "No Records Found" : this.noRecords = "";
                this.spinnerService.setSpinner(false);
                console.log(data);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    // @HostListener('window:scroll', ['$event'])
    // onWindowScroll($event) {
    //     alert("Hi");
    //     if(this.scrollY < window.scrollY)
    //     {
    //         alert(++this.counter);
    //     }
    //     else{
    //         alert("Reached Bottom");
    //     }
    // }

    divScroll(event) {
        //alert(this.scrollY+" "+window.scrollY)
       // if(this.canScroll){
           event.preventDefault();
            if( event.srcElement.scrollTop < event.srcElement.offsetHeight)
            {
                //alert(++this.counter);
            }
            else{
                //alert("Reached Bottom");
            }
      //  }
    }

    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.validateUpload = false;
            this.selectedDistressEffect = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedDistressEffect);
            console.log("in Edit::", this.distressEffectInEdit.value, this.selectedDistressEffect)

            this.distressEffectInEdit.controls['DistressEffectUrl'].setValue(this.selectedDistressEffect ? this.selectedDistressEffect.name : '');
            if(!this.selectedDistressEffect.type.endsWith("image/jpeg") && !this.selectedDistressEffect.type.endsWith("image/jpg") && !this.selectedDistressEffect.type.endsWith("image/png") && !this.selectedDistressEffect.type.endsWith("image/svg+xml"))
            {
                console.log("in Edit::", this.validateUpload)
                this.selectedFileName = "";
                this.validateUpload = true;
                this.editedImage = "";
                this.distressEffectInEdit.controls['DistressEffectUrl'].setValue(''); 
                this.formData.delete('image');
                this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;  
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = (<FileReader>event.target).result.toString();
            }
           reader.readAsDataURL(event.target.files[0]);
        }
        this.myeditDistressEffect.nativeElement.value = "";

    }

    getSafeUrl(image){
        return  this.safePipe.transform(image,'url');
     }
    
    clearSelectedFile() {
        this.selectedDistressEffect = null;
        this.selectedFileName = "";
        this.myeditDistressEffect.nativeElement.value = "";
    }

    onDistressEffectSubmit() {
        this.spinnerService.setSpinner(true);
        this.distressEffectInEdit.value.Price = Number(this.distressEffectInEdit.value.Price);
        this.formData.append('data', JSON.stringify(this.distressEffectInEdit.value));
        this.distressEffectService.addDistressEffect(this.formData).subscribe(
            (data) => {
                this.selectedDistressEffect = null;
                this.selectedFileName = "";
                this.myeditDistressEffect.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                this.distressEffectInEdit = this.generateFormGroup();
                this.toastr.success('Texture Added Successfully!', 'Success');
                this.initialize();
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.myeditDistressEffect.nativeElement.value = "";
        this.closePopup();
    }


    deleteDistressEffect(distressEffect) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Texture?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.selectedDistressEffect = null;
                this.selectedFileName = "";
                this.myeditDistressEffect.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(true);
                this.distressEffectService.deleteDistressEffect(distressEffect).subscribe(
                    (data) => {
                        this.initialize();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Texture Deleted Successfully!', 'Success');
                    },
                    (err) => {
                      this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    editDistressEffect(distressEffect) {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.showImage = false;
        this.editedImage = distressEffect.DistressEffectUrl;
        this.distressEffectInEdit = new FormGroup({
            _id: new FormControl(distressEffect._id),
            DistressEffectID: new FormControl(distressEffect.DistressEffectID),
            AccountID: new FormControl(distressEffect.AccountID),
            Name: new FormControl(distressEffect.Name,[Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.distressEffect,Enums.FieldNames.Name,distressEffect.Name,this.commonService.getAccountID())]),
            Tags:new FormControl(distressEffect.Tags),
            Price: new FormControl(distressEffect.Price.toFixed(2),[Validators.required]),
            DistressEffectUrl: new FormControl(distressEffect.DistressEffectUrl,[Validators.required]),
            CreatedBy:new FormControl(distressEffect.CreatedBy),
            CreatedDate:new FormControl(distressEffect.CreatedDate),
            ModifiedBy:new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate:new FormControl(distressEffect.ModifiedDate),
            Status:new FormControl(distressEffect.Status),
            Deleted:new FormControl(distressEffect.Deleted)
        });
    }
  
    updateDistressEffect() {
        this.spinnerService.setSpinner(true);
        var distressEffect = this.distressEffectInEdit.value;
        this.distressEffectInEdit.value.Price = Number(this.distressEffectInEdit.value.Price)
        this.formData.append('data', JSON.stringify(this.distressEffectInEdit.value));
        this.distressEffectService.updateDistressEffect(this.formData).subscribe(
            (data) => {
                this.initialize();
                this.spinnerService.setSpinner(false);
                this.myeditDistressEffect.nativeElement.value = "";
                this.formData = new FormData();
                this.toastr.success('Texture Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.distressEffectInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
    }

    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = false;
        this.showAddButton = true;
        this.showImage = true;
        this.editedImage = "";
    }

    closePopup() {
        this.selectedDistressEffect = null;
        this.selectedFileName = "";
        this.errormsg = "";
        this.editedImage = "";
        this.myeditDistressEffect.nativeElement.value = "";
        document.getElementById('myModal').style.display = "none";
        this.distressEffectInEdit = this.generateFormGroup();
    }
    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.DistressEffectUrl;
        this.modalName = element.Name;
        this.modalPrice = element.Price.toFixed(2);
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    isNumberkey(event){
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    generateFormGroup() {
        this.fileName = '';
        return new FormGroup({
            DistressEffectID: new FormControl(''),
            AccountID:new FormControl(this.commonService.getAccountID()),
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.distressEffect,Enums.FieldNames.Name,null,this.commonService.getAccountID())]),
            Tags: new FormControl(''),            
            Price: new FormControl('',[Validators.required]),
            DistressEffectUrl: new FormControl(this.fileName,[Validators.required]),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            CreatedDate: new FormControl(Date),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(Date),
            Status: new FormControl(true),
            Deleted: new FormControl(false)
        });
    }
}