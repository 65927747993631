import { Injectable } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class SpinnerService{
    showSpinner = new Subject<boolean>();

    setSpinner(value: boolean){
        this.showSpinner.next(value);
    }
    getSpinner(): Observable<boolean>{
        return this.showSpinner.asObservable();
    }
}