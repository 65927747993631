import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { AccountUserModel } from "../Models/AccountUser.Model";
import { SpinnerService } from "./spinner.service";
import { Router } from "@angular/router";

@Injectable()
export class CommonService {
  constructor(
    private spinnerService: SpinnerService,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  openPopup(id) {
    document.getElementById(id).style.display = "block";
  }

  closePopup(id) {
    document.getElementById(id).style.display = "none";
  }

  getAccountID() {
    return Number(localStorage.getItem("accountId"));
  }

  getAccountUserID() {
    return Number(localStorage.getItem("accountUserId"));
  }

  getAccountUserEmail() {
    return localStorage.getItem("accountUserEmail");
  }

  handleError(err) {
    this.spinnerService.setSpinner(false);
    throw err;
  }

  checkUniqueName(
    modelName,
    fieldName,
    Name,
    fieldValue,
    accountID
  ): Observable<boolean> {
    return this.httpClient.post<boolean>("common/check-unique-name", {
      modelName: modelName,
      fieldName: fieldName,
      Name: Name,
      fieldValue: fieldValue,
      accountID,
    });
  }

  navigate(url: string) {
    let locationSkip: boolean = true;
    let urlPath: string = "";
    switch (url.toLowerCase()) {
      case "home":
        urlPath = "admin/dashboard";
        break;
      case "setcanvas":
        urlPath = "admin/product/setcanvas";
        break;
      case "addproduct":
        urlPath = "admin/product/addproduct";
        break;
      case "editproduct":
        urlPath = "admin/product/editproduct";
        break;
      case "productlist":
        urlPath = "admin/product/productlist";
        break;
      case "account-roles":
        urlPath = "admin/account-roles";
        break;
      case "addrole":
        urlPath = "admin/account-roles/addrole";
        break;
      case "view-Details":
        urlPath = "admin/settings/view-Details";
        break;
      case "store-requests":
        urlPath = "admin/settings/store-requests";
        break;
      case "images":
        urlPath = "admin/images";
        break;
      case "extras":
        urlPath = "admin/distress-effects";
        break;
      case "settings":
        urlPath = "settings/store-Profile";
        break;
      case "orderlist":
        urlPath = "admin/orderlist";
        break;
      case "userslist":
        urlPath = "admin/users";
        break;
      case "fontslist":
        urlPath = "admin/font-files";
        break;
      case "colorslist":
        urlPath = "admin/colors";
        break;
      case "clipart":
        urlPath = "admin/clipart";
        break;
      case "printprofilelist":
        urlPath = "admin/print-profiles";
        break;
      case "addprintprofile":
        urlPath = "admin/print-profiles/add-print-profile";
        break;
      default:
        urlPath = "admin/dashboard";
        break;
    }
    this.router.navigateByUrl(urlPath, { skipLocationChange: false });
  }
}
