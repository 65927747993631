import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ClipartModel } from './clipart.model';
import { ClipartService } from './clipart.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import * as XLSX from 'ts-xlsx';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import { ValidateUniqueName } from '../../../Common/Services/asyncUniqueName.validator';
import { CommonService } from '../../../Common/Services/common.service';
import { Enums } from '../../../Common/enums';

@Component({
    selector: 'app-clipart',
    templateUrl: './clipart.component.html',
    styleUrls: ['./clipart.component.css']
})
export class ClipartComponent implements OnInit {
    allClipart: ClipartModel[];
    searchText: String;
    editedImage: string;
    clipartsToShow: ClipartModel[];
    modalImage: String = "https://decoratorcdn.blob.core.windows.net/images/ScarySmiley.svg";
    modalName: String = "Blind Smiley";
    modalCode: string = "abcd";
    public noRecords = "";
    modalPrice: string = "10";
    modalCategoryName: String = "categoryName";
    BulkSelectedFileName: string = "";
    clipartInEdit: FormGroup;
    selectedFile: File = null;
    showImage: boolean = true;
    selectedClipart = null;
    fileUploaded: any;
    fileType = 'Xlsx';
    public errormsg = "";
    validateUpload: boolean = false;
    file: File;
    arrayBuffer: any;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    clipartCategoryName: String;
    selectedFileName: string = "";
    formData: FormData;
    fileName: String = '';
    @ViewChild('myFileInput') myInputVariable: ElementRef;
    @ViewChild('editclipart') myeditclipart: ElementRef;
    constructor(private clipartService: ClipartService, private spinnerService: SpinnerService, private toastr: ToastrService, private confirmationService: ConfirmationService, private fb: FormBuilder, private safePipe: SafePipe, public commonService: CommonService) { }

    ngOnInit() {
        this.clipartInEdit = this.generateFormGroup();
        this.editedImage = "";
        this.showUpdateButton = false;
        this.validateUpload = false;
        this.showAddButton = false;
        this.showImage = true;
        this.spinnerService.setSpinner(true);
        this.reloadData();
        this.formData = new FormData();
    }

    reloadData() {
        this.clipartService.getClipArtDataByAccountID(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allClipart = data;
                this.clipartsToShow = data;
                this.clipartsToShow.length == 0 ? this.noRecords = "No Records Found" : this.noRecords = "";
                this.spinnerService.setSpinner(false);
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }
    // initialize(){
    //     this.clipartInEdit = this.generateFormGroup();
    //     this.editedImage = "";
    //     this.showUpdateButton = false;
    //     this.validateUpload = false;
    //     this.showAddButton = false;
    //     this.showImage = true;
    //     this.spinnerService.setSpinner(true);
    //     this.clipartService.getClipArtDataByAccountID(this.commonService.getAccountID()).subscribe(
    //         (data) => {
    //             this.allClipart = data;
    //             this.clipartsToShow = data;
    //             this.spinnerService.setSpinner(false);
    //             console.log(data);
    //         },
    //         (err) => {
    //            this.commonService.handleError(err);
    //         }
    //     )
    //     this.spinnerService.setSpinner(true);
    //     this.formData = new FormData();
    // }

    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.validateUpload = false;
            this.selectedClipart = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.clipartInEdit.controls['ClipartUrl'].setValue(this.selectedClipart ? this.selectedClipart.name : '');
            this.formData.append('image', this.selectedClipart);
            if (!this.selectedClipart.type.endsWith("image/svg+xml")) {
                this.selectedFileName = "";
                this.validateUpload = true;
                this.editedImage = "";
                this.clipartInEdit.controls['ClipartUrl'].setValue('');
                this.formData.delete('image');
                this.errormsg = "Only .svg formats are allowed"; return;
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = String((<FileReader>event.target).result);
            }
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    getSafeUrl(image) {
        return this.safePipe.transform(image, 'url');
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode == 46 || (charCode >= 48 && charCode <= 57)) {
            return false;
        }
        return true;
    }

    clearSelectedFile() {
        this.selectedClipart = null;
        this.selectedFileName = "";
        this.formData.delete('image');
        this.errormsg = "";
        this.myeditclipart.nativeElement.value = "";
    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    onClipartSubmit() {
        this.spinnerService.setSpinner(true);
        this.clipartInEdit.value.Price = Number(this.clipartInEdit.value.Price);
        this.formData.append('data', JSON.stringify(this.clipartInEdit.value));
        this.clipartService.addClipart(this.formData).subscribe(
            (data) => {
                this.selectedClipart = null;
                this.selectedFileName = "";
                this.myeditclipart.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                this.reloadData();
                this.clipartInEdit = this.generateFormGroup();
                this.toastr.success('Clipart Added Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.closePopup();
    }

    updateClipart() {
        this.spinnerService.setSpinner(true);
        var clipart = this.clipartInEdit.value;
        this.clipartInEdit.value.Price = Number(this.clipartInEdit.value.Price);
        this.formData.append('data', JSON.stringify(this.clipartInEdit.value));
        this.clipartService.updateClipart(this.formData).subscribe(
            (data) => {
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.myeditclipart.nativeElement.value = "";
                this.formData = new FormData();
                this.toastr.success('Clipart Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.clipartInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
    }

    deleteClipart(clipart) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Clipart?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.clipartService.deleteClipart(clipart).subscribe(
                    (data) => {
                        this.selectedClipart = null;
                        this.selectedFileName = "";
                        this.myeditclipart.nativeElement.value = "";
                        this.formData = new FormData();
                        this.reloadData();
                        this.spinnerService.setSpinner(false);
                        this.clipartInEdit = this.generateFormGroup();
                        this.toastr.success('Clipart Deleted Successfully!', 'Success');
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                )
            },
            reject: () => { }
        });
    }

    editClipart(clipart) {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.showImage = false;
        this.editedImage = clipart.ClipartUrl;
        this.clipartInEdit = new FormGroup({
            ClipartID: new FormControl(clipart.ClipartID),
            AccountID: new FormControl(clipart.AccountID),
            Name: new FormControl(clipart.Name.trim(), [Validators.required, this.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.clipart, Enums.FieldNames.Name, clipart.Name, this.commonService.getAccountID())]),
            Tags: new FormControl(clipart.Tags),
            Price: new FormControl(clipart.Price, [Validators.required]),
            ClipartUrl: new FormControl(clipart.ClipartUrl, [Validators.required]),
            CreatedBy: new FormControl(clipart.CreatedBy),
            CreatedDate: new FormControl(clipart.CreatedDate),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(clipart.ModifiedDate),
            Status: new FormControl(clipart.Status),
            Deleted: new FormControl(clipart.Deleted),
            _id: new FormControl(clipart._id)
        });
    }

    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = false;
        this.showAddButton = true;
        this.showImage = true;
        this.editedImage = "";
    }

    closePopup() {
        this.selectedClipart = null;
        this.selectedFileName = "";
        this.errormsg = "";
        this.editedImage = "";
        document.getElementById('myModal').style.display = "none";
        this.clipartInEdit = this.generateFormGroup();
    }

    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.ClipartUrl;
        this.modalName = element.Name;
        this.modalPrice = element.Price;
        this.myInputVariable.nativeElement.value = "";
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        document.getElementById('bulkUploadModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
        this.errormsg = "";
        this.BulkSelectedFileName = "";
        this.myInputVariable.nativeElement.value = "";
    }
    openBulkUploadPopup() {
        this.file = undefined;
        this.myInputVariable.nativeElement.value = "";
        this.BulkSelectedFileName = "";
        document.getElementById('bulkUploadModal').style.display = "block";
    }

    incomingfile(event) {
        this.file = event.target.files[0];
        this.BulkSelectedFileName = event.target.files[0].name;
    }

    UploadExcel = function (e) {
        var filetype = this.file;
        if (filetype == undefined) {
            this.errormsg = "Please select any one file"; return;
        }
        if (this.fileType == 'Csv' && this.file.name.endsWith("xlsx")) {
            this.errormsg = "Only csv file should be uploaded"; return;

        }
        if (this.fileType == 'Xlsx' && this.file.name.endsWith("csv")) {
            this.errormsg = "Only xlsx file should be uploaded"; return;
        }
        if ((filetype != 'Xlsx' && !this.file.name.endsWith("xlsx")) && (filetype != 'Csv' && !this.file.name.endsWith("csv"))) {
            this.errormsg = "Only xlsx and csv files should be uploaded"; return;
        }
        this.errormsg = "";
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, { type: "binary", cellDates: true, cellFormula: true });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            var sh = workbook.Sheets.Sheet1;
            this.logs = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            var headers = [];
            var range = XLSX.utils.decode_range(worksheet["!ref"]);
            var C, R = range.s.r;
            for (C = range.s.c; C <= range.e.c; ++C) {
                var cell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
                if (cell == "" || cell == undefined) {
                    this.errormsg = "Please Check The Given Sheet"; return;
                }
            }
            this.spinnerService.setSpinner(true);
            this.clipartService.bulkAddCliparts(this.logs).subscribe(
                (data) => {
                    this.reloadData();
                    document.getElementById('bulkUploadModal').style.display = "none";
                }, (err) => {
                    this.commonService.handleError(err);
                }
            )
        }
        fileReader.readAsArrayBuffer(this.file);
        this.file = undefined;
    }


    onFileChanged(event) {
        if (event.target.files[0]) {
            this.selectedFile = event.target.files[0];
        }
        else {
            this.selectedFile = null;
        }
    }
    isNumberkey(event) {
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }

    generateFormGroup() {
        this.fileName = '';
        return new FormGroup({
            ClipartID: new FormControl(0),
            AccountID: new FormControl(this.commonService.getAccountID()),
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.clipart, Enums.FieldNames.Name, null, this.commonService.getAccountID())]),
            Tags: new FormControl(''),
            Price: new FormControl(null, [Validators.required]),
            ClipartUrl: new FormControl(this.fileName, [Validators.required]),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            CreatedDate: new FormControl(Date),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(Date),
            Status: new FormControl(true),
            Deleted: new FormControl(false)
        });
    }

}