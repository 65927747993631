import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ImageComponent } from './images.component';
import { ImageService } from './images.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {PipesModule} from '../../MainApp/pipes/pipes.module';
@NgModule({
  imports: [
    CommonModule,
    TableModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    PipesModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: ImageComponent
    //   },
    //   { path: '**', redirectTo: 'ImageComponent' }
    // ])
  ],
  declarations: [ImageComponent],
  providers: [ImageService,ConfirmationService]
})
export class ImagesModule { }
