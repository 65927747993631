import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { OrderInfoModel } from "./orderinfo.model";
import { OrderService } from "./orders.service";
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../../Common/Services/common.service";
import { environment } from "src/environments/environment";
import { Table } from "primeng/table";

@Component({
  selector: "app-order",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"],
})
export class OrderComponent implements OnInit {
  allOrderDetail: OrderInfoModel[];
  commonSearchText: String;
  public noRecords: String;
  orderDetailToShow: OrderInfoModel[];
  DownloadURL: string = "#";
  searchOrder: Number;
  searchOrderParam: Number = 0;
  rowsPerPage = [5, 10, 15, 20, 50, 100, 200];
  loading: boolean;
  totalRecords: number;
  @ViewChild("dt") table: Table;
  constructor(
    private orderService: OrderService,
    private spinnerService: SpinnerService,
    private toastr: ToastrService,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    this.spinnerService.setSpinner(true);
    this.loading = true;
  }

  searchOrders() {
    this.table.reset();
    let event = {
      first: 0,
      rows: this.table.rows,
    };
    this.loadOrdersLazy(event);
  }

  //   getOrders() {
  //     if (this.searchOrder == undefined) {
  //       this.searchOrderParam = 0;
  //     } else {
  //       this.searchOrderParam = this.searchOrder;
  //     }
  //     this.orderService
  //       .getOrderInfoDataByAccId(
  //         this.commonService.getAccountID(),
  //         this.searchOrderParam
  //       )
  //       .subscribe(
  //         (data) => {
  //           this.allOrderDetail = data;
  //           this.orderDetailToShow = data;
  //           data.length == 0
  //             ? (this.noRecords = "No Records Found")
  //             : (this.noRecords = "");
  //           this.spinnerService.setSpinner(false);
  //         },
  //         (err) => {
  //           this.spinnerService.setSpinner(false);
  //           this.commonService.handleError(err);
  //         }
  //       );
  //   }
  loadOrdersLazy(event) {
    this.spinnerService.setSpinner(true);
    this.getOrdersLazy(event.first, event.rows);
  }
  getOrdersLazy(first, rows) {
    this.orderService
      .getOrderInfoDataByAccId(
        this.commonService.getAccountID(),
        this.searchOrderParam,
        first,
        rows
      )
      .subscribe(
        (data) => {
          this.allOrderDetail = data;
          this.orderDetailToShow = data;
          this.loading = false;
          if (data && data.count) {
            this.totalRecords = data.count;
            this.noRecords = data.count == 0 ? "No Records Found" : "";
          }
          this.spinnerService.setSpinner(false);
        },
        (err) => {
          this.commonService.handleError(err);
        }
      );
  }
  DownloadImages(OrderID) {
    this.toastr.success("Creating your file, please wait...", "Success");
    this.spinnerService.setSpinner(true);
    this.DownloadURL =
      environment.BASE_URL +
      "order-info/get-order-images-by-OrderID?OrderID=" +
      OrderID +
      "&APIKEY=" +
      localStorage.getItem("token");
    this.spinnerService.setSpinner(false);
  }
}
