import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TableModule } from "primeng/table";
import { CalendarModule } from "primeng/calendar";
import { FormsModule } from "@angular/forms";
import { ChartModule } from "primeng/chart";
@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    FormsModule,
    ChartModule,
  ],
  // RouterModule.forChild([
  //   {
  //     path: '', component: DashboardComponent
  //   },
  //   { path: '**', redirectTo: 'DashboardComponent' }
  // ])
  // exports: [DashboardComponent],
  // entryComponents: [DashboardComponent]
})
export class DashboardModule {}
