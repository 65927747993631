import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ProductCategoryModel } from './product-category.model';

@Injectable()
export class ProductCategoryService {
    constructor(private httpClient: HttpClient) { }

    getAllProductCategories(): Observable<ProductCategoryModel[]> {
        return this.httpClient.get<ProductCategoryModel[]>('product-category/get-all-product-categories');
    }
  
    getProductCategoryDataByIds(accountID, accountUserID): Observable<ProductCategoryModel[]> {
        return this.httpClient.get<ProductCategoryModel[]>('product-category/get-product-category-by-ID/', { params: { accountID, accountUserID } })
    }
    
    getProductCategoryDataByAccountId(accountID): Observable<ProductCategoryModel[]> {
        return this.httpClient.get<ProductCategoryModel[]>('product-category/get-product-category-by-AccountID/', { params: { accountID } })
    }

    getProductCategoryCountByAccountId(accountID, catSearch): Observable<any> {
        return this.httpClient.get<any>('product-category/get-product-category-count-by-AccountID', { params: { accountID, catSearch } })
    }

    getProductCategoryDataByAccountIdAndLazyloading(accountID, catSearch, start, rows, sortField, sortOrder): Observable<any> {
        return this.httpClient.get<any>('product-category/get-product-category-by-AccountID-pages', { params: { accountID,catSearch,start,rows, sortField,sortOrder  } })
    }

    addProductCategory(productCategory): Observable<ProductCategoryModel> {
        return this.httpClient.post<ProductCategoryModel>('product-category/add-product-category', productCategory);
    }

    deleteProductCategory(productCategory): Observable<ProductCategoryModel>{  
        return this.httpClient.put<ProductCategoryModel>('product-category/delete-product-category', productCategory);      
      }  

      updateProductCategory(productCategory): Observable<ProductCategoryModel>{
        return this.httpClient.put<ProductCategoryModel>('product-category/update-product-category', productCategory);
      }
      
}