import { ErrorHandler, Injectable, Inject, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
// import { ToasterService } from './toaster.service';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptor implements ErrorHandler {
  constructor(
    private authService: AuthService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly zone: NgZone
  ) { }

  handleError(error: Error) {
    if (!error) {
      console.error("Empty error passed to error handler: " + JSON.stringify(error));
      return;
    }

    // ngx-toastr bug. See: https://github.com/scttcper/ngx-toastr/issues/179
    let toaster: ToastrService;
    try {
      toaster = this.injector.get(ToastrService);
    } catch (error) {
      console.error(error);
      return;
    }

    var errorMessage: string;

    if (error instanceof HttpErrorResponse) {
      // Handle api errors.
      errorMessage = (error.error && error.error.customError) || error.message;

      if (error.status == 401) {
        // TODO: Ensure this is called only once for multiple subsequent 401 responses.
        //       Maybe call isLoggedIn each time more than one API calls are about to be made?
        //       Like in a resolve guard, or ngoninit or something?
        // Should isLoggedIn() be called here?

        this.zone.run(_ => {
          this.authService.logout(true);
          toaster.info("Your session has expired. Please login again.", "Logged Out!", {
            onActivateTick: true
          });
        });


        return;
      }
      if (error.status == 403) {
        // TODO: Ensure this is called only once for multiple subsequent 401 responses.
        //       Maybe call isLoggedIn each time more than one API calls are about to be made?
        //       Like in a resolve guard, or ngoninit or something?
        // Should isLoggedIn() be called here?

        this.zone.run(_ => {
          this.authService.logout(true);
          toaster.info("Invalid Authtoken. Please login again.", "Logged Out!", {
            onActivateTick: true
          });
        });


        return;
      }
      if (error.status == 0) {
        errorMessage = "Couldn't reach the server.";
      }

      if (!errorMessage) {
        errorMessage = "A network error occurred.";
      }
    }
    else {
      errorMessage = error.message;
    }

    if (!errorMessage) {
      errorMessage = "An error occurred.";
    }

    console.error(error);

    try {
      toaster.error(errorMessage, 'Error', {
        onActivateTick: true,
      });
    } catch (error) {
      console.error(error);
    }
  }
}
