import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { DistressEffectModel } from './distress-effects.model';

@Injectable()
export class DistressEffectService {
    constructor(private httpClient: HttpClient) { }

    getDistressEffectDataByAccId(accountID): Observable<DistressEffectModel[]> {
        return this.httpClient.get<DistressEffectModel[]>('distress-effect/get-distress-effects-by-accountId', { params: { accountID } })
    }
  
    addDistressEffect(distressEffect): Observable<DistressEffectModel> {
        return this.httpClient.post<DistressEffectModel>(`distress-effect/add-distress-effect`, distressEffect);
    }
    
    deleteDistressEffect(distressEffect): Observable<DistressEffectModel>{  
        return this.httpClient.put<DistressEffectModel>('distress-effect/delete-distress-effect', distressEffect);      
      }  

    updateDistressEffect(distressEffect): Observable<DistressEffectModel>{
        return this.httpClient.put<DistressEffectModel>('distress-effect/update-distress-effect', distressEffect);
    }

    getDistressEffectDataByAccIdLimit(accountID,skip,limit): Observable<DistressEffectModel[]> {
        return this.httpClient.get<DistressEffectModel[]>('distress-effect/get-distress-effects-by-accountId-limit', { params: { accountID,skip,limit} })
    }
}