import { Component, OnInit } from '@angular/core';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { SettingsModel } from '../settings.model';
import { CommonService } from '../../../../Common/Services/common.service';
@Component({
    selector: 'app-preloadeditems',
    templateUrl: './preloadedItems.component.html',
    styleUrls: ['./preloadedItems.component.css']
})

export class PreloadedItemsComponent implements OnInit {
    allSettingsData: SettingsModel;
    preloadedItemsInEdit: FormGroup;
    constructor(private spinnerService: SpinnerService, private toastr: ToastrService, private settingsService: SettingsService, public commonService: CommonService) { }

    ngOnInit() {
        this.preloadedItemsInEdit = this.generateFormGroup();
        this.spinnerService.setSpinner(true);
        this.reloadData();
    }

    reloadData() {
        this.settingsService.getAllSettings(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.spinnerService.setSpinner(false);
                this.allSettingsData = data;
                this.preloadedItemsInEdit = new FormGroup({
                    Clipart: new FormControl(data.PreLoadSettings[0].Clipart),
                    Product: new FormControl(data.PreLoadSettings[0].Product),
                    webFont: new FormControl(data.PreLoadSettings[0].webFont),
                    DistressEffect: new FormControl(data.PreLoadSettings[0].DistressEffect),
                    Template: new FormControl(data.PreLoadSettings[0].Template),
                    Background: new FormControl(data.PreLoadSettings[0].Background),
                    Pattern: new FormControl(data.PreLoadSettings[0].Pattern)
                });
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }
    
    onFileImageSubmit() {
        this.allSettingsData.PreLoadSettings[0] = this.preloadedItemsInEdit.value
        this.spinnerService.setSpinner(true);
        this.settingsService.updateStoreSettings(this.allSettingsData).subscribe(
            () => {
                this.reloadData();
                this.toastr.success("Data Updated Successfully!", "Success");
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }

    generateFormGroup() {
        return new FormGroup({
            Clipart: new FormControl(0),
            Product: new FormControl(0),
            webFont: new FormControl(0),
            DistressEffect: new FormControl(0),
            Template: new FormControl(0),
            Background: new FormControl(0),
            Pattern: new FormControl(0),
        });
    }
}