import { Subject } from "rxjs/internal/Subject";
import { Directive, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";

@Directive({
    selector: 'utilities'
})
export class Utilities implements OnDestroy {
    /* Piped with takeUntil to automatically unsub from subscriptions on component destruction. */
    componentDestroyed$: Subject<void> = new Subject();

    static isValidObject(obj: Object){
        return obj !== undefined && obj !== null;
    }
    static isNullOrEmpty(value: string){
        return !this.isValidObject(value) || value.length === 0;
    }
    static noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    ngOnDestroy() {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }
}