import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import { ImageMaskModel } from './image-masks.model';
import { ImageMaskService } from './image-masks.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import {CommonService} from '../../../Common/Services/common.service';
import {ValidateUniqueName} from '../../../Common/Services/asyncUniqueName.validator';
import {Enums} from '../../../Common/enums';
@Component({
    selector: 'app-image-masks',
    templateUrl: './image-masks.component.html',
    styleUrls: ['./image-masks.component.css']
})

export class ImageMaskComponent implements OnInit {
    imageMasksToShow: ImageMaskModel[];
    searchText:String;
    modalImage: String = "https://decoratorcdn.blob.core.windows.net/images/ScarySmiley.svg";
    modalName: String = "Blind Smiley";
    modalPrice: string = "10";
    public noRecords = "";
    selectedImageMask = null;
    fileName:String = "";
    public errormsg = "";
    imageMaskInEdit: FormGroup;
    editedImage: string;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    selectedFileName: string = "";
    formData: FormData;
    @ViewChild('editPhoto') myeditImageMask: ElementRef;
    constructor(private imageMaskService: ImageMaskService, private spinnerService: SpinnerService, private toastr: ToastrService, private confirmationService: ConfirmationService,private safePipe :SafePipe,public commonService:CommonService) { }
    ngOnInit() {
        this.imageMaskInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.editedImage = "";
        this.showAddButton = false;
        this.spinnerService.setSpinner(true);
        this.reloadData();
        this.formData = new FormData();
    }

    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";
            this.selectedImageMask = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedImageMask);
            this.imageMaskInEdit.controls['ImageMaskUrl'].setValue(this.selectedImageMask ? this.selectedImageMask.name : ''); 
            if(!this.selectedImageMask.type.endsWith("image/jpeg") && !this.selectedImageMask.type.endsWith("image/jpg") && !this.selectedImageMask.type.endsWith("image/png") && !this.selectedImageMask.type.endsWith("image/svg+xml"))
            {
                this.selectedFileName = "";
                this.editedImage = "";
                this.imageMaskInEdit.controls['ImageMaskUrl'].setValue('');
                  this.formData.delete('image');
                this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = (<FileReader>event.target).result.toString();
            }
           reader.readAsDataURL(event.target.files[0]);
        
        }
        this.myeditImageMask.nativeElement.value = "";
    }

    reloadData(){
        this.imageMaskService.getImageMaskDataByAccId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.imageMasksToShow = data;
                this.spinnerService.setSpinner(false);
                this.imageMasksToShow.length == 0 ?  this.noRecords = "No Records Found" : this.noRecords = "";
                console.log(data);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    getSafeUrl(image){
       return  this.safePipe.transform(image,'url');
    }
    
    clearSelectedFile() {
        this.selectedImageMask = null;
        this.selectedFileName = "";
        this.formData.delete('image');
        this.myeditImageMask.nativeElement.value = "";
        this.errormsg = "";
    }

    onImageMaskSubmit() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.imageMaskInEdit.value));
        this.imageMaskService.addImageMask(this.formData).subscribe(
            (data) => {
                this.reloadData();
                this.selectedImageMask = null;
                this.selectedFileName = "";
                this.myeditImageMask.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                this.imageMaskInEdit = this.generateFormGroup();
                this.toastr.success('Image Mask Added Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.closePopup();
    }

    deleteImageMask(imageMask) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Image Mask?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.imageMaskService.deleteImageMask(imageMask).subscribe(
                    (data) => {
                        this.selectedImageMask = null;
                        this.selectedFileName = "";
                        this.myeditImageMask.nativeElement.value = "";
                        this.formData = new FormData();
                        this.reloadData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Image Mask Deleted Successfully!', 'Success');
                    },
                    (err) => {
                        this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    editImageMask(imageMask) {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.editedImage = imageMask.ImageMaskUrl;
        this.imageMaskInEdit = new FormGroup({
            Name: new FormControl(imageMask.Name, [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.imageMask,Enums.FieldNames.Name,imageMask.Name,this.commonService.getAccountID())]),
            Tags: new FormControl(imageMask.Tags),
            AccountID: new FormControl(imageMask.AccountID),
            ImageMaskUrl: new FormControl(this.editedImage,[Validators.required]),
            CreatedBy:new FormControl(imageMask.CreatedBy),
            ModifiedBy:new FormControl(this.commonService.getAccountUserID()),
            Status: new FormControl(imageMask.Status),
            Price: new FormControl(imageMask.Price.toFixed(2),[Validators.required]),
            ImageMaskID: new FormControl(imageMask.ImageMaskID)
        });
    }
     
    isNumberkey(event){
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }

    updateImageMask() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.imageMaskInEdit.value));
        this.imageMaskService.updateImageMask(this.formData).subscribe(
            (data) => {
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.formData = new FormData();
                this.myeditImageMask.nativeElement.value = "";
                this.toastr.success('Image Mask Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.imageMaskInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
    }

    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = false;
        this.showAddButton = true;
         this.editedImage = "";
    }

    closePopup() {
        this.selectedImageMask = null;
        this.selectedFileName = "";
        document.getElementById('myModal').style.display = "none";
        this.imageMaskInEdit = this.generateFormGroup();
        this.errormsg = "";
        this.editedImage = "";
    }

    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.ImageMaskUrl;
        this.modalName = element.Name;
        this.modalPrice = element.Price.toFixed(2);
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    generateFormGroup() {
        return new FormGroup({
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.imageMask,Enums.FieldNames.Name,null,this.commonService.getAccountID())]),
            Tags: new FormControl(''),
            ImageMaskUrl: new FormControl(this.fileName,[Validators.required]),
            ImageMaskID: new FormControl(),
            Price: new FormControl('',[Validators.required]),
            CreatedBy:new FormControl(this.commonService.getAccountUserID()),
            ModifiedBy:new FormControl(this.commonService.getAccountUserID()),
            Status:new FormControl(true),
            AccountID: new FormControl(this.commonService.getAccountID())
        });
    }
}