import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import 'fabric';
declare let fabric;
import { ProductModel } from '../product.model';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../../Common/Services/datashare.service';
import { HostListener } from '@angular/core';
import { ProductService } from '../product.service';
import { CommonService } from '../../../../Common/Services/common.service';
import { fabric } from 'fabric';

@Component({
    selector: 'app-setupCanvas',
    templateUrl: './setcanvas.component.html',
    styleUrls: ['./setcanvas.component.css']
})

export class SetUpCanvasComponent implements OnInit {
    dataFromProductTab: ProductModel;
    productDetail: any;
    tshirtmainImage: String = "https://decoratorcdn.blob.core.windows.net/images/product.png";
    public canvasTop: number = 0;
    public canvasLeft: number = 0;
    public canvasWidth: number = 10;
    public canvasHeight: number = 10;
    public top: number = 0;
    public left: number = 0;
    public width: number = 50;
    public canvasTemp: fabric.Canvas;
    public height: number = 50;
    public minTop: number = 0;
    public minLeft: number = 0;
    public minWidth: number = 0;
    public minHeight: number = 0;
    public maxTop: number = 100;
    public maxLeft: number = 100;
    public maxWidth: number = 100;
    public maxHeight: number = 100;
    public ImageId: number = 0;
    public canvas: any;
    public baseCanvasElement: HTMLCanvasElement;
    public skuId: any;
    public productName: any;
    public productCode:any;
    public productPrice: any;
    productSkuIndex: number = 0;
    productDisplayImageIndex: number = 0;

    constructor(private spinnerService: SpinnerService, private toastr: ToastrService,
        private datashare: DataService, private productService: ProductService, private elementRef: ElementRef,public commonService:CommonService) { }
    @ViewChild('ProductImage') productImage: ElementRef;
    @ViewChild('mainDiv') mainDiv: ElementRef;
    @ViewChild('BaseCanvas') baseCanvas: ElementRef;
    @ViewChild('canvasTopSlider') canvasTopSlider: ElementRef;
    @ViewChild('canvasLeftSlider') canvasLeftSlider: ElementRef;
    @ViewChild('canvasWidthSlider') canvasWidthSlider: ElementRef;
    @ViewChild('canvasHeightSlider') canvasHeightSlider: ElementRef;

    @HostListener('window:resize', ['$event'])
    sizeChange(event) {}

    ngOnInit() {
        this.dataFromProductTab = this.datashare.getProductData();
        if (!this.dataFromProductTab) {
            this.commonService.navigate('productlist');
        }
        else {
            this.tshirtmainImage = this.dataFromProductTab.Variants[0].ImageUrls[0].ImageUrl;
            this.ImageId = this.dataFromProductTab.Variants[0].ImageUrls[0].ImageId;
            this.skuId = this.dataFromProductTab.Variants[0].AccountProductSkuID;
            this.canvas = new fabric.Canvas("BaseCanvas");
            this.canvas.on('object:scaling', () => {
                this.HandleScaling();
                this.HandleScaling();
                this.canvas.renderAll();
            });
            this.canvas.on('object:moving', () => {
                this.HandleScaling();
                this.HandleScaling();
                this.canvas.renderAll();
            })
            this.baseCanvasElement = <HTMLCanvasElement>document.getElementById("BaseCanvas");
        }
        this.setCanvas(this.productDetail) //for initial load canvas

        // this.canvas.on('object:selected', () =>{
        //     debugger
        // })
    }

    HandleScaling = () => {
        var obj = this.canvas.getActiveObject();
        if (obj != null) {
            var top = obj.top;
            var left = obj.left;
            var zoom = this.canvas.getZoom();
            var pan_x = this.canvas.viewportTransform[4];
            var pan_y = this.canvas.viewportTransform[5];

            // width & height we are constraining to must be calculated by applying the inverse of the current viewportTransform
            var c_width = this.canvas.width / zoom;
            var c_height = this.canvas.height / zoom;


            var w = obj.width * obj.scaleX
            var left_adjust, right_adjust
            if (obj.originX == "center") {
                left_adjust = right_adjust = w / 2;
            } else {
                left_adjust = 0;
                right_adjust = w;
            }

            var h = obj.height * obj.scaleY;
            var top_adjust, bottom_adjust;
            if (obj.originY == "center") {
                top_adjust = bottom_adjust = h / 2;
            } else {
                top_adjust = 0;
                bottom_adjust = h;
            }

            // if you need margins set them here
            var top_margin = 0;
            var bottom_margin = 0;
            var left_margin = 0;
            var right_margin = 0;

            var top_bound = top_margin + top_adjust - pan_y;
            var bottom_bound = c_height - bottom_adjust - bottom_margin - pan_y;
            var left_bound = left_margin + left_adjust - pan_x;
            var right_bound = c_width - right_adjust - right_margin - pan_x;

            if (w > c_width) {
                obj.set("left", left_bound);
                this.canvas.getActiveObject().scaleX = c_width / obj.width;
                this.canvas.getActiveObject().scaleY = this.canvas.getActiveObject().scaleX;

            } else {
                obj.set("left", Math.min(Math.max(left, left_bound), right_bound));
            }

            if (h > c_height) {
                obj.set("top", top_bound);
                this.canvas.getActiveObject().scaleY = obj.canvas.getHeight() / obj.height;
            } else {
                obj.set("top", Math.min(Math.max(top, top_bound), bottom_bound));
            }
        }
    }
    
    setCanvas(productData) {
        this.productService.getImageCanvas(this.ImageId).subscribe(
            data => {
                if(this.canvas != undefined)
                    this.canvas._objects = [];
                if(!data) {
                    data = Object.assign({}, data)
                }
                if (this.productImage != undefined && this.canvas != undefined) {
                //   debugger
                    this.width = this.productImage.nativeElement.offsetWidth;
                    this.height = this.productImage.nativeElement.offsetHeight;
                    this.canvasTop = this.productImage.nativeElement.offsetTop;
                    this.height = this.productImage.nativeElement.offsetHeight;
                    this.baseCanvasElement.width = this.width;
                    this.baseCanvasElement.height = this.height;
                    var upperCanvas = this.elementRef.nativeElement.querySelectorAll('.upper-canvas')[0];
                    upperCanvas.style.setProperty('width', this.width + 'px');
                    upperCanvas.style.setProperty('height', this.height + 'px');
                    upperCanvas.style.setProperty('top', this.canvasTop + 'px');
                    var canvasContainer = this.elementRef.nativeElement.querySelectorAll('.canvas-container')[0];
                    canvasContainer.style.setProperty('width', this.width + 'px');
                    canvasContainer.style.setProperty('height', this.height + 'px');
                    this.canvas.setWidth(this.width);
                    this.canvas.setHeight(this.height);
                    fabric.loadSVGFromString('<svg width="100" height="100"><rect width="100" height="100" style="fill:none;stroke-width:1;stroke:rgb(0,0,0)" /></svg>', (objects, options) => {
                        var obj = fabric.util.groupSVGElements(objects, options);
                        obj.setControlsVisibility({
                            ml: true,
                            mr: true,
                            mt: true,
                            mb: true,
                        });
                        obj.set({ 'borderColor': '#000', 'cornerColor': '#000' });
                        this.canvas.add(obj);
                        obj.set("originX",'left');
                        obj.set("originY",'top');
                        obj.isClipArt = true;
                        obj.hasRotatingPoint = false;
                        this.canvas.setActiveObject(obj);
                        // obj.width = data.CanvasWidth ? data.CanvasWidth * this.canvas.width / 100 : this.canvas.width/2;
                        // obj.height= data.CanvasHeight ? data.CanvasHeight * this.canvas.height / 100 : this.canvas.height/2;
                        // obj.top = data.CanvasTop ? data.CanvasTop * this.canvas.height / 100 : this.canvas.height/4;
                        // obj.left = data.CanvasLeft ? data.CanvasLeft * this.canvas.width / 100 : this.canvas.width/4;
                        
                        if(productData==undefined || productData == null)
                            this.productDisplayImageIndex = (this.productDisplayImageIndex == undefined || this.productDisplayImageIndex == -1) ? 0 : this.productDisplayImageIndex
                        else
                            this.productDisplayImageIndex = this.ImageId
                            // debugger
                            // this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasWidth = 0;
                            // this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasHeight = 0;
                        obj.width = this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasWidth == 0 ? this.productImage.nativeElement.offsetWidth/2 : this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasWidth * this.productImage.nativeElement.offsetWidth / 100;
                        obj.height= this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasHeight == 0 ? this.productImage.nativeElement.offsetHeight/2 : this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasHeight * this.productImage.nativeElement.offsetHeight / 100;
                        var offsetTop = (this.baseCanvas.nativeElement.offsetHeight - this.productImage.nativeElement.offsetHeight ) / 2;
                        // console.log('offsetTop', offsetTop)
                        // obj.top = this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasTop;
                        obj.top = Math.abs(offsetTop + (this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasTop * this.productImage.nativeElement.offsetHeight/100));
                        var offsetLeft = (this.baseCanvas.nativeElement.offsetWidth - this.productImage.nativeElement.offsetWidth) / 2;
                        // obj.left = this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasLeft;
                        obj.left = Math.abs(offsetLeft + (this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasLeft * this.productImage.nativeElement.offsetWidth/100));
                        //this.dataFromProductTab.Variants[this.productSkuIndex].ImageUrls[this.productDisplayImageIndex].CanvasTop;
                        var activeObject = this.canvas.getActiveObject();
                        activeObject.setCoords();
                        //this.canvas.renderAll();
                        this.HandleScaling();
                       /// this.canvas.renderAll();
                        // this.HandleScaling();
                        // this.canvas.renderAll();
                       
                    });
                    setTimeout(() => {
                        this.productName = this.dataFromProductTab.Name;
                        this.productCode = this.dataFromProductTab.Code;
                        this.productPrice = this.dataFromProductTab.MSRP
                        this.canvas.renderAll();
                    },50)
                }
            }
        );
    }

    loadColorVariants(data) {
        this.productDetail = data;
        this.tshirtmainImage = data.ImageUrls[0].ImageUrl;
        this.ImageId = data.ImageUrls[0].ImageId;
        this.skuId = data.AccountProductSkuID;
        this.setCanvas(this.productDetail)
    }
    
    loadSizeVariants(imageURL, imageId, productSkuID) {
        this.tshirtmainImage = imageURL;
        this.ImageId = imageId;
        this.skuId = productSkuID;
        this.setCanvas(this.productDetail)
    }


    submitDimensions() {
        if (this.canvas != undefined && this.canvas._objects != undefined && this.canvas._objects[0] != undefined) {
            let canvasArea: ProductModel;
            this.dataFromProductTab.Variants.forEach((variant, index)=> {
                variant.ImageUrls[this.ImageId].CanvasTop = (this.canvas._objects[0].top / this.canvas.height * 100);
                variant.ImageUrls[this.ImageId].CanvasLeft = (this.canvas._objects[0].left / this.canvas.width * 100);
                variant.ImageUrls[this.ImageId].CanvasWidth = (this.canvas._objects[0].width * this.canvas._objects[0].scaleX / this.canvas.width * 100);
                variant.ImageUrls[this.ImageId].CanvasHeight = (this.canvas._objects[0].height * this.canvas._objects[0].scaleY / this.canvas.height * 100) 
            });
            canvasArea = this.dataFromProductTab;
            this.productService.updateImageCanvas(canvasArea).subscribe(
                (data) => {
                    this.toastr.success("Canvas Successfully SetUp", "Success");
                },
                (err) => {
                    this.toastr.error("Error In Setting Up the Canvas", "Error");
                    throw err;
                }
            )
        }
    }
}