import { Component, OnInit } from '@angular/core';
import { CustomTemplateModel } from './custom-templates.model';
import { CustomTemplateService } from './custom-templates.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import {CommonService} from '../../../Common/Services/common.service';

@Component({
    selector: 'app-custom-templates',
    templateUrl: './custom-templates.component.html',
    styleUrls: ['./custom-templates.component.css']
})

export class CustomTemplateComponent implements OnInit {
    allCustomTemplates: CustomTemplateModel[];
    customTemplatesToShow: CustomTemplateModel[];
    customTemplateInEdit: FormGroup;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    modalImage: String = "https://decoratorcdn.blob.core.windows.net/images/ScarySmiley.svg";
    modalName: String = "Blind Smiley";
    createdDateValue: Date;
    modifiedDateValue: Date;
    constructor(private customTemplateService: CustomTemplateService, private spinnerService: SpinnerService, private toastr: ToastrService,public commonService:CommonService) { }
    ngOnInit() {
        this.customTemplateInEdit = this.generateFormGroup();
        this.spinnerService.setSpinner(true);
        this.showUpdateButton = false;
        this.showAddButton = false;
        this.customTemplateService.getCustomTemplateDataByAccountId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allCustomTemplates = data;
                this.customTemplatesToShow = data;
                this.spinnerService.setSpinner(false);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    filterByOrderCreatedDate() {
        console.log(this.createdDateValue);
        this.customTemplatesToShow = this.allCustomTemplates.filter(element => {
            let dbDate = moment(element.createdDate);
            let selectedDate = moment(this.createdDateValue);
            return moment(dbDate).isSame(selectedDate, 'day')
        });
    }

    filterByOrderModifiedDate() {
        console.log(this.modifiedDateValue);
        this.customTemplatesToShow = this.allCustomTemplates.filter(element => {
            let dbDate = moment(element.modifiedDate);
            let selectedDate = moment(this.modifiedDateValue);
            return moment(dbDate).isSame(selectedDate, 'day')
        });
    }
    clearFilter() {
        this.customTemplatesToShow = this.allCustomTemplates;
    }

    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = false;
        this.showAddButton = true;
    }

    closePopup() {
        document.getElementById('myModal').style.display = "none";
        this.customTemplateInEdit = this.generateFormGroup();
    }

    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.templateImage;
        this.modalName = element.templateName;
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }

    generateFormGroup() {
        return new FormGroup({
            templateName: new FormControl('', Validators.required),
            templateImage: new FormControl('', Validators.required),
            templateID: new FormControl(''),
            isAdmin: new FormControl(1),
            accountID: new FormControl(this.commonService.getAccountID()),
            accountUserID: new FormControl(this.commonService.getAccountUserID())
        });
    }

    customizeProduct(){
        localStorage.setItem('productId',"1");
    }

    integrateTool() {
        var link = <HTMLLinkElement>document.querySelector('link[rel=import]');

        // Clone the <template> in the import.
        //var template = link.import.querySelector('jsa-counter');
        //var template = link.import.querySelector('decorator-tool'); 
        //var templateDiv =  document.createElement('div');
       // templateDiv.setAttribute("class","decorator-integration")
        //templateDiv.appendChild(template)
        //document.getElementsByTagName('body')[0].appendChild(templateDiv);
       // document.getElementsByTagName('body')[0].appendChild(template);
       //document.getElementById("decorator-integration").appendChild(template);
    }

    customize(){
        
    }
}