import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { of as observableOf } from 'rxjs/internal/observable/of';
import { throwError } from 'rxjs/internal/observable/throwError';
import { config } from "../config";
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { first } from 'rxjs/internal/operators/first';
import { map } from 'rxjs/internal/operators/map';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { catchError } from 'rxjs/operators';
import { UserModel } from 'src/app/Common/Models/user.model';
import { UserDetails } from '../Models/user-details.model';

/** Authentication and Authorization Service */
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private strings = config.localStorageStrings;
    private screens = [];

    private userObject = new BehaviorSubject<UserModel>(null);
    user: Observable<UserModel> = this.userObject.asObservable();

    userDetails: BehaviorSubject<UserDetails> = new BehaviorSubject<UserDetails>(new UserDetails);

    constructor(private httpClient: HttpClient, private router: Router) { }

    // #region API Calls
    _login(UserName:string, Password:string, AccountID: number): Observable<UserModel> {
        return this.httpClient.post<UserModel>('login', { UserName, Password, AccountID })
    };

    _validateUser(): Observable<UserDetails> {
        return this.httpClient.get<UserDetails>('is-logged-in')
        .pipe(
            tap((data: UserDetails) => {
                console.log(data);
                this.userDetails.next(data);
                return UserDetails;
            }),
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status > 400) {
                    // TODO: Fix err.status checking when backend implements error hanlding
                    return observableOf(null);
                }
                return throwError(err);
            })
        )
    }

    _authenticateAccount(accountURL): Observable<UserModel> {
        return this.httpClient.post<UserModel>('login/authenticateAccount', { accountURL })
    };

    _authenticateAdminAccount(accountURL, accountEmail, isInPage): Observable<UserModel> {
        return this.httpClient.post<UserModel>('login/authenticateAdminAccount', { accountURL, accountEmail, isInPage })
    };

    // #endregion API Calls

    /** Sets the user automatically upon success. */
    login(username:string, password:string, accountID: number): Observable<UserModel> {
        if (username == null || password == null) {
            return throwError(new Error('Email and password must not be empty.'));
        }

        return this._login(username, password,accountID).pipe(tap(user => {
            if (user == null) {
                return throwError(new Error('Email or Password Did Not Match'));
            }
            this.setUser(user);
        }));
    }

    logout(redirectToLogin: boolean = true) {
        this.removeUser();
        if (redirectToLogin) {
            this.router.navigateByUrl('login');
        }
    }

    setUser(user: UserModel) {
        if (user == null) {
            return;
        }

        localStorage.setItem(this.strings.token, user.token);
        localStorage.setItem(this.strings.accountId, user.accountId);
        localStorage.setItem(this.strings.accountUserId, user.accountUserId);
        localStorage.setItem(this.strings.screens, user.screens);
        if(user.screens!=""){
            this.screens = user.screens.split(',').map(x=>parseInt(x));
        }
        this.userObject.next(user);
    }

    removeUser() {
        localStorage.removeItem(this.strings.token);
        //localStorage.removeItem(this.strings.accountId);
        localStorage.removeItem(this.strings.accountUserId);
        localStorage.removeItem(this.strings.screens);
        this.userObject.next(null);
    }

    authenticateAccount(accountURL): Observable<UserModel> {
        if (accountURL == null) {
            return throwError(new Error('Account URL must not be empty.'));
        }
        return this._authenticateAccount(accountURL).pipe(tap(user => {
            if (user == null) {
                return throwError(new Error('Account URL Did Not Match'));
            }
            this.setUser(user);
        }));
    }

    authenticateAdminAccount(accountURL, accountEmail, isInPage): Observable<UserModel> {
        if (accountURL == null) {
            return throwError(new Error('Account URL must not be empty.'));
        }
        return this._authenticateAdminAccount(accountURL, accountEmail, isInPage).pipe(tap(user => {
            if (user == null) {
                return throwError(new Error('Account URL Did Not Match'));
            }
            this.setUser(user);
        }));
    }

    isLoggedIn(): Observable<boolean> {
        let token = localStorage.getItem('token');
        if (!token) return observableOf(false);

        return this.user.pipe(
            first(),
            mergeMap(user => this._validateUser()),
            map(user => !!user)
        );
    }

    isAccountAuthenticated(accountURL): Observable<boolean> {
        let token = localStorage.getItem('token');
        return this.user.pipe(
            first(),
            mergeMap(user => this.authenticateAccount(accountURL)),
            map(user => !!user)
        );
    }

    isAccountAdminAuthenticated(accountURL, accountEmail, isInPage): Observable<boolean> {
        let token = localStorage.getItem('token');
        return this.user.pipe(
            first(),
            mergeMap(user => this.authenticateAdminAccount(accountURL, accountEmail, isInPage)),
            map(user => !!user)
        );
    }

    get token(): string {
        return localStorage.getItem(this.strings.token);
    }

    screenIds(): Array<number> {
        return this.screens;
    }
}