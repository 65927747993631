import { Component, OnInit } from '@angular/core';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from '../settings.service';
import { SettingsModel } from '../settings.model';
import {CommonService} from '../../../../Common/Services/common.service';
@Component({
    selector: 'app-numberOfElements',
    templateUrl: './numberOfElements.component.html',
    styleUrls: ['./numberOfElements.component.css']
})

export class NumberOfElementsComponent implements OnInit {
    allSettingsData: SettingsModel;
    numberOfElements: FormGroup;
    constructor(private spinnerService: SpinnerService, private toastr: ToastrService, private settingsService: SettingsService,public commonService:CommonService) { }

    ngOnInit() {
        this.numberOfElements = this.generateFormGroup();
        this.spinnerService.setSpinner(true);
        this.reloadData();
    }
    reloadData(){
        this.settingsService.getAllSettings(this.commonService.getAccountID()).subscribe(
            (data) => {
                console.log(data);
                this.allSettingsData = data;
                this.spinnerService.setSpinner(false);
                this.numberOfElements = new FormGroup({
                    Total: new FormControl(data[0].canvasSettings[0].Total),
                    Clipart: new FormControl(data[0].canvasSettings[0].Clipart),
                    PlainText: new FormControl(data[0].canvasSettings[0].PlainText),
                    Image: new FormControl(data[0].canvasSettings[0].Image),
                    QRcode: new FormControl(data[0].canvasSettings[0].QRcode),
                    handrawing: new FormControl(data[0].canvasSettings[0].handrawing),
                    TextOnPath: new FormControl(data[0].canvasSettings[0].TextOnPath),
                    textfx: new FormControl(data[0].canvasSettings[0].textfx),
                    TextShape: new FormControl(data[0].canvasSettings[0].TextShape),
                    WordCloud: new FormControl(data[0].canvasSettings[0].WordCloud)
                });
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }
    onFileImageSubmit() {
        this.allSettingsData[0].canvasSettings[0] = this.numberOfElements.value;
        this.spinnerService.setSpinner(true);
        this.settingsService.updateStoreSettings(this.allSettingsData[0]).subscribe(
            (data) => {
                this.reloadData();
                this.toastr.success("Data Updated Successfully!", "Success");
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }

    generateFormGroup() {
        return new FormGroup({
            Total: new FormControl(0),
            Clipart: new FormControl(0),
            PlainText: new FormControl(0),
            Image: new FormControl(0),
            QRcode: new FormControl(0),
            handrawing: new FormControl(0),
            TextOnPath: new FormControl(0),
            textfx: new FormControl(0),
            TextShape: new FormControl(0),
            WordCloud: new FormControl(0),
        });
    }




}