import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CustomTemplateComponent } from './custom-templates.component';
import { CustomTemplateService } from './custom-templates.service';
import { ReactiveFormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule,
    FormsModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: CustomTemplateComponent
    //   },
    //   { path: '**', redirectTo: 'CustomTemplateComponent' }
    // ])
  ],
  declarations: [CustomTemplateComponent],
  providers: [CustomTemplateService]
})

export class CustomTemplatesModule { }
