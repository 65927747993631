import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BackgroundComponent } from './backgrounds.component';
import { BackgroundService } from './backgrounds.service';
import { ReactiveFormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {PipesModule} from '../../MainApp/pipes/pipes.module';
@NgModule({
  imports: [
    CommonModule,
    TableModule,
    CalendarModule,
    FormsModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    PipesModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: BackgroundComponent
    //   },
    //   { path: '**', redirectTo: 'BackgroundComponent' }
    // ])
  ],
  declarations: [BackgroundComponent],
  providers: [BackgroundService,ConfirmationService]
})

export class BackgroundsModule { }
