import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform { 
  transform(items: any[], searchText: string): any[] {
    if(!items) return [];
    if(!searchText) return items;
    searchText = searchText.toLowerCase();
    let result = items.filter( it => {
      return JSON.stringify(it.Name).toLowerCase().includes(searchText);
    });

    if(result.length === 0) {
      return [-1];
    }
    return result;
   }
}

@Pipe({
  name: 'commonfilter'
})
export class CommonFilterPipe implements PipeTransform { 
  transform(items: any[], commonSearchText: string): any[] {
    if(!items) return [];
    if(!commonSearchText) return items;
    commonSearchText = commonSearchText.toLowerCase();
    let result = items.filter( it => {
      return JSON.stringify(it).toLowerCase().includes(commonSearchText);
    });

    if(result.length === 0) {
      return [-1];
    }
    return result;
   }
}



