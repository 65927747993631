import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ProductModel } from './product.model';
import { ProductCategoryModel } from '../product-category/product-category.model';
import { ProductDetailModel } from './productDetail.model';
//import { SetCanvasModel } from './setupcanvas/setcanvas.model'
import { PrintProfileModel } from '../../MainApp/print-profile/print-profile.model';
import { productSkuImageModel } from './productsku/productskuimage.model';

@Injectable()
export class ProductService {
    constructor(private httpClient: HttpClient) { }

    // getProductDataByAccId(accountID): Observable<ProductModel[]> {
    //     return this.httpClient.get<ProductModel[]>('product/get-product-by-AccountID', { params: { accountID } })
    // }

    getProductDetailsByAccountID(accountID, prodSearch): Observable<any> {
        return this.httpClient.get<any>('product/get-product-details-by-accountID', { params: { accountID, prodSearch } })
    }

    getProductDetailByAccIdandLazyLoading(accountID, prodSearch, start, rows, sortField, sortOrder): Observable<any> {
        return this.httpClient.get<any>('product/get-product-details-by-accountID-pages', { params: { accountID, prodSearch, start, rows, sortField, sortOrder } })
    }

    addProduct(product): Observable<any> {
        // console.log(product);
        return this.httpClient.post<any>('product-service/add-product', product);
    }

    addProductDetail(productDetail): Observable<ProductModel> {
        return this.httpClient.post<ProductModel>('product-service/add-product-detail', productDetail);
    }

    deleteProduct(product): Observable<ProductModel> {
        return this.httpClient.put<ProductModel>('product/delete-product', product);
    }

    deleteSKUByID(SKU): Observable<ProductModel> {
        return this.httpClient.put<ProductModel>('product-service/delete-SKU-by-ID', SKU);
    }

    updateProduct(product): Observable<any> {
        console.log(product);
        return this.httpClient.post<any>('product/update-product', product);
    }

    syncProduct(productData): Observable<any> {
        return this.httpClient.post<any>('product/sync-product', productData);
    }

    bulkAddProducts(bulkProducts): Observable<ProductModel[]> {
        return this.httpClient.post<ProductModel[]>(`product-service/bulk-add-product`, bulkProducts);
    }

    getProductCategoryDataByAccountId(accountID): Observable<ProductCategoryModel[]> {
        return this.httpClient.get<ProductCategoryModel[]>('product-category/get-product-category-by-AccountID/', { params: { accountID } })
    }
    // getProductDetailsByAccountId(accountID): Observable<ProductModel[]> {
    //     return this.httpClient.get<ProductModel[]>('product-detail/get-product-details-by-accountID', { params: { accountID } })
    // }
    getProductsByAccountID(accountID): Observable<ProductModel[]> {
        return this.httpClient.get<ProductModel[]>('product/get-products-by-AccountID', { params: { accountID } })
    }

    getPrintProfileDataByAccountId(accountID): Observable<PrintProfileModel[]> {
        return this.httpClient.get<PrintProfileModel[]>('print-profile/get-print-profiles-by-AccountID', { params: { accountID } })
    }

    getImageCanvas(ProductSkuImageID): Observable<productSkuImageModel> {
        return this.httpClient.get<productSkuImageModel>('product-sku-image/get-productsku-image', { params: { ProductSkuImageID } });
    }

    updateImageCanvas(canvasArea): Observable<ProductModel> {
        return this.httpClient.put<ProductModel>('product/update-image-canvas', canvasArea);
    }

    getProductDetailsByAccountProductID(accountID,accountProductID, accountSKUId){
        return this.httpClient.get<ProductModel>('product/get-product-details-by-AccountProductID',
        { params: { accountID,accountProductID,accountSKUId } })
    }
}