import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setupcost',
  templateUrl: './setupcost.component.html',
  styleUrls: ['./setupcost.component.css']
})
export class SetupcostComponent implements OnInit {
  isSetupCostEnabled:boolean;data:any;

  @Input('Data')
  set _getData(data: any) {
     this.data = data;
     if(this.data){
      this.isSetupCostEnabled = this.data.Price > 0 ? true : false;
     }
  }

  @Output('OnAdd') ChangeEvent: EventEmitter<any> = new EventEmitter<string>();

  constructor(private toastr: ToastrService) { }

  ngOnInit() {
  }

  //Send the data to Parent
  // StoreSetupPrice(){
  //   this.setUpCost.Price > 0 ? this.ChangeEvent.emit(this.setUpCost) : this.toastr.warning('Enter the Setup Price!', 'Warning');
  // }

  //Check Number or not // Allow only Numbers
  isNumberkey(event){ return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57); }
}
