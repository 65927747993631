import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { SleeveTierData } from '../../print-profile.model';
import { ToastrService } from 'ngx-toastr';
import { range, Observable } from 'rxjs';


@Component({
  selector: 'app-sleeve',
  templateUrl: './sleeve.component.html',
  styleUrls: ['./sleeve.component.css']
})
export class SleeveComponent implements OnInit {
  
  showSleeve:boolean = false;
  IsEnableQuote:boolean = false;
  IsQuantityTierEnabled = false;
  Valid:boolean = true;

  countObservable;
  //Number of Sleeve
  NumberofSleev:number; 
  priceArray: any= [];
  data: any=[];

  SleeveTierData:SleeveTierData

  // @Input('Data') data: any = [];
  
  @Input('Data')
  set _getData(data: any) {
     this.data = data;
     if(this.data){
      this.showSleeve = this.data.TiersData.SleeveData[0].Price > 0 ? true : false;
     }
  }
 
  @Output('OnAdd') ChangeEvent: EventEmitter<any> = new EventEmitter<string>();

  constructor(private toastr: ToastrService) { }
   
  ngOnInit() {
  }

  //Get from Child when every change
  StoreTierData($event){
      this.SleeveTierData = $event;
      this.data.TiersData = $event;

      //Validation
    this.data.TiersData.TierData.forEach(val => {
      for (let key in val.Price) {
        if((val.Price[key] == null) && this.data.Enabled){
          this.Valid = false
        }
      }
    });
    this.data.TiersData.SleeveData.forEach(val =>{
      if((val.Price == null) && this.data.Enabled){
        this.Valid = false
      }
    })
    let data = {
      Valid : this.Valid,
      Data : this.data
    }
    this.ChangeEvent.emit(data);
    this.Valid = true;
    }


  //Check Number or not // Allow only Numbers
  isNumberkey(event){ return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57); }

}
