import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../../Common/Services/common.service';
import { PrintProfileModel } from '../print-profile.model';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Utilities as UTIL } from 'src/app/Common/Utilities/utilities';
import { Enums } from '../../../../Common/enums';
import { ValidateUniqueName } from '../../../../Common/Services/asyncUniqueName.validator';
import { SpinnerService } from '../../../../Common/Services/spinner.service';
import { ClipartModel } from '../../clipart/clipart.model';
import { ClipartService } from '../../clipart/clipart.service';
import { FontFileService } from '../../font-files/font-files.service';
import { FontFileModel } from '../../font-files/font-files.model';
import { ColorService } from '../../colors/colors.service';
import { ColorModel } from '../../colors/colors.model';
import { PrintProfileService } from '../print-profile.service';
import { ToastrService } from 'ngx-toastr';
import { SafePipe } from '../../../../Common/pipes/safePipe.pipe';
import { DataService } from '../../../../Common/Services/datashare.service';
import { PrintProfileModule } from '../print-profile.module';
import { Key } from 'protractor';
import { Routes, RouterModule, Router } from '@angular/router';

@Component({
  selector: 'add-print-profile',
  templateUrl: './add-print-profile.component.html',
  styleUrls: ['./add-print-profile.component.css']
})
export class AddPrintProfileComponent extends UTIL implements OnInit {
  private profile: PrintProfileModel;
  public generalSettingsForm: FormGroup;
  public selectPriceForm: FormGroup;
  public assetSettingsForm: FormGroup;
  private TierForm: FormGroup;
  tierArray: FormArray;
  arrayItems: {
    From: number;
    To: number;
    Price: number
  }[];
  DynamicPriceRuleColor:{
    Color: number;
    Price: number;
  }[];
  objectKeys = Object.keys;
  DynamicPriceRuleDTG:{
    TShirts: number;
    Rhombus: number;
    MobileCase: number;
    CircleSticker: number;
    A8: number;
    A7: number;
    A6: number;
    A5: number;
    A4: number;
    A3: number;
    A2: number;
    A1: number;
  };
  DynamicPriceRuleDesign:{
    From: number;
    To: number;
    Price: number
  }[];
  PriceValid = {
    SetupCost : true,
    ClipArtPrice : true,
    FontPrice : true,
    PrintableColorPrice :true,
    ArtworkPrice : true,
    SleevePrice : true,
    ImageUploadPrice :true,
    PriceforLetter : true,
  }
  private priceSettingsForm: FormGroup;
  priceSettings;
  public features: Array<string> = ["Clipart Library","Backgrounds","Patterns Library","Upload Design"];
  public imageFormats: Array<string> = ["svg","jpeg","jpg","png"];
  public orderFileFormats: Array<string> = ["pdf","png","svg"];
  public selectedFeatures: Array<string> = [];
  public selectedImageFormats: Array<string> = [];
  public selectedOrderFileFormats: Array<string> = []; 
  public isMinimumQuantityEnabled: boolean;
  public noClipartRecords: string;
  public cliparts: ClipartModel[];
  public selectedCliparts: Array<Number> = [];
  public noFontFileRecords: string;
  public fontFiles: FontFileModel[];
  public selectedFontFiles: Array<Number> = [];
  public noColorRecords: string;
  public colors: ColorModel[];
  public selectedColors: Array<Number> = [];
  public clipArtTree: any;
  public FontTree:any;
  public colorTree:any;

  //For Price Selection
  private showSetupCost: boolean = false;
  private showClipArtPrice: boolean = false;
  private showFontPrice: boolean = false;
  private showPrintableColorPrice: boolean = false;
  private showDynamicDecorationPrice: boolean = false;
  private showArtworkPrice: boolean = false;
  private showSleevePrice: boolean = false;
  private showImageUploadPrice: boolean = false;
  private showPriceforLetter: boolean = false;

  //forTier
  private TierCount =0;
  private TierBox: any = [];

  private isSetupCostEnabled: boolean;
  private isClipartPriceEnabled: boolean;
  private isFontPriceEnabled: boolean;
  private isColorPriceEnabled: boolean;
  private isPrintableColorEnabled: boolean;
  private isDynamicDecorationEnabled: boolean;
  private isArtworkEnabled: boolean;
  private isSleeveEnabled: boolean;
  private isImageUploadEnabled: boolean;
  private isPriceforLetterEnabled: boolean;
  private formData: FormData;
  public errormsg = "";
  private validateUpload: boolean = false;
  private selectedImage = null;
  public editedImage: string;
  public DataFromPrintProfileTab: PrintProfileModel;


  constructor(
    private spinnerService: SpinnerService,
    public commonService: CommonService,
    private _fb: FormBuilder,
    private clipartService: ClipartService,
    private fontFileService: FontFileService,
    private colorService: ColorService,
    private printProfileService: PrintProfileService,
    private toastr: ToastrService,
    private safePipe :SafePipe,
    private datashare: DataService,
    private router: Router
    ) {
    super();
  }
  
  ngOnInit(){
    
    let profile;
    profile = this.DataFromPrintProfileTab = this.datashare.getPrintProfileData();
    
    this.spinnerService.setSpinner(true);
    this.getClipartsData();
    this.getFontsData();
    this.getColorsData();
    
    if (profile != undefined && profile._id) {
      this.editedImage = profile.GeneralSettings.ProfileImageUrl;
      this.selectedFeatures = profile.GeneralSettings.Features;
      this.selectedImageFormats = profile.GeneralSettings.ImageFormats;
      this.selectedOrderFileFormats = profile.GeneralSettings.OrderFileFormats;
      
      this.generalSettingsForm = new FormGroup({
        ProfileName: new FormControl(profile.GeneralSettings.ProfileName, [Validators.required, UTIL.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.printProfile, Enums.FieldNames.Name, null, this.commonService.getAccountID())]),
        ProfileDescription: new FormControl(profile.GeneralSettings.ProfileDescription),
        ProfileImageUrl: new FormControl(profile.GeneralSettings.ProfileImageUrl,[Validators.required]),
        Features: this.convertSelectedItemsToControlArray(this.features, this.selectedFeatures),
        ImageFormats: this.convertSelectedItemsToControlArray(this.imageFormats, this.selectedImageFormats),
        IsColorPickerEnabled: new FormControl(profile.GeneralSettings.IsColorPickerEnabled),
        AllowMinimumQuantity: new FormControl(profile.GeneralSettings.AllowMinimumQuantity),
        MinimumOrderQuantity: new FormControl(profile.GeneralSettings.MinimumOrderQuantity),
        OrderFileFormats: this.convertSelectedItemsToControlArray(this.orderFileFormats, this.selectedOrderFileFormats),
        GenerateSingleFilePerSide: new FormControl(profile.GeneralSettings.GenerateSingleFilePerSide),
        IsTextStrokeEnabled: new FormControl(profile.GeneralSettings.IsTextStrokeEnabled),
        IsMutilineTextEnabled: new FormControl(profile.GeneralSettings.IsMutilineTextEnabled),
        IsGraphicFontEnabled: new FormControl(profile.GeneralSettings.IsGraphicFontEnabled),
        UploadBackground: new FormControl(profile.GeneralSettings.UploadBackground),
        ReplaceClipArt: new FormControl(profile.GeneralSettings.ReplaceClipArt),
        ReplaceShape: new FormControl(profile.GeneralSettings.ReplaceShape)        
      });

      this.selectedCliparts = profile.AssetSettings.Cliparts;
      this.selectedFontFiles = profile.AssetSettings.Fonts;
      this.selectedColors = profile.AssetSettings.Colors;

      this.assetSettingsForm = new FormGroup({
        Cliparts: this._fb.array(this.selectedCliparts),
        Fonts: this._fb.array(this.selectedFontFiles),
        Colors: this._fb.array(this.selectedColors)
      });

      this.isSetupCostEnabled = profile.PriceSettings.SetupCostEnabled;
      this.isClipartPriceEnabled = profile.PriceSettings.ClipartPriceEnabled;
      this.isFontPriceEnabled = profile.PriceSettings.FontPriceEnabled;
      this.isColorPriceEnabled = profile.PriceSettings.ColorPriceEnabled;
      
      this.priceSettingsForm = new FormGroup({
        SetupCostEnabled: new FormControl(profile.PriceSettings.SetupCost.Enabled),
        SetupCostPrice: new FormControl(profile.PriceSettings.SetupCostPrice),
        ApplyForEachSide: new FormControl(profile.PriceSettings.ApplyForEachSide),
        ApplyOnSingleOrder: new FormControl(profile.PriceSettings.ApplyOnSingleOrder),
        ClipartPriceEnabled: new FormControl(profile.PriceSettings.ClipartPriceEnabled),
        ClipartPrice: new FormControl(profile.PriceSettings.ClipartPrice),
        FontPriceEnabled: new FormControl(profile.PriceSettings.FontPriceEnabled),
        FontPrice: new FormControl(profile.PriceSettings.FontPrice),
      });

      this.priceSettings = {
        SetupCost: profile.PriceSettings.SetupCost,
        ClipArtCost: profile.PriceSettings.ClipArtCost,
        FontPrice: profile.PriceSettings.FontPrice,
        ColorPrice: profile.PriceSettings.ColorPrice,
        ArtWorkPrice: profile.PriceSettings.ArtWorkPrice,
        ImagePrice: profile.PriceSettings.ImagePrice,
        PricePerLetterPrice: profile.PriceSettings.PricePerLetterPrice,
        SleevePrice: profile.PriceSettings.SleevePrice,
      }

      }
    else{
      this.editedImage = "";
      this.generalSettingsForm = this.generateGeneralSettingsFormGroup();
      this.assetSettingsForm = this.generateAssetSettingsFormGroup();
      this.priceSettingsForm = this.generatePriceSettingsFormGroup();
      
      this.priceSettings = {
        SetupCost: {
          Enabled: true,
          Price: 0,
          ApplyForEachSide: '',
          ApplyOnSingleOrder: ''
        },
        ClipArtCost: {
          Enabled: false,
          TiersData : {
            TierData:[{
              From: 1,
              To:2,
              Price: {0:0}
            }],
            IsQuoteEnable: false
          }
        },
        FontPrice: {
          Enabled: false,
          TiersData : {
            TierData:[{
              From: 1,
              To:2,
              Price: {0:0}
            }],
            IsQuoteEnable: false
          }
        },
        ColorPrice: {
          Enabled: false,
          TiersData : {
            TierData:[{
              From: 1,
              To:2,
              Price: {0:0}
            }],
            IsQuoteEnable: false
          }
        },
        ArtWorkPrice: {
          Enabled: false,
          Price: 0
        },
        ImagePrice: {
          Enabled: false,
          Price : 0,
          TiersData : {
            TierData:[{
              From: 1,
              To:2,
              Price: {0:0}
            }],
            IsQuoteEnable: false
          }
        },
        PricePerLetterPrice: {
          Enabled: false,
          Price : 0,
          TiersData : {
            TierData:[{
              From: 1,
              To:2,
              Price: {0:0}
            }],
            IsQuoteEnable: false
          }
        },
        SleevePrice: {
          Enabled: false,
          TiersData : {
            TierData:[{
              From: 1,
              To:2,
              Price: {0:0}
            }],
            SleeveData:[{
              Number: 1,
              Price: 0
            }],
            IsQuoteEnable: false
          }
        },
      }
    }

   

      this.printProfileService.getPrintProfileDataByAccountId(this.commonService.getAccountID()).subscribe(
          (data) => {
            this.spinnerService.setSpinner(false);
          },
          (err) => {
            this.commonService.handleError(err);
          }
        )
    this.selectPriceForm = new FormGroup({
      showSetupCost : new FormControl(true),
      showClipArtPrice : new FormControl(true),
      showFontPrice : new FormControl(true),
      showPrintableColorPrice : new FormControl(true),
      showDynamicDecorationPrice : new FormControl(true),
      showArtworkPrice : new FormControl(true),
      showSleevePrice : new FormControl(true),
      showImageUploadPrice : new FormControl(true),
      showPriceforLetter : new FormControl(true),
    });
    this.formData = new FormData();
  }
  

CreateArrayforPerColor(){
  this.DynamicPriceRuleColor= [];
  let count = this.priceSettingsForm.value.PerColorsNumber;
  for(let i = 1; i <= count; i++)
  {
    this.DynamicPriceRuleColor.push({Color:i,Price:0})
  }
}

  onFileSelected(event) {
    if (event.target.files[0] != undefined) {
        this.errormsg = "";
        this.validateUpload = false;
        this.selectedImage = event.target.files[0];
        this.generalSettingsForm.controls['ProfileImageUrl'].setValue(this.selectedImage ? this.selectedImage.name : '');
        this.formData.append('image', this.selectedImage);
        
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
            this.editedImage = String((<FileReader>event.target).result);
        }
       reader.readAsDataURL(event.target.files[0]);
    }
  }

  getSafeUrl(image){   
    return  this.safePipe.transform(image,'url'); 
  }

  trimValue(formControl) { formControl.setValue(formControl.value.trim()); }

  isNumberkey(event){ return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57); }
  
  //#region General Settings

  generateGeneralSettingsFormGroup(): FormGroup {
    return new FormGroup({
      ProfileName: new FormControl('', [Validators.required, UTIL.noWhitespaceValidator], [ValidateUniqueName.createUniqueValidator(this.commonService, Enums.FileNames.color, Enums.FieldNames.Name, null, this.commonService.getAccountID())]),
      ProfileDescription: new FormControl(''),
      ProfileImageUrl: new FormControl('',[Validators.required]),
      Features: this.setFeatureControls(),
      ImageFormats: this.setImageControls(),
      IsColorPickerEnabled: new FormControl(false),
      AllowMinimumQuantity: new FormControl(false),
      MinimumOrderQuantity: new FormControl(Number),
      OrderFileFormats: this.setOrderFileFormatsControls(),
      GenerateSingleFilePerSide: new FormControl(false),
      IsTextStrokeEnabled: new FormControl(false),
      IsMutilineTextEnabled: new FormControl(false),
      IsGraphicFontEnabled: new FormControl(false),
      UploadBackground: new FormControl(false),
      ReplaceClipArt: new FormControl(''),
      ReplaceShape: new FormControl('')
    });
  }  

  convertSelectedItemsToControlArray(allItems, selectedItems){
      if(allItems && selectedItems){
        return this._fb.array(allItems.map(x => {
          if(selectedItems.includes(x))
            return this._fb.control(true);
          else
            return this._fb.control(false);
        }));
      }
  }

  // FeaturesSettigs
  setFeatureControls(){
    const features = this.features.map(x => {
      return this._fb.control(false);
    });
    return this._fb.array(features);
  }

  getSelectedFeatures(){    
  }


  get featuresArray(){
    return <FormArray>this.generalSettingsForm.get("Features"); 
  }

  // ImageSettings
  setImageControls(){
    const imageFormats = this.imageFormats.map(x => {
      return this._fb.control(false);
    });
    return this._fb.array(imageFormats);
  }
  
  getSelectedImageFormats(){

  }
  
  get imageFormatsArray(){
    return <FormArray>this.generalSettingsForm.get("ImageFormats");
  }

  // ColorSettings
  onColorPickerSettingChanged(event?){
    this.generalSettingsForm.patchValue({IsColorPickerEnabled: event.target.checked});
  }

  //OrderSettings
  onAllowMinimumQuantitySettingChanged(event?){
    this.generalSettingsForm.patchValue({AllowMinimumQuantity: event.target.checked});
    this.isMinimumQuantityEnabled = this.generalSettingsForm.get("AllowMinimumQuantity").value;
  }

  setOrderFileFormatsControls(){
    const orderFileFormats = this.orderFileFormats.map(x => {
      return this._fb.control(false);
    });
      return this._fb.array(orderFileFormats);
  }
    
  getSelectedOrderFileFormats(){
    this.orderFileFormatsArray.controls.forEach((x, i) => {
      if(x.value){
        this.selectedOrderFileFormats.push(this.orderFileFormats[i]);
      }
    });
  }
    
  get orderFileFormatsArray(){
    return <FormArray>this.generalSettingsForm.get("OrderFileFormats");
  }

  onGenerateSingleFilePerSideSettingChanged(event?){
    this.generalSettingsForm.patchValue({GenerateSingleFilePerSide: event.target.checked});
  }

  // TextSettings
  onTextStrokeSettingChanged(event){
    this.generalSettingsForm.patchValue({IsTextStrokeEnabled: event.target.checked});
  }

  onMutilineTextSettingChanged(event){
    this.generalSettingsForm.patchValue({IsMutilineTextEnabled: event.target.checked});
  }

  onGraphicFontSettingChanged(event){
    this.generalSettingsForm.patchValue({IsGraphicFontEnabled: event.target.checked});
  }

  // MiscSettings
  onUploadBackgroundSettingChanged(event){
    this.generalSettingsForm.patchValue({UploadBackground: event.target.checked});
  }

  //#endregion General Settings

  //#region Asset Settings
  
  generateAssetSettingsFormGroup(): FormGroup {
    return new FormGroup({
      Cliparts: new FormArray([]),
      Fonts: new FormArray([]),
      Colors: new FormArray([])
    });
  }

  get clipartArray(){
    return <FormArray>this.assetSettingsForm.get("Cliparts");
  }
  get fontFileArray(){
    return <FormArray>this.assetSettingsForm.get("Fonts");
  }
  get colorArray(){
    return <FormArray>this.assetSettingsForm.get("Colors");
  }

  getClipartsData() {
    this.clipartService.getClipArtDataByAccountID(this.commonService.getAccountID()).subscribe(
      (data) => {
        this.cliparts = data;
        
        this.clipArtTree = this.datashare.setTree(this.cliparts);

        this.clipArtTree.forEach((val,Index)=>{
          val.children.forEach((data,NestIndex)=>{
             data.IsActive = this.selectedCliparts.includes(data.ClipartID) ? true : false;
          })
        })

        this.noClipartRecords = this.cliparts.length == 0 ? "No Records Found" : this.cliparts.length.toString();
        this.cliparts.forEach(() => this.clipartArray.push(new FormControl()));

        // this.cliparts.forEach((clipart) => {
        //   const groupIndex = this.groupedCliparts.findIndex((item: { tag: string, groupedCliparts: ClipartModel[] }) => {
        //     return item.tag === clipart.Tags;
        //   });
        //   groupIndex !== -1 ? this.groupedCliparts[groupIndex].groupedCliparts.push(clipart)
        //     : this.groupedCliparts.push({ tag: clipart.Tags, groupedCliparts: [clipart] });
        // });
        this.spinnerService.setSpinner(false);
      },
      (err) => {
        this.commonService.handleError(err);
      }
    )
  }

  getFontsData() {
    this.fontFileService.getFontFileDataByAccountId(this.commonService.getAccountID()).subscribe(
      (data) => {
        this.fontFiles = data;
        this.FontTree = this.datashare.setTree(this.fontFiles);

        this.FontTree.forEach((val,Index)=>{
          val.children.forEach((data,NestIndex)=>{
             data.IsActive = this.selectedFontFiles.includes(data.FontID) ? true : false;
          })
        })
        this.noFontFileRecords = this.fontFiles.length == 0 ? "No Records Found" : this.fontFiles.length.toString();
        this.fontFiles.forEach(() => this.fontFileArray.push(new FormControl()));

        this.spinnerService.setSpinner(false);
      },
      (err) => {
        this.commonService.handleError(err);
      }
    )
  }

  getColorsData() {
    this.colorService.getColorsDataByAccId(this.commonService.getAccountID()).subscribe(
      (data) => {
        this.colors = data;

        this.colorTree = this.datashare.setTree(this.colors);

        this.colorTree.forEach((val,Index)=>{
          val.children.forEach((data,NestIndex)=>{
             data.IsActive = this.selectedColors.includes(data.colorID) ? true : false;
          })
        })


        this.noColorRecords = this.colors.length == 0 ? "No Records Found" : this.colors.length.toString();
        this.colors.forEach(() => this.colorArray.push(new FormControl()));
        if(this.selectedCliparts.length > 0)
          this.convertSelectedItemsToControlArray(this.cliparts, this.selectedCliparts);
        this.spinnerService.setSpinner(false);
      },
      (err) => {
        this.commonService.handleError(err);
      }
    )
  }

  onClipartsChecked(event, clipartID, child) {
    if (this.selectedCliparts.includes(clipartID)) {
      this.selectedCliparts = this.selectedCliparts.filter(x => x !== clipartID);
    } else {
      this.selectedCliparts.push(clipartID);
    }
  }
  
  onFontsChecked(event, fontID) {
    if (this.selectedFontFiles.includes(fontID)) {
      this.selectedFontFiles = this.selectedFontFiles.filter(x => x !== fontID);
    } else {
      this.selectedFontFiles.push(fontID);
    }
  }

  onColorsChecked(event, colorID) {
    if (this.selectedColors.includes(colorID)) {
      this.selectedColors = this.selectedColors.filter(x => x !== colorID);
    } else {
      this.selectedColors.push(colorID);
    }
  }
  
  //#endregion Asset Settings

  //#region Price Settings

  generatePriceSettingsFormGroup(): FormGroup {
    return new FormGroup({
      SetupCostEnabled: new FormControl(false),
      SetupCostPrice: new FormControl(''),
      ApplyForEachSide: new FormControl(''),
      ApplyOnSingleOrder: new FormControl(''),
      ClipartPriceEnabled: new FormControl(false),
      ClipartPrice: new FormControl(''),
      FontPriceEnabled: new FormControl(false),
      FontPrice: new FormControl('')
    });
  }

  onSetupCostChanged(event?){
    this.priceSettingsForm.patchValue({SetupCostEnabled: event.target.checked});
    this.isSetupCostEnabled = this.priceSettingsForm.get("SetupCostEnabled").value;
    if(!this.isSetupCostEnabled){
      this.priceSettingsForm.patchValue({SetupCostPrice: ''});
    }
  }

  onClipartPriceChanged(event?){
    this.priceSettingsForm.patchValue({ClipartPriceEnabled: event.target.checked});    
    this.isClipartPriceEnabled = this.priceSettingsForm.get("ClipartPriceEnabled").value;
    if(!this.isClipartPriceEnabled){
      this.priceSettingsForm.patchValue({ClipartPrice: ''});
    }
  }

  onFontPriceChanged(event?){
    this.priceSettingsForm.patchValue({FontPriceEnabled: event.target.checked});    
    this.isFontPriceEnabled = this.priceSettingsForm.get("FontPriceEnabled").value;
    if(!this.isFontPriceEnabled){
      this.priceSettingsForm.patchValue({FontPrice: ''});
    }
  }

  onColorPriceChanged(event?){
    this.priceSettingsForm.patchValue({ColorPriceEnabled: event.target.checked});    
    this.isColorPriceEnabled = this.priceSettingsForm.get("ColorPriceEnabled").value;
    if(!this.isColorPriceEnabled){
      this.priceSettingsForm.patchValue({ColorPrice: ''});
    }
  }
   
  //Open Popup
  openPopUp(){
    document.getElementById('myModal').style.display = "block";
  }

  //Close Popup
  closePopup() {
    document.getElementById('myModal').style.display = "none";
  }

  //ArtPrice Store Final Data
  StoreArtwork($event){
    this.priceSettings.ArtWorkPrice = $event;
  }

  //Sleeve Store Final Data
  StoreSleeve($event){
    this.PriceValid.SleevePrice = $event.Valid;
    this.priceSettings.SleevePrice= $event.Data;
  }


  //Image Uploaded Final Data
  StoreImageUploaded($event){
    this.PriceValid.ImageUploadPrice = $event.Valid;
    this.priceSettings.ImagePrice= $event.Data;
  }

  //Image Uploaded Final Data
  StoreClipArtPrice($event){
    this.PriceValid.ClipArtPrice = $event.Valid;
    this.priceSettings.ClipArtCost= $event.Data;
  } 

  //Printable Colo
  StorePrintableColor($event){
    this.PriceValid.PrintableColorPrice = $event.Valid;
    this.priceSettings.ColorPrice= $event.Data;
  }
  
  //Price Per Letter
  StorePricePerLetter($event){
    this.PriceValid.PriceforLetter = $event.Valid;
    this.priceSettings.PricePerLetterPrice= $event.Data;
  }

  //Font Price
  StoreFontPrice($event){
    this.PriceValid.FontPrice = $event.Valid;
    this.priceSettings.FontPrice= $event.Data;
  }

  //Setup Prices
  StoreSetupPrice($event){
    this.priceSettings.SetupCost = $event;
    $event.Price = parseInt($event.Price);
  }

  //Update Price Details
   UpdatePriceSetup(){
     let PriceDetails = {
      PrintProfileID : 48,
      PriceSettings: this.priceSettings
     }
     this.printProfileService.StorePriceSetup(PriceDetails).subscribe(
      (data) => {
        this.toastr.success('Profile Saved Successfully!', 'Success');
      },
      (err) => {
        this.formData = new FormData();
        this.commonService.handleError(err);
      }
    )
   }

  //Feature Submit
 
  submitFeatures(){
    
  }

  //#endregion Price Settings

  onPrintProfileSubmit() {
    this.spinnerService.setSpinner(true);

    //Validation Check for PriceSettings

    let IsValid = (Object.values(this.PriceValid)).includes(false)

    if((this.priceSettings.ArtWorkPrice.Enabled && (this.priceSettings.ArtWorkPrice.Price == null || this.priceSettings.ArtWorkPrice.Price == 0)) || 
    (this.priceSettings.ArtWorkPrice.Enabled && (this.priceSettings.PricePerLetterPrice.Price == null || this.priceSettings.PricePerLetterPrice.Price == 0)) ||
    (this.priceSettings.ImagePrice.Enabled && (this.priceSettings.ImagePrice.Price == null || this.priceSettings.ImagePrice.Price == 0)) || 
    this.priceSettings.SetupCost.Price == null || IsValid){
        this.toastr.warning('Enter the Price Details', 'Warning');
        this.spinnerService.setSpinner(false);
        return false;
      }

    

    //Image Format
    this.imageFormatsArray.controls.forEach((x, i) => {
      if(x.value){
       if(!this.selectedImageFormats.includes(this.imageFormats[i])){
         this.selectedImageFormats.push(this.imageFormats[i]);
       }       
      }
      else{
        this.selectedImageFormats = this.selectedImageFormats.filter(res =>res != this.imageFormats[i])
      }
    });

    //Clipart Feature
    this.featuresArray.controls.forEach((x, i) => {
      if(x.value){
       if(!this.selectedFeatures.includes(this.features[i])){
         this.selectedFeatures.push(this.features[i]);
       }       
      }
      else{
        this.selectedFeatures = this.selectedFeatures.filter(res =>res != this.features[i])
      }
    });

    
    //Order format
    this.orderFileFormatsArray.controls.forEach((x, i) => {
      if(x.value){
       if(!this.selectedOrderFileFormats.includes(this.orderFileFormats[i])){
         this.selectedOrderFileFormats.push(this.orderFileFormats[i]);
       }       
      }
      else{
        this.selectedOrderFileFormats = this.selectedOrderFileFormats.filter(res =>res != this.orderFileFormats[i])
      }
    });

    

    let g = this.generalSettingsForm.value;
    let a = this.assetSettingsForm.value;
    let p = this.priceSettingsForm.value;

    this.profile = {
    PrintProfileID: this.DataFromPrintProfileTab ? this.DataFromPrintProfileTab.PrintProfileID : 0,
    AccountID: this.commonService.getAccountID(),
    GeneralSettings: {
      ProfileName: g.ProfileName,
      ProfileDescription: g.ProfileDescription,
      ProfileImageUrl: g.ProfileImageUrl,
      Features: this.selectedFeatures,
      ImageFormats: this.selectedImageFormats,
      IsColorPickerEnabled: g.IsColorPickerEnabled,
      AllowMinimumQuantity: g.AllowMinimumQuantity,
      MinimumOrderQuantity: g.MinimumOrderQuantity,
      OrderFileFormats: this.selectedOrderFileFormats,
      GenerateSingleFilePerSide: g.GenerateSingleFilePerSide,
      IsTextStrokeEnabled: g.IsTextStrokeEnabled,
      IsMutilineTextEnabled: g.IsMutilineTextEnabled,
      IsGraphicFontEnabled: g.IsGraphicFontEnabled,
      UploadBackground: g.UploadBackground,
      ReplaceClipArt: g.ReplaceClipArt,
      ReplaceShape: g.ReplaceShape
    },
    AssetSettings: {
      Cliparts: this.selectedCliparts,
      Fonts: this.selectedFontFiles,
      Colors: this.selectedColors      
    },
    PriceSettings: this.priceSettings,
    CreatedBy: this.commonService.getAccountUserID(),
    CreatedDate: new Date(),
    ModifiedBy: this.commonService.getAccountUserID(),
    ModifiedDate: new Date(),
    Status: true,
    Deleted: false
    };
  
    this.formData.append("data", JSON.stringify(this.profile));
    if(this.profile.PrintProfileID == 0){
      this.printProfileService.addPrintProfile(this.formData).subscribe(
        (data) => {
          this.formData = new FormData();
          this.spinnerService.setSpinner(false);
          this.toastr.success('Profile Saved Successfully!', 'Success');
          this.router.navigate(["print-profiles"], { skipLocationChange: true });
        },
        (err) => {
          this.formData = new FormData();
          this.commonService.handleError(err);
        }
      )
    }
    else{
      this.printProfileService.updatePrintProfile(this.formData).subscribe(
        (data) => {
          this.formData = new FormData();
          this.spinnerService.setSpinner(false);
          this.toastr.success('Profile Updated Successfully!', 'Success');
          this.router.navigate(["print-profiles"], { skipLocationChange: true });
        },
        (err) => {
          this.formData = new FormData();
          this.commonService.handleError(err);
        }
      )
    }
  }
}
