import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ImageModel } from './images.model';
import { ImageService } from './images.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import {Enums} from '../../../Common/enums';
import {ValidateUniqueName} from '../../../Common/Services/asyncUniqueName.validator';
import {CommonService} from '../../../Common/Services/common.service';
@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.css']
})

export class ImageComponent implements OnInit {
    allImages: ImageModel[];
    imagesToShow: ImageModel[];
    searchText: String;
    modalImage: String = "";
    modalName: String = "";
    modalPrice: string = "";
    fileName:String = "";
    selectedImage = null;
    public errormsg = "";
    public noRecords = "";
    imageInEdit: FormGroup;
    selectedFile: File = null;
    editedImage: String;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    selectedFileName: string = "";
    formData: FormData;
    modalTags: String = "";
    @ViewChild('editPhoto') myeditImage: ElementRef;
    constructor(private imageService: ImageService, private spinnerService: SpinnerService, private toastr: ToastrService, private confirmationService: ConfirmationService, private safePipe: SafePipe, public commonService :CommonService) { }
    ngOnInit() {
        this.imageInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.editedImage = "";
        this.showAddButton = false;
        this.spinnerService.setSpinner(true);
        this.reloadData();
        this.spinnerService.setSpinner(true);
        this.formData = new FormData();
    }

    reloadData(){
        this.imageService.getImagesDataByAccId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allImages = data;
                this.imagesToShow = data;
                this.imagesToShow.length == 0 ?  this.noRecords = "No Records Found" : this.noRecords = "";
                this.spinnerService.setSpinner(false);
                console.log(data);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }
    isNumberkey(event){
        return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)
    }

    onFileSelected(event) {
        if (event.target.files[0] != undefined) {
            this.errormsg = "";  
            this.selectedImage = event.target.files[0];
            this.selectedFileName = event.target.files[0].name;
            this.formData.append('image', this.selectedImage);
            this.imageInEdit.controls['ImageUrl'].setValue(this.selectedImage ? this.selectedImage.name : ''); 
            if (!this.selectedImage.type.endsWith("image/jpeg") && !this.selectedImage.type.endsWith("image/jpg") && !this.selectedImage.type.endsWith("image/png") && !this.selectedImage.type.endsWith("image/svg+xml")) {
                this.selectedFileName = "";
                this.editedImage = "";
                this.imageInEdit.controls['ImageUrl'].setValue(''); 
                this.formData.delete('image');
                this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;
            }
            var reader = new FileReader();
            reader.onload = (event: ProgressEvent) => {
                this.editedImage = (<FileReader>event.target).result.toString();
            }
            reader.readAsDataURL(event.target.files[0]);

        }
        this.myeditImage.nativeElement.value = "";
    }

    getSafeUrl(image){
        return  this.safePipe.transform(image,'url');
     }

    clearSelectedFile() {
        this.selectedImage = null;
        this.selectedFileName = "";
        this.formData.delete('image');
        this.myeditImage.nativeElement.value = "";
        this.errormsg = "";
        this.editedImage = "";
    }

    onImageSubmit() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.imageInEdit.value));
        this.imageService.addImage(this.formData).subscribe(
            (data) => {
                this.reloadData();
                this.selectedImage = null;
                this.selectedFileName = "";
                this.myeditImage.nativeElement.value = "";
                this.formData = new FormData();
                this.spinnerService.setSpinner(false);
                this.imageInEdit = this.generateFormGroup();
                this.toastr.success('Image Added Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
               this.commonService.handleError(err);
            }
        )
        this.closePopup();
    }

    editImage(image) {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.editedImage = image.ImageUrl;
        this.imageInEdit = new FormGroup({
            Name: new FormControl(image.Name, [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.image,Enums.FieldNames.Name,image.Name,this.commonService.getAccountID())]),
            Tags: new FormControl(image.Tags),
            AccountID: new FormControl(image.AccountID),
            ImageUrl: new FormControl(this.editedImage,[Validators.required]),
            ModifiedBy:new FormControl(this.commonService.getAccountUserID()),
            Status: new FormControl(image.Status),
            Price:new FormControl(image.Price.toFixed(2),Validators.required),
            ImageID: new FormControl(image.ImageID)
        });
    }

    deleteImage(image) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this Image?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.imageService.deleteImage(image).subscribe(
                    (data) => {
                        this.reloadData();
                        this.selectedImage = null;
                        this.selectedFileName = "";
                        this.myeditImage.nativeElement.value = "";
                        this.formData = new FormData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('Image Deleted Successfully!', 'Success');
                    },
                    (err) => {
                       this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    updateImage() {
        this.spinnerService.setSpinner(true);
        this.formData.append('data', JSON.stringify(this.imageInEdit.value));
        this.imageService.updateImage(this.formData).subscribe(
            (data) => {
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.formData = new FormData();
                this.myeditImage.nativeElement.value = "";
                this.toastr.success('Image Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.imageInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    openPopup() {
        document.getElementById('myModal').style.display = "block";
        this.showUpdateButton = false;
        this.showAddButton = true;
        this.editedImage = "";
    }

    closePopup() {
        this.selectedImage = null;
        this.selectedFileName = "";
        document.getElementById('myModal').style.display = "none";
        this.imageInEdit = this.generateFormGroup();
        this.errormsg = "";
        this.editedImage = "";
    }

    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.ImageUrl;
        this.modalName = element.Name;
        this.modalPrice = element.Price.toFixed(2);
        this.modalTags = element.Tags;
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }
    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    
    generateFormGroup() {
        return new FormGroup({
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.image,Enums.FieldNames.Name,null,this.commonService.getAccountID())]),
            Tags: new FormControl(''),
            ImageUrl: new FormControl(this.fileName,[Validators.required]),
            ImageID: new FormControl(''),
            Price:new FormControl(null,[Validators.required]),
            CreatedBy:new FormControl(this.commonService.getAccountUserID()),
            ModifiedBy:new FormControl(this.commonService.getAccountUserID()),
            Status: new FormControl(true),
            AccountID: new FormControl(this.commonService.getAccountID())
        });
    }
}