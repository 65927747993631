import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PrintProfileComponent } from './print-profile.component';
import { PrintProfileService } from './print-profile.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { PipesModule } from '../../MainApp/pipes/pipes.module';
import { AddPrintProfileComponent } from './add-print-profile/add-print-profile.component';
import { SharedModule } from '../../Shared/shared.module';
import { ClipartService } from '../clipart/clipart.service';
import { FontFileService } from '../font-files/font-files.service';
import { ColorService } from '../colors/colors.service';
import { DataService } from 'src/app/Common/Services/datashare.service';
import { ArtworkComponent } from './add-print-profile/artwork/artwork.component';
import { SleeveComponent } from './add-print-profile/sleeve/sleeve.component';
import { QuantityTierComponent } from './add-print-profile/quantity-tier/quantity-tier.component';
import { UploadedImageComponent } from './add-print-profile/uploaded-image/uploaded-image.component';
import { ClipartComponent } from './add-print-profile/clipart/clipart.component';
import { PrintableColorComponent } from './add-print-profile/printable-color/printable-color.component';
import { PriceperletterComponent } from './add-print-profile/priceperletter/priceperletter.component';
import { FontpriceComponent } from './add-print-profile/fontprice/fontprice.component';
import { SetupcostComponent } from './add-print-profile/setupcost/setupcost.component';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    PipesModule,
    CheckboxModule,
    SharedModule,
    TabViewModule
    // RouterModule.forChild([
    //   {
    //     path: '', component: PrintProfileComponent
    //   },
    //   {
    //     path: 'add-print-profile', component: AddPrintProfileComponent
    //   },
    //   { path: '**', redirectTo: 'properties' }
    // ])
  ],
  declarations: [PrintProfileComponent, AddPrintProfileComponent, ArtworkComponent, SleeveComponent, QuantityTierComponent, UploadedImageComponent, ClipartComponent, PrintableColorComponent, PriceperletterComponent, FontpriceComponent, SetupcostComponent],
  providers: [PrintProfileService, ConfirmationService, ClipartService, FontFileService, ColorService, DataService]
})
export class PrintProfileModule { }
