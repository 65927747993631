import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProductCategoryComponent } from './product-category.component'
import { ProductCategoryService } from './product-category.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PipesModule } from '../../MainApp/pipes/pipes.module';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    TableModule,
    ConfirmDialogModule,
    PipesModule,
    // RouterModule.forChild([
    //   {
    //     path: '', component: ProductCategoryComponent
    //   },

    //   {path: '**', redirectTo: 'properties'}
    // ])
  ],
  declarations: [ProductCategoryComponent],
  providers: [ProductCategoryService, ConfirmationService]
})
export class ProductCategoryModule { }
