import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { printProfileModel } from './print-profile.model';
import { PrintProfileService } from './print-profile.service';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { SafePipe } from '../../../Common/pipes/safePipe.pipe';
import { Enums } from '../../../Common/enums';
import { ValidateUniqueName } from '../../../Common/Services/asyncUniqueName.validator';
import { CommonService } from '../../../Common/Services/common.service';
import { DataService } from '../../../Common/Services/datashare.service';
import { PrintProfileModel } from './print-profile.model';
@Component({
    selector: 'app-print-profiles',
    templateUrl: './print-profile.component.html',
    styleUrls: ['./print-profile.component.css']
})
export class PrintProfileComponent implements OnInit {
    allPrintProfiles: PrintProfileModel[];
    printProfilesToShow: PrintProfileModel[];
    modalImage: String = "";
    modalName: String = "Blind Smiley";
    modalPrice: string = "10";
    selectedPrintprofile = null;
    public noRecords = "";
    public errormsg = "";
    fileName:String = "";
    editedImage: String;
    validateUpload: boolean = false;
    printProfileInEdit: FormGroup;
    selectedFile: File = null;
    showImage: boolean = true;
    checkname: String;
    createdDateValue: Date;
    modifiedDateValue: Date;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    selectedFileName: string = "";
    searchText: String;
    formData: FormData;
    selectedCategories: string[] = [];

    fileFormats = [{ name: "JPEG", checked: false }, { name: "SVG", checked: false }, { name: "PNG", checked: false }, { name: 'TIFF', checked: false }, { name: 'AI', checked: false }, { name: 'PSD', checked: false }]

    @ViewChild('editPrintProfileImage') myEditPrintProfile: ElementRef;
    constructor(
        private printProfileService: PrintProfileService,
        private spinnerService: SpinnerService,
        private toastr: ToastrService,
        private confirmationService: ConfirmationService,
        private fb: FormBuilder,
        private safePipe: SafePipe,
        public commonService: CommonService,
        private datashare: DataService) { }

    ngOnInit() {
        this.spinnerService.setSpinner(true);
        this.datashare.setPrintProfileData("");
        this.printProfileInEdit = this.generateFormGroup();
        this.showUpdateButton = false;
        this.showAddButton = false;
        this.editedImage = "";
        this.showImage = true;
        this.formData = new FormData();
        this.reloadData();
    }
     
    reloadData()
    {
        this.printProfileService.getPrintProfileDataByAccountId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allPrintProfiles = data;
                this.printProfilesToShow = data;
                this.printProfilesToShow.length == 0 ?  this.noRecords = "No Records Found" : this.noRecords = "";
                this.spinnerService.setSpinner(false);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    // onFileSelected(event) {
    //     if (event.target.files[0] != undefined) {
    //         this.errormsg = "";
    //         this.validateUpload = false;
    //         this.selectedPrintprofile = event.target.files[0];
    //         this.selectedFileName = event.target.files[0].name;
    //         this.formData.append('image', this.selectedPrintprofile);
    //         this.printProfileInEdit.controls['PrintProfileUrl'].setValue(this.selectedPrintprofile ? this.selectedPrintprofile.name : ''); 
    //         if (!this.selectedPrintprofile.type.endsWith("image/jpeg") && !this.selectedPrintprofile.type.endsWith("image/jpg") && !this.selectedPrintprofile.type.endsWith("image/png") && !this.selectedPrintprofile.type.endsWith("image/svg+xml")) {
    //             this.selectedFileName = "";
    //             this.validateUpload = true;
    //             this.printProfileInEdit.controls['PrintProfileUrl'].setValue('');
    //             this.editedImage = "";
    //             this.errormsg = "Only jpeg,png,jpg and svg formats are allowed"; return;
    //         }
    //         var reader = new FileReader();
    //         reader.onload = (event: ProgressEvent) => {
    //             this.editedImage = (<FileReader>event.target).result.toString();
    //         }
    //         reader.readAsDataURL(event.target.files[0]);
    //     }
    // }
   
    // getSafeUrl(image) {
    //     return this.safePipe.transform(image, 'url');
    // }
    // trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    clearSelectedFile() {
        this.selectedPrintprofile = null;
        this.selectedFileName = "";
        this.formData.delete('image');
        this.myEditPrintProfile.nativeElement.value = "";
        this.errormsg = "";
    }
    // onPrintProfileSubmit() { 
    //     this.printProfileInEdit.value.FileFormat = this.selectedCategories;
    //     this.formData.append('data', JSON.stringify(this.printProfileInEdit.value));
    //     this.printProfileService.addPrintProfile(this.formData).subscribe(
    //         (data) => {
    //             this.selectedPrintprofile = null;
    //             this.selectedFileName = "";
    //             this.myEditPrintProfile.nativeElement.value = "";
    //             this.formData = new FormData();
    //             this.spinnerService.setSpinner(false);
    //             this.printProfileInEdit = this.generateFormGroup();
    //             this.toastr.success('PrintProfile Added Successfully!', 'Success');
    //             this.reloadData();
    //         },
    //         (err) => {
    //             this.formData = new FormData();
    //             this.commonService.handleError(err);
    //         }
    //     )
    //     this.selectedCategories.length = 0;
    //     this.closePopup();
    // }

    deletePrintProfile(printProfile) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this PrintProfile?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.spinnerService.setSpinner(true);
                this.printProfileService.deletePrintProfile(printProfile).subscribe(
                    (data) => {
                        this.selectedPrintprofile = null;
                        this.selectedFileName = "";
                        // this.myEditPrintProfile.nativeElement.value = "";
                        this.formData = new FormData();
                       this.reloadData();
                        this.spinnerService.setSpinner(false);
                        this.toastr.success('PrintProfile Deleted Successfully!', 'Success');
                    },
                    (err) => {
                       this.commonService.handleError(err);
                    }
                )
            },
            reject: () => {

            }
        });
    }

    editPrintProfile(printProfile) {
        this.datashare.setPrintProfileData(printProfile);
        this.commonService.navigate('addprintprofile');
    }

    OldeditPrintProfile(printProfile) {
        document.getElementById('myModal').style.display = "block";
        this.editedImage = printProfile.PrintProfileUrl;
        this.showUpdateButton = true;
        this.showAddButton = false;
        this.showImage = false;
        this.selectedCategories = printProfile.FileFormat;
        this.printProfileInEdit = new FormGroup({
            PrintProfileID: new FormControl(printProfile.PrintProfileID),
            AccountID: new FormControl(printProfile.AccountID),
            Name: new FormControl(printProfile.Name, [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.printProfile,Enums.FieldNames.Name,printProfile.Name,this.commonService.getAccountID())]),
            FileFormat: this.fb.array([]),
            PrintProfileUrl: new FormControl(printProfile.PrintProfileUrl,[Validators.required]),
            Status: new FormControl(printProfile.Status),
            Deleted: new FormControl(printProfile.Deleted),
            CreatedBy: new FormControl(printProfile.CreatedBy),
            CreatedDate: new FormControl(printProfile.CreatedDate),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            _id: new FormControl(printProfile._id)
        });
    }

    updatePrintProfile() {
        this.spinnerService.setSpinner(true);
        this.printProfileInEdit.value.FileFormat = this.selectedCategories;
        this.formData.append('data', JSON.stringify(this.printProfileInEdit.value));
        this.printProfileService.updatePrintProfile(this.formData).subscribe(
            (data) => {
               this.reloadData();
                this.spinnerService.setSpinner(false);
                this.formData = new FormData();
                this.myEditPrintProfile.nativeElement.value = "";
                this.toastr.success('PrintProfile Updated Successfully!', 'Success');
            },
            (err) => {
                this.formData = new FormData();
                this.commonService.handleError(err);
            }
        )
        this.printProfileInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "none";
    }

    openPopup() {
        this.selectedCategories.length = 0;
        this.showUpdateButton = false;
        this.showAddButton = true;
        this.showImage = true;
        this.editedImage = "";
        this.printProfileInEdit = this.generateFormGroup();
        document.getElementById('myModal').style.display = "block";
    }

    closePopup() {
        this.selectedCategories.length = 0;
        this.selectedPrintprofile = null;
        let fileFormatFormArray = <FormArray>this.printProfileInEdit.controls.fileFormats;
        // let index = fileFormatFormArray.value.indexOf(name);  
        //     if (index !== -1) {
        //         fileFormatFormArray.value.splice(index, 1);
        //     }
        this.selectedFileName = "";
        this.fileFormats.forEach(element => {
            element.checked = false;
        });
        this.printProfileInEdit = this.generateFormGroup();
        this.errormsg = "";
        this.editedImage = "";
        document.getElementById('myModal').style.display = "none";
    }

    openImagePopup(element) {
        document.getElementById('imageViewingModal').style.display = "block";
        this.modalImage = element.GeneralSettings.ProfileImageUrl;
        this.modalName = element.GeneralSettings.ProfileName;
    }

    closeImagePopup() {
        document.getElementById('imageViewingModal').style.display = "none";
        this.modalImage = "";
        this.modalName = "";
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }
    onChange(name: string, isChecked: boolean) {
        let fileFormatFormArray = <FormArray>this.printProfileInEdit.controls.fileFormats;

        if (isChecked) {
            fileFormatFormArray.value.push(name);
        } else {
            let index = fileFormatFormArray.value.indexOf(name);
            if (index !== -1) {
                fileFormatFormArray.value.splice(index, 1);
            }
        }
    }
    generateFormGroup() {
        return new FormGroup({
            PrintProfileID: new FormControl(''),
            AccountID: new FormControl(this.commonService.getAccountID()),
            Name: new FormControl('', [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.printProfile,Enums.FieldNames.Name,null,this.commonService.getAccountID())]),
            FileFormat: this.fb.array([]),
            PrintProfileUrl: new FormControl(this.fileName,[Validators.required]),
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            CreatedDate: new FormControl(new Date()),
            ModifiedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedDate: new FormControl(new Date()),
        });
    }

}