import { Component, OnInit } from '@angular/core';
import { SpinnerService } from "../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {
    accountId: number;
    showDataTable: boolean = false;
    createdDateValue: Date;
    modifiedDateValue: Date;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    constructor(private spinnerService: SpinnerService, private toastr: ToastrService) { }

    ngOnInit() {


    }



}