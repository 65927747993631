import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";

@Injectable()
export class OrderService {
  constructor(private httpClient: HttpClient) {}

  getOrderInfoDataByAccId(accountID, ordSearch, start, rows): Observable<any> {
    return this.httpClient.get<any>("order-info/get-order-info-by-accountID", {
      params: { accountID, ordSearch, start, rows },
    });
  }

  getOrderInfoDataByDateRange(AccountID, fromDate, toDate): Observable<any> {
    return this.httpClient.get<any>(
      "order-info/get-order-info-data-by-date-range",
      { params: { AccountID, fromDate, toDate } }
    );
  }

  getOrderImagesById(OrderID): Observable<any> {
    return this.httpClient.get<any>("order-info/get-order-images-by-OrderID", {
      params: { OrderID },
    });
  }
}
