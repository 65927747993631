import { Component, AfterViewInit, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators,FormBuilder, FormArray } from '@angular/forms';
import { AccountRolesModel } from '../account-roles.model';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../../../Common/Services/datashare.service';
import {AccountRoleService} from '../account-roles.service';
import { element } from 'protractor';
import {Enums} from '../../../../Common/enums';
import {CommonService} from '../../../../Common/Services/common.service';
import {ValidateUniqueName} from '../../../../Common/Services/asyncUniqueName.validator';

declare var require: any;
var accItem;
var accHD;
var itemClass;
@Component({
    selector: 'app-addrole',
    templateUrl: './addrole.component.html',
    styleUrls: ['./addrole.component.css']
})

export class AddRoleComponent implements OnInit {
    roleInEdit: FormGroup;
    current: number = 0;
    items: Array<any>;
    formArray: FormArray;
    DataFromRoleTab: any;
    showUpdateButton: boolean = true;
    showAddButton: boolean = true;
    Ids: Array<any>
    allRoles:AccountRolesModel[];
    selectedScreens: string[] = [];
    constructor(private elementRef: ElementRef,private accountRoleService: AccountRoleService,private datashare: DataService,private fb: FormBuilder, private toastr: ToastrService,private spinnerService :SpinnerService,public commonService:CommonService) {
        this.formArray = new FormArray([
            this.generateFormGroup()
        ]);
        this.roleInEdit = this.generateFormGroup();
    }
    ngOnInit() {
        this.showAddButton = true;
        this.showUpdateButton = false;
        this.items = [
            {
                "title": "Dashboard",
                "menuID": 1,
                "Checked":true,
                "screens": [
                    {
                        "itemTitle": "Dashboard","itemID" : 1,"Checked":true
                    }
                ]
            },
            {
                "title": "Orders",
                "menuID": 2,
                "Checked":false,
                "screens": [
                    {
                        "itemTitle": "Orders","itemID" : 2,"Checked":false
                    }
                ]
            },
            {
                "title": "Products",
                "menuID": 3,
                "Checked":false,
                "screens": [
                    {
                        "itemTitle": "Categories", "itemID" : 3,"Checked":false
                    },
                    {
                        "itemTitle": "Products", "itemID" : 4,"Checked":false
                    }
                ]
            },
            {
                "title": "Images",
                "menuID": 4,
                "Checked":false,
                "screens": [
                    {
                        "itemTitle": "Image", "itemID" : 5,"Checked":false
                    },
                    {
                        "itemTitle": "Image Mask", "itemID" : 6,"Checked":false
                    },
                    {
                        "itemTitle": "Patterns", "itemID" : 7,"Checked":false
                    },
                    {
                        "itemTitle": "Backgrounds", "itemID" : 8,"Checked":false
                    }
                ]
            },
            {
                "title": "Cliparts",
                "menuID": 5,
                "Checked":false,
                "screens": [
                    {
                        "itemTitle": "Cliparts",  "itemID" : 9,"Checked":false
                    }
                ]
            },
            {
                "title": "Extras",
                "menuID": 6,
                "Checked":false,
                "screens": [
                    {
                        "itemTitle": "Distress Effect", "itemID" : 10,"Checked":false
                    },
                    {
                        "itemTitle": "Preloaded Items", "itemID" : 11,"Checked":false
                    },
                    {
                        "itemTitle": "Word Cloud", "itemID" : 12,"Checked":false
                    },
                    {
                        "itemTitle": "Fonts", "itemID" : 13,"Checked":false
                    },
                    {
                        "itemTitle": "Colors",  "itemID" : 14,"Checked":false
                    }
                ]
            },
            {
                "title": "Settings",
                "menuID": 7,
                "Checked":false,
                "screens": [
                    {
                        "itemTitle": "Store Profile", "itemID" : 15,"Checked":false
                    },
                    {
                        "itemTitle": "Roles", "itemID" : 16,"Checked":false
                    },
                    {
                        "itemTitle": "users", "itemID" : 17,"Checked":false
                    },
                    {
                        "itemTitle": "Print Profiles", "itemID" : 18,"Checked":false
                    },
                    {
                        "itemTitle": "Store requests",  "itemID" : 19,"Checked":false
                    },
                    {
                        "itemTitle": "API Configurations",  "itemID" : 20,"Checked":false
                    }
                ]
            }
        ]
        this.DataFromRoleTab = this.datashare.getRoleData();
        if (this.DataFromRoleTab != undefined && this.DataFromRoleTab._id) {
            this.showUpdateButton = true;
            this.showAddButton = false;
            var strVale =  this.DataFromRoleTab.Screens;
            strVale = strVale.replace("[","");
            strVale = strVale.replace("]","");
            var resultArray = strVale.split(',').map(function(strVale){return Number(strVale);});
            this.items.forEach(element => {
                element.screens.map(
                    x =>{
                        if(resultArray.includes(x.itemID)){
                            x.Checked = true; 
                            element.Checked = true;
                            return x;
                            }
                            else{
                                x.Checked = false;
                                return x;
                            }
                        })
                }); 
    
               this.roleInEdit = new FormGroup({
                RoleName: new FormControl(this.DataFromRoleTab.RoleName,[Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.accountRole,Enums.FieldNames.RoleName,this.DataFromRoleTab.RoleName,this.commonService.getAccountID())]),
                RoleID:new FormControl(this.DataFromRoleTab.RoleID),
                CreatedBy: new FormControl(this.DataFromRoleTab.CreatedBy),
                ModifiedBy:new FormControl(this.commonService.getAccountUserID()),
                Status:new FormControl(this.DataFromRoleTab.Status),
                Screens:new FormControl(resultArray),
                RoleType:new FormControl(this.DataFromRoleTab.RoleType),
                AccountID:new FormControl(this.DataFromRoleTab.AccountID)
               });
        }
        else
        {
            this.roleInEdit = this.generateFormGroup();
        }
    }   
    
    addRole() {
        this.spinnerService.setSpinner(true);
        this.accountRoleService.addRole(this.roleInEdit.value).subscribe(
            (data) => {
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.roleInEdit = this.generateFormGroup();
                this.toastr.success('Role Added Successfully!', 'Success');
                this.commonService.navigate('account-roles');
            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
        this.roleInEdit = this.generateFormGroup();
    }

    updateRole(){
        this.spinnerService.setSpinner(true);
        this.accountRoleService.updateRole(this.roleInEdit.value).subscribe(
            (data)=>{
                this.reloadData();
                this.spinnerService.setSpinner(false);
                this.toastr.success('Role Updated Successfully!', 'Success');
                this.commonService.navigate('account-roles');
            },
            (err)=>{
                    this.commonService.handleError(err);
           }
        )
    }
    trimValue(formControl) { formControl.setValue(formControl.value.trim()); }
    onChange(id: number, isChecked: boolean) 
    {
        console.log(this.roleInEdit.controls.Screens);
        let ScreensArray = <FormArray>this.roleInEdit.controls.Screens;

        if (isChecked) {
            ScreensArray.value.push(id);
        this.items.forEach(element => {
            element.screens.find(x =>{if(x.itemID == id){element.Checked = true}});
     });
        } else {
            let index = ScreensArray.value.indexOf(id);
            if (index !== -1) {
                ScreensArray.value.splice(index, 1);
            }
        }
    }

    menuChange(id: number, isChecked: boolean){
        console.log(isChecked);
        let ScreensArray = <FormArray>this.roleInEdit.controls.Screens;
         if(isChecked)
         {
            var checkedItems = this.items.find(x=>x.menuID == id);
            checkedItems.screens.forEach(element => {
                element.Checked = true;
                ScreensArray.value.push(element.itemID);
            });
         }
         else
         {
            var checkedItems = this.items.find(x=>x.menuID == id);
            console.log(checkedItems);
            checkedItems.screens.forEach(element => {
                element.Checked = false;
                let index = ScreensArray.value.indexOf(element.itemID);
            if (index !== -1) {
                ScreensArray.value.splice(index, 1);
            }
            });
            
         }
         
    }
  
    reloadData(){
        this.accountRoleService.getRolesDataByAccId(this.commonService.getAccountID()).subscribe(
            (data) => {
                this.allRoles = data;
                this.spinnerService.setSpinner(false);
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    public noWhitespaceValidator(control: FormControl) {
        let isWhitespace = (control.value || '').trim().length === 0;
        let isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true }
    }

    onTabOpen(e) {
        var index = e.index;
    }
    onTabClose(e) {
        var index = e.index;
    }
    generateFormGroup() {
        return new FormGroup({
            RoleName: new FormControl('', [Validators.required, this.noWhitespaceValidator],[ValidateUniqueName.createUniqueValidator(this.commonService,Enums.FileNames.accountRole,Enums.FieldNames.RoleName,null,this.commonService.getAccountID())]),
            RoleID: new FormControl(''),
            CreatedBy: new FormControl(this.commonService.getAccountUserID()),
            ModifiedBy:  new FormControl(this.commonService.getAccountUserID()),
            Screens: this.fb.array([1]),
            RoleType:new FormControl(1),
            Status: new FormControl(true),
            Deleted: new FormControl(false),
            AccountID: new FormControl(this.commonService.getAccountID())
        });
    }
}