import { environment } from '../../environments/environment';
export const config = {
    BASE_URL: environment.BASE_URL,
    localStorageStrings: {
        token: 'token',
        accountId: 'accountId',
        accountUserId: 'accountUserId',
        screens:'screens'
    },
    RouteIds: {
        "order": 2,
        "product-category": 3,
        "product": 4,
        "images": 5,
        "image-masks": 6,
        "patterns": 7,
        "backgrounds": 8,
        "clipart": 9,
        "distress-effects": 10,
        "custom-templates": 11,
        "word-cloud": 12,
        "font-files": 13,
        "colors": 14,
        "store-Profile": 15,
        "account-roles": 16,
        "users": 17,
        "print-profiles": 18,
        "store-requests": 19
    }
};