import { Component, OnInit } from '@angular/core';
import { SpinnerService } from "../../../../Common/Services/spinner.service";
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl } from '@angular/forms';
import { SettingsModel } from '../settings.model';
import { SettingsService } from '../settings.service';
import {CommonService} from '../../../../Common/Services/common.service';
@Component({
    selector: 'app-font-image',
    templateUrl: './File-Image.component.html',
    styleUrls: ['./File-Image.component.css']
})

export class FileImageComponent implements OnInit {
    allSettingsData: SettingsModel;
    fileImageInEdit: FormGroup;
    constructor(private spinnerService: SpinnerService, private toastr: ToastrService, private settingsService: SettingsService,public commonService:CommonService) { }

    ngOnInit() {
        this.fileImageInEdit = this.generateFormGroup();
        this.spinnerService.setSpinner(true);
        this.reloadData();
    }
    reloadData(){
        this.settingsService.getAllSettings(this.commonService.getAccountID()).subscribe(
            (data) => {
                console.log(data);
                this.allSettingsData = data;
                this.spinnerService.setSpinner(false);
                this.fileImageInEdit = new FormGroup({
                    MaxFileSize: new FormControl(data[0].assetSettings[0].MaxFileSize),
                    MaxImageWidth: new FormControl(data[0].assetSettings[0].MaxImageWidth),
                    MaxImageHeight: new FormControl(data[0].assetSettings[0].MaxImageHeight)
                })

            },
            (err) => {
                this.commonService.handleError(err);
            }
        )
    }

    onFileImageSubmit() {
        this.allSettingsData[0].assetSettings[0] = this.fileImageInEdit.value;
        this.spinnerService.setSpinner(true);
        this.settingsService.updateStoreSettings(this.allSettingsData[0]).subscribe(
            (data) => {
                this.reloadData();
                this.toastr.success("Data Updated Successfully!", "Success");
            },
            (err) => {
               this.commonService.handleError(err);
            }
        )
    }

    generateFormGroup() {
        return new FormGroup({
            MaxFileSize: new FormControl(0),
            MaxImageWidth: new FormControl(0),
            MaxImageHeight: new FormControl(0)
        });
    }




}